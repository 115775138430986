import React from "react";

export const ECAM: React.FC = () => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 90 480"
            width="90"
            height="480"
        >
            <g>
                <path
                    className="cls-1"
                    fill="#231f20"
                    points="21.59 46 2.41 46 2.41 42.01 8.63 42.01 2.5 40.34 2.72 36.08 9.83 34.72 2.84 33.74 3.06 29.57 9.91 28.27 3.19 27.24 3.41 23.07 9.99 21.82 3.53 20.73 3.74 16.56 10.06 15.37 3.87 14.22 4.09 10.06 10.14 8.92 4.21 7.72 4.62 0 19.41 0 19.64 4.42 13.61 5.65 19.77 6.91 19.98 11.06 14.06 12.15 20.09 13.28 20.31 17.42 14.14 18.57 20.42 19.63 20.64 23.78 14.22 24.99 20.75 25.99 20.97 30.15 14.3 31.4 21.08 32.35 21.29 36.52 10.96 38.56 21.58 42.1 21.59 46"
                    d="M80.963 172.5L9.038 172.5L9.038 157.537L32.363 157.537L9.375 151.275L10.2 135.3L36.862 130.2L10.65 126.525L11.475 110.888L37.163 106.013L11.963 102.15L12.788 86.513L37.462 81.825L13.237 77.737L14.025 62.1L37.725 57.637L14.513 53.325L15.337 37.725L38.025 33.45L15.787 28.95L17.325 0L72.787 0L73.65 16.575L51.037 21.188L74.138 25.913L74.925 41.475L52.725 45.563L75.338 49.8L76.162 65.325L53.025 69.638L76.575 73.612L77.4 89.175L53.325 93.712L77.813 97.462L78.637 113.063L53.625 117.75L79.05 121.313L79.837 136.95L41.1 144.6L80.925 157.875L80.963 172.5Z"
                />
                <path
                    className="cls-2"
                    fill="#494747"
                    d="m69.375 3.75 0.525 9.787 -37.5 7.65 38.288 7.837 0.488 9.338 -38.887 7.162 39.638 7.425 0.488 9.263 -40.762 7.575 41.513 7.013 0.488 9.263 -42.487 7.987 43.237 6.638 0.488 9.263 -44.362 8.287 45.112 6.337 0.488 9.263L26.587 143.625l50.775 16.95v8.137H12.675V161.25H56.625L13.162 148.425l0.525 -9.975 46.125 -8.813 -45.3 -6.375 0.488 -9.225 44.325 -8.438 -43.537 -6.675 0.488 -9.3 42.375 -8.063 -41.587 -7.05 0.488 -9.338 40.575 -7.65 -39.787 -7.388 0.488 -9.338 38.7 -7.313L19.575 25.837 20.738 3.675h48.675m3.375 -3.675H17.325l-1.5 28.95 22.237 4.5 -22.688 4.275 -0.825 15.6 23.213 4.313 -23.7 4.462 -0.787 15.637 24.188 4.088 -24.675 4.688 -0.825 15.637 25.2 3.863 -25.688 4.875 -0.825 15.637 26.213 3.675 -26.663 5.1 -0.825 15.975 23.025 6.262H9.038V172.5h71.925v-14.625l-39.787 -13.275 38.737 -7.65 -0.787 -15.637 -25.425 -3.563 24.975 -4.688 -0.787 -15.6 -24.488 -3.75 24.038 -4.537 -0.825 -15.563 -23.55 -3.975 23.175 -4.313 -0.825 -15.525 -22.613 -4.237L75 41.475l-0.787 -15.563 -23.1 -4.725 22.613 -4.612L72.862 0z"
                />
            </g>
            <g>
                <g>
                    <path
                        className="cls-1"
                        fill="#231f20"
                        x="1.45"
                        y="45.49"
                        width="21.1"
                        height="15"
                        d="M5.438 170.588H84.563V226.838H5.438V170.588z"
                    />
                    <path
                        className="cls-2"
                        fill="#494747"
                        d="M84.563 228.637H5.438a1.762 1.762 0 0 1 -1.725 -1.8v-56.25c0 -1.013 0.787 -1.8 1.725 -1.8h79.125c0.975 0 1.762 0.825 1.762 1.8v56.25c0 1.013 -0.787 1.8 -1.762 1.8M7.2 225h75.6V172.387H7.2z"
                    />
                </g>
                <g>
                    <g>
                        <path
                            className="cls-1"
                            fill="#231f20"
                            x="3.37"
                            y="60.49"
                            width="17.26"
                            height="2"
                            d="M12.638 226.838H77.363V234.338H12.638V226.838z"
                        />
                        <path
                            className="cls-2"
                            fill="#494747"
                            d="M77.362 235.95H12.638c-0.863 0 -1.575 -0.713 -1.575 -1.613v-7.5c0 -0.9 0.713 -1.613 1.575 -1.613h64.725c0.863 0 1.537 0.713 1.537 1.613v7.5c0 0.9 -0.713 1.613 -1.537 1.613m-63.15 -3.263h61.612v-4.275H14.213z"
                        />
                    </g>
                    <g>
                        <path
                            className="cls-1"
                            fill="#231f20"
                            x="1.45"
                            y="62.49"
                            width="21.1"
                            height="3"
                            d="M5.438 234.338H84.563V245.588H5.438V234.338z"
                        />
                        <path
                            className="cls-2"
                            fill="#494747"
                            d="M84.563 247.013H5.438c-0.787 0 -1.425 -0.675 -1.425 -1.463v-11.25c0 -0.825 0.638 -1.463 1.425 -1.463h79.125c0.787 0 1.425 0.675 1.425 1.463v11.25c0 0.825 -0.638 1.463 -1.425 1.463m-77.7 -2.963h76.237v-8.287H6.863z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <path
                    className="cls-1"
                    fill="#231f20"
                    d="M84.6 437.362V329.662l3.525 -15.675V245.55H1.837v68.438l3.525 15.675v107.7s4.95 40.65 39.6 40.65 39.6 -40.65 39.6 -40.65z"
                />
                <path
                    className="cls-2"
                    fill="#494747"
                    d="M45 479.962C9.113 479.962 3.6 438.038 3.563 437.625V329.887L0 313.988V243.637h90v70.8l-3.525 15.487v107.737C86.4 438.075 80.888 480 45 480ZM3.712 313.762l3.525 15.9v107.7c0.188 1.35 5.175 38.737 37.763 38.737s37.388 -36.075 37.763 -38.85V329.25l3.525 -15.487V247.462H3.675v66.3z"
                />
            </g>
        </svg>
    );
};

export const getECAMSVGString = () =>
    `  
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 480"
      width="90"
      height="480"
    >
      <g>
        <path
          class="cls-1"
          fill="#231f20"
          points="21.59 46 2.41 46 2.41 42.01 8.63 42.01 2.5 40.34 2.72 36.08 9.83 34.72 2.84 33.74 3.06 29.57 9.91 28.27 3.19 27.24 3.41 23.07 9.99 21.82 3.53 20.73 3.74 16.56 10.06 15.37 3.87 14.22 4.09 10.06 10.14 8.92 4.21 7.72 4.62 0 19.41 0 19.64 4.42 13.61 5.65 19.77 6.91 19.98 11.06 14.06 12.15 20.09 13.28 20.31 17.42 14.14 18.57 20.42 19.63 20.64 23.78 14.22 24.99 20.75 25.99 20.97 30.15 14.3 31.4 21.08 32.35 21.29 36.52 10.96 38.56 21.58 42.1 21.59 46"
          d="M80.963 172.5L9.038 172.5L9.038 157.537L32.363 157.537L9.375 151.275L10.2 135.3L36.862 130.2L10.65 126.525L11.475 110.888L37.163 106.013L11.963 102.15L12.788 86.513L37.462 81.825L13.237 77.737L14.025 62.1L37.725 57.637L14.513 53.325L15.337 37.725L38.025 33.45L15.787 28.95L17.325 0L72.787 0L73.65 16.575L51.037 21.188L74.138 25.913L74.925 41.475L52.725 45.563L75.338 49.8L76.162 65.325L53.025 69.638L76.575 73.612L77.4 89.175L53.325 93.712L77.813 97.462L78.637 113.063L53.625 117.75L79.05 121.313L79.837 136.95L41.1 144.6L80.925 157.875L80.963 172.5Z"
        />
        <path
          class="cls-2"
          fill="#494747"
          d="m69.375 3.75 0.525 9.787 -37.5 7.65 38.288 7.837 0.488 9.338 -38.887 7.162 39.638 7.425 0.488 9.263 -40.762 7.575 41.513 7.013 0.488 9.263 -42.487 7.987 43.237 6.638 0.488 9.263 -44.362 8.287 45.112 6.337 0.488 9.263L26.587 143.625l50.775 16.95v8.137H12.675V161.25H56.625L13.162 148.425l0.525 -9.975 46.125 -8.813 -45.3 -6.375 0.488 -9.225 44.325 -8.438 -43.537 -6.675 0.488 -9.3 42.375 -8.063 -41.587 -7.05 0.488 -9.338 40.575 -7.65 -39.787 -7.388 0.488 -9.338 38.7 -7.313L19.575 25.837 20.738 3.675h48.675m3.375 -3.675H17.325l-1.5 28.95 22.237 4.5 -22.688 4.275 -0.825 15.6 23.213 4.313 -23.7 4.462 -0.787 15.637 24.188 4.088 -24.675 4.688 -0.825 15.637 25.2 3.863 -25.688 4.875 -0.825 15.637 26.213 3.675 -26.663 5.1 -0.825 15.975 23.025 6.262H9.038V172.5h71.925v-14.625l-39.787 -13.275 38.737 -7.65 -0.787 -15.637 -25.425 -3.563 24.975 -4.688 -0.787 -15.6 -24.488 -3.75 24.038 -4.537 -0.825 -15.563 -23.55 -3.975 23.175 -4.313 -0.825 -15.525 -22.613 -4.237L75 41.475l-0.787 -15.563 -23.1 -4.725 22.613 -4.612L72.862 0z"
        />
      </g>
      <g>
        <g>
          <path
            class="cls-1"
            fill="#231f20"
            x="1.45"
            y="45.49"
            width="21.1"
            height="15"
            d="M5.438 170.588H84.563V226.838H5.438V170.588z"
          />
          <path
            class="cls-2"
            fill="#494747"
            d="M84.563 228.637H5.438a1.762 1.762 0 0 1 -1.725 -1.8v-56.25c0 -1.013 0.787 -1.8 1.725 -1.8h79.125c0.975 0 1.762 0.825 1.762 1.8v56.25c0 1.013 -0.787 1.8 -1.762 1.8M7.2 225h75.6V172.387H7.2z"
          />
        </g>
        <g>
          <g>
            <path
              class="cls-1"
              fill="#231f20"
              x="3.37"
              y="60.49"
              width="17.26"
              height="2"
              d="M12.638 226.838H77.363V234.338H12.638V226.838z"
            />
            <path
              class="cls-2"
              fill="#494747"
              d="M77.362 235.95H12.638c-0.863 0 -1.575 -0.713 -1.575 -1.613v-7.5c0 -0.9 0.713 -1.613 1.575 -1.613h64.725c0.863 0 1.537 0.713 1.537 1.613v7.5c0 0.9 -0.713 1.613 -1.537 1.613m-63.15 -3.263h61.612v-4.275H14.213z"
            />
          </g>
          <g>
            <path
              class="cls-1"
              fill="#231f20"
              x="1.45"
              y="62.49"
              width="21.1"
              height="3"
              d="M5.438 234.338H84.563V245.588H5.438V234.338z"
            />
            <path
              class="cls-2"
              fill="#494747"
              d="M84.563 247.013H5.438c-0.787 0 -1.425 -0.675 -1.425 -1.463v-11.25c0 -0.825 0.638 -1.463 1.425 -1.463h79.125c0.787 0 1.425 0.675 1.425 1.463v11.25c0 0.825 -0.638 1.463 -1.425 1.463m-77.7 -2.963h76.237v-8.287H6.863z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          class="cls-1"
          fill="#231f20"
          d="M84.6 437.362V329.662l3.525 -15.675V245.55H1.837v68.438l3.525 15.675v107.7s4.95 40.65 39.6 40.65 39.6 -40.65 39.6 -40.65z"
        />
        <path
          class="cls-2"
          fill="#494747"
          d="M45 479.962C9.113 479.962 3.6 438.038 3.563 437.625V329.887L0 313.988V243.637h90v70.8l-3.525 15.487v107.737C86.4 438.075 80.888 480 45 480ZM3.712 313.762l3.525 15.9v107.7c0.188 1.35 5.175 38.737 37.763 38.737s37.388 -36.075 37.763 -38.85V329.25l3.525 -15.487V247.462H3.675v66.3z"
        />
      </g>
    </svg>
    `;
