import { useApp } from '@inlet/react-pixi';
import { useContext, useEffect } from 'react';
import { Black, Blue, BootColors, Green, IBootColor, IColor } from '../../ui/dialog/color/types';
import { Side, Sides } from '../../workspace/assembly/breakout/types';
import { ConnectorTypes, ECAM_KEY, getConnectorTypeString, IConnectorType, isDuplex, isMMC, isOutdoorConnector, isPigtailConnector, LC_DUPLEX_KEY, LC_KEY, LC_SIMPLEX_KEY, LC_UPC, LSH, LSH_KEY, MDC_KEY, MTP24_PINNED_KEY, MTP24_UNPINNED_KEY, MTP_KEY, MTP_PINNED_KEY, MTP_UNPINNED_KEY, OPTITAP_FEMALE_KEY, OPTITAP_MALE_KEY, OPTITIP_KEY, PINNED_KEY, PUSHLOK_MF_KEY, PUSHLOK_SF_KEY, SC_DUPLEX_KEY, SC_SIMPLEX_KEY, SC_UPC, SN, SN_KEY } from '../../workspace/assembly/connector/types';
import { FiberColors, JacketColors, MTPExclusiveColors } from '../../workspace/assembly/palette/types';
import { Status } from '../../workspace/store/types';
import { getLCDuplexSVGString } from '../cable/breakout/connector-furcation/connector-sprite/LCDuplex';
import { getLCSimplexSVGString } from '../cable/breakout/connector-furcation/connector-sprite/LCSimplex';
import { getLCUnibootSVGString } from '../cable/breakout/connector-furcation/connector-sprite/LCUniboot';
import { getLSHSVGString } from '../cable/breakout/connector-furcation/connector-sprite/LSH';
import { getMDCSVGString } from '../cable/breakout/connector-furcation/connector-sprite/MDC';
import { getPinnedMMCSVGString, getUnpinnedMMCSVGString } from '../cable/breakout/connector-furcation/connector-sprite/MMC';
import { getPinnedMTPSVGString, getUnpinnedMTPSVGString, MTP24BootColor, MTPBootColor } from '../cable/breakout/connector-furcation/connector-sprite/MTP';
import { getSCDuplexSVGString } from '../cable/breakout/connector-furcation/connector-sprite/SCDuplex';
import { getSCSimplexSVGString } from '../cable/breakout/connector-furcation/connector-sprite/SCSimplex';
import { getSNSVGString } from '../cable/breakout/connector-furcation/connector-sprite/SN';
import { getEndAPullingGripSVGString } from '../cable/pulling-grip/end-a/PullingGripEndA';
import { getEndBPullingGripSVGString } from '../cable/pulling-grip/end-b/PullingGripEndB';
import { StatusContext } from '../status/types';
import { getECAMSVGString } from '../cable/breakout/connector-furcation/connector-sprite/ECAM';
import { getOPTITIPSVGString } from '../cable/breakout/connector-furcation/connector-sprite/OPTITIP';
import { getOPTITAPFemaleSVGString } from '../cable/breakout/connector-furcation/connector-sprite/OPTITAP_FEMALE';
import { getOPTITAPMaleSVGString } from '../cable/breakout/connector-furcation/connector-sprite/OPTITAP_MALE';
import { getPUSHLOKMFSVGString } from '../cable/breakout/connector-furcation/connector-sprite/PUSHLOK_MF';
import { getPUSHLOKSFSVGString } from '../cable/breakout/connector-furcation/connector-sprite/PUSHLOK_SF';

const texturePathPrefix = "data:image/svg+xml;utf8,";

export const useResourceManager = () => {
    const app = useApp();
    const { state: statusState } = useContext(StatusContext);
    const { status } = statusState;

    useEffect(() => {
        if (status === Status.InitialLoad) {
            const connectorTypes = Object.values(ConnectorTypes).filter(c => !isPigtailConnector(c.type));
            const duplexConnectorTypes = extractConnectorTypes(connectorTypes, (c => c.type === LC_UPC || c.type === SC_UPC));
            const connectorTypesSingleColor = extractConnectorTypes(connectorTypes, (c => c.type === SN || c.type === LSH || isMMC(c.type)));
            const filteredConnectorTypes = [...duplexConnectorTypes, ...connectorTypesSingleColor];
            const connectorTypesAllColors = extractConnectorTypes(connectorTypes, (c => {
                return !filteredConnectorTypes.includes(getConnectorTypeString(c.type)) && !isOutdoorConnector(c.type);
            }));
            const outdoorConnectorsBlackOnly = extractConnectorTypes(connectorTypes, (c) => {
                return isOutdoorConnector(c.type);
            })
            const mtpConnectorTypes = extractConnectorTypes(connectorTypes, (c => c.type.includes(MTP_KEY)));

            const { endATexture, endBTexture } = generatePullingGripTexture();
            app.loader.add(endATexture.textureName, endATexture.texturePath);
            app.loader.add(endBTexture.textureName, endBTexture.texturePath);
            for (const fiberColor of FiberColors) {
                connectorTypesAllColors.forEach((connectorType) => {
                        const { textureName, texturePath } = generateConnectorTexture(connectorType, fiberColor);
                        app.loader.add(textureName, texturePath);
                });
                for (const jacketColor of JacketColors) {
                    duplexConnectorTypes.forEach((connectorType) => {
                        for (const bootColorCombination of BootColors) {
                            const { textureName, texturePath } = generateConnectorTexture(
                                connectorType, 
                                fiberColor, 
                                jacketColor, 
                                undefined, 
                                bootColorCombination
                            );
                            app.loader.add(textureName, texturePath);
                        }
                    });
                }
            }
            outdoorConnectorsBlackOnly.forEach((connectorType) => {
                const { textureName, texturePath } = generateConnectorTexture(connectorType, Black);
                app.loader.add(textureName, texturePath);
            })
            for (const mtpExclusiveColor of MTPExclusiveColors) {
                mtpConnectorTypes.forEach((connectorType) => {
                    const { textureName, texturePath } = generateConnectorTexture(connectorType, mtpExclusiveColor);
                    app.loader.add(textureName, texturePath);
                })
            }

            connectorTypesSingleColor.forEach((connectorType) => {
                const { textureName, texturePath } = generateConnectorTexture(connectorType, isMMC(connectorType) ? Green : Blue);
                app.loader.add(textureName, texturePath);
                if (connectorType === SN_KEY) {
                    const { textureName, texturePath } = generateConnectorTexture(connectorType, Blue, undefined, Sides.SideB);
                    app.loader.add(textureName, texturePath);
                }
            })
            
            app.loader.load();
        }
    }, [status, app]);
}

export function extractConnectorTypes(connectorTypes: IConnectorType[], filterFunction: (c: IConnectorType) => boolean) {
    return connectorTypes.filter(c => filterFunction(c))
                         .map(c => getConnectorTypeString(c.type))
                         .filter((c, i, self) => self.indexOf(c) === i);
}

export function generateConnectorTexture(connectorType: string, color: IColor, jacketColor?: IColor, side?: Side, bootColors?: IBootColor) {
    const { hex, name } = color;
    const isMTPConnector = connectorType.includes(MTP_KEY);
    const isDuplexConnector = isDuplex(connectorType);
    const isMMCConnector = isMMC(connectorType);

    let textureName = jacketColor ? `${connectorType}_${name}_${jacketColor.name}Jacket` : (!side ? `${connectorType}_${name}` : `${connectorType}B_${name}`);
    let SVGString = getConnectorSVGString(connectorType, hex, jacketColor?.hex, side);
    if (isMTPConnector) {
        SVGString = getMTPConnectorSVGString(connectorType, hex);
    } 
    else if (isMMCConnector) {
        SVGString = getMMCConnectorSVGString(connectorType);
    }
    else if (isDuplexConnector && bootColors && bootColors.bootAColor && bootColors.bootBColor) {
        textureName = `${textureName}_${bootColors.bootAColor.name}A_${bootColors.bootBColor.name}B`;
        SVGString = getConnectorSVGString(connectorType, hex, jacketColor?.hex, side, bootColors.bootAColor?.hex, bootColors.bootBColor?.hex);
    }
    const encodedSVG = encodeURIComponent(SVGString);
    const texturePath = `${texturePathPrefix}${encodedSVG}`;

    return { textureName, texturePath }
}

export function getConnectorSVGString(connectorType: string, hex: string, jacketColor?: string, side?: Side, bootAColor?: string, bootBColor?: string) {
    switch (connectorType) {
        case SN_KEY:
            return getSNSVGString(hex, side);
        case LC_DUPLEX_KEY:
            return getLCDuplexSVGString(hex, jacketColor, bootAColor, bootBColor);
        case SC_DUPLEX_KEY:
            return getSCDuplexSVGString(hex, jacketColor, bootAColor, bootBColor);
        case MDC_KEY: 
            return getMDCSVGString(hex);
        case LC_SIMPLEX_KEY:
            return getLCSimplexSVGString(hex);
        case SC_SIMPLEX_KEY:
            return getSCSimplexSVGString(hex);
        case LSH_KEY:
            return getLSHSVGString();
        case OPTITIP_KEY: 
            return getOPTITIPSVGString();
        case ECAM_KEY:
            return getECAMSVGString();
        case OPTITAP_FEMALE_KEY:
            return getOPTITAPFemaleSVGString();
        case OPTITAP_MALE_KEY:
            return getOPTITAPMaleSVGString();
        case PUSHLOK_MF_KEY:
            return getPUSHLOKMFSVGString();
        case PUSHLOK_SF_KEY:
            return getPUSHLOKSFSVGString();
        case LC_KEY:
        default:
            return getLCUnibootSVGString(hex);
    }
}

export function getMTPConnectorSVGString(connectorType: string, hex: string) {
    const bootColor = getMTPBootColor(connectorType);
    const pinned = connectorType.includes(PINNED_KEY);
    return pinned ? getPinnedMTPSVGString(hex, bootColor) : getUnpinnedMTPSVGString(hex, bootColor);
}

export function getMMCConnectorSVGString(connectorType: string) {
    const pinned = connectorType.includes(PINNED_KEY);
    return pinned ? getPinnedMMCSVGString() : getUnpinnedMMCSVGString();
}

export function getMTPBootColor(connectoryType: string) {
    switch (connectoryType) {
        case MTP24_PINNED_KEY:
        case MTP24_UNPINNED_KEY:
            return MTP24BootColor;
        case MTP_PINNED_KEY:
        case MTP_UNPINNED_KEY:
        default:
            return MTPBootColor;
    }
}

export const generatePullingGripTexture = () => {
    const endASVG = encodeURIComponent(getEndAPullingGripSVGString());
    const endATexturePath = `${texturePathPrefix}${endASVG}`;
    const endATexture = { textureName: "PullingGrip_EndA", texturePath: endATexturePath };

    const endBSVG = encodeURIComponent(getEndBPullingGripSVGString());
    const endBTexturePath = `${texturePathPrefix}${endBSVG}`;
    const endBTexture = { textureName: "PullingGrip_EndB", texturePath: endBTexturePath };

    return { endATexture, endBTexture };
}