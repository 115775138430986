import React from "react";

export const OPTITIP: React.FC = () => {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 90 621.563"
            width="90"
            height="621.563"
        >
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                fill="#606161"
                                d="M87.188 500.063H2.813l5.063 51.75c0 2.25 3.375 4.5 6.188 4.5h61.875c2.813 0 5.625 -1.688 6.188 -4.5z"
                            />
                            <path
                                className="st1"
                                fill="#494747"
                                d="M75.938 559.125h-61.875c-4.5 0 -8.438 -2.813 -9 -6.75L0 500.625c0 -0.563 0 -1.688 0.563 -2.25s1.125 -1.125 2.25 -1.125h84.375c0.563 0 1.688 0 2.25 1.125 0 0 0.563 1.125 0.563 2.25l-5.063 51.75c-1.125 3.937 -4.5 6.75 -9 6.75m-69.75 -56.25 5.063 48.937c0 1.125 1.688 1.688 3.375 1.688h61.875c1.688 0 3.375 -1.125 3.375 -2.25l4.5 -48.375z"
                            />
                        </g>
                        <g>
                            <path
                                className="st0"
                                fill="#606161"
                                d="M48.937 618.75H40.5c-12.937 0 -23.625 -10.688 -23.625 -23.625V556.875h22.5v33.75c0 2.813 2.25 5.625 5.625 5.625s5.625 -2.25 5.625 -5.625v-33.75h22.5v38.25c0 12.937 -10.688 23.625 -23.625 23.625z"
                            />
                            <path
                                className="st1"
                                fill="#494747"
                                d="M48.937 621.563H40.5c-14.625 0 -27 -11.813 -27 -26.438V556.875c0 -1.688 1.125 -2.813 2.813 -2.813h22.5c1.688 0 2.813 1.125 2.813 2.813v33.75c0 1.688 1.125 2.813 2.813 2.813s2.813 -1.125 2.813 -2.813v-33.75c0 -1.688 1.125 -2.813 2.813 -2.813h22.5c1.688 0 2.813 1.125 2.813 2.813v38.25c0 14.625 -11.813 26.438 -26.438 26.438m-29.25 -61.875v35.438c0 11.813 9.563 20.813 20.813 20.813h8.438c11.813 0 20.813 -9.563 20.813 -20.813v-35.438h-16.875v30.938c0 4.5 -3.937 8.438 -8.438 8.438s-8.438 -3.937 -8.438 -8.438v-30.938h-16.875z"
                            />
                        </g>
                    </g>
                    <g>
                        <path
                            className="st0"
                            fill="#606161"
                            d="M87.188 500.063H2.813l5.063 -92.813c0 -4.5 3.375 -7.875 6.188 -7.875h61.875c2.813 0 5.625 3.375 6.188 7.875z"
                        />
                        <path
                            className="st1"
                            fill="#494747"
                            d="M87.188 502.875H2.813s-1.688 0 -2.25 -1.125c0 0 -0.563 -1.125 -0.563 -2.25l5.063 -92.813c0.563 -6.188 4.5 -10.688 9 -10.688h61.875c4.5 0 8.438 4.5 9 10.125l5.063 93.375s0 1.688 -0.563 2.25 -1.125 1.125 -2.25 1.125M5.625 497.25h78.188l-5.063 -90c0 -2.813 -2.25 -5.063 -3.375 -5.063H14.063c-1.125 0 -2.813 2.25 -3.375 5.063z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            fill="#606161"
                            x="3"
                            y="67"
                            width="10"
                            height="4"
                            d="M16.875 376.875H73.125V399.375H16.875V376.875z"
                        />
                        <path
                            className="st1"
                            fill="#494747"
                            d="M73.125 402.188H16.875c-1.688 0 -2.813 -1.125 -2.813 -2.813v-22.5c0 -1.688 1.125 -2.813 2.813 -2.813h56.25c1.688 0 2.813 1.125 2.813 2.813v22.5c0 1.688 -1.125 2.813 -2.813 2.813m-53.438 -5.625h50.625v-16.875h-50.625z"
                        />
                    </g>
                    <g>
                        <path
                            className="st0"
                            fill="#606161"
                            x="3"
                            y="63"
                            width="10"
                            height="4"
                            d="M16.875 354.375H73.125V376.875H16.875V354.375z"
                        />
                        <path
                            className="st1"
                            fill="#494747"
                            d="M73.125 379.688H16.875c-1.688 0 -2.813 -1.125 -2.813 -2.813v-22.5c0 -1.688 1.125 -2.813 2.813 -2.813h56.25c1.688 0 2.813 1.125 2.813 2.813v22.5c0 1.688 -1.125 2.813 -2.813 2.813m-53.438 -5.625h50.625v-16.875h-50.625z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st0"
                        fill="#606161"
                        x="1"
                        y="55"
                        width="14"
                        height="8"
                        d="M5.625 309.375H84.375V354.375H5.625V309.375z"
                    />
                    <path
                        className="st1"
                        fill="#494747"
                        d="M84.375 357.188H5.625c-1.688 0 -2.813 -1.125 -2.813 -2.813v-45c0 -1.688 1.125 -2.813 2.813 -2.813h78.75c1.688 0 2.813 1.125 2.813 2.813v45c0 1.688 -1.125 2.813 -2.813 2.813m-75.938 -5.625h73.125v-39.375h-73.125z"
                    />
                </g>
                <g>
                    <path
                        className="st0"
                        fill="#606161"
                        d="M84.375 294.75s-20.25 -51.75 -20.25 -109.688V2.813H25.875v181.687C25.875 242.438 5.625 294.188 5.625 294.188v14.063h78.75v-14.063Z"
                    />
                    <path
                        className="st1"
                        fill="#494747"
                        d="M84.375 311.625H5.625c-1.688 0 -2.813 -1.125 -2.813 -2.813v-15.188s20.25 -52.313 20.25 -109.125V2.813c0 -1.688 1.125 -2.813 2.813 -2.813h38.25c1.688 0 2.813 1.125 2.813 2.813v181.687c0 56.813 19.688 108.563 20.25 109.125v15.188c0 1.688 -1.125 2.813 -2.813 2.813m-75.938 -5.625h73.125v-10.688c-2.25 -5.625 -20.25 -55.688 -20.25 -110.25V5.625H28.687v179.438c0 54.562 -18 105.188 -20.25 110.25z"
                    />
                </g>
            </g>
        </svg>
    );
};

export const getOPTITIPSVGString = () =>
    ` <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 90 621.563"
            width="90"
            height="621.563"
        >
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                class="st0"
                                fill="#606161"
                                d="M87.188 500.063H2.813l5.063 51.75c0 2.25 3.375 4.5 6.188 4.5h61.875c2.813 0 5.625 -1.688 6.188 -4.5z"
                            />
                            <path
                                class="st1"
                                fill="#494747"
                                d="M75.938 559.125h-61.875c-4.5 0 -8.438 -2.813 -9 -6.75L0 500.625c0 -0.563 0 -1.688 0.563 -2.25s1.125 -1.125 2.25 -1.125h84.375c0.563 0 1.688 0 2.25 1.125 0 0 0.563 1.125 0.563 2.25l-5.063 51.75c-1.125 3.937 -4.5 6.75 -9 6.75m-69.75 -56.25 5.063 48.937c0 1.125 1.688 1.688 3.375 1.688h61.875c1.688 0 3.375 -1.125 3.375 -2.25l4.5 -48.375z"
                            />
                        </g>
                        <g>
                            <path
                                class="st0"
                                fill="#606161"
                                d="M48.937 618.75H40.5c-12.937 0 -23.625 -10.688 -23.625 -23.625V556.875h22.5v33.75c0 2.813 2.25 5.625 5.625 5.625s5.625 -2.25 5.625 -5.625v-33.75h22.5v38.25c0 12.937 -10.688 23.625 -23.625 23.625z"
                            />
                            <path
                                class="st1"
                                fill="#494747"
                                d="M48.937 621.563H40.5c-14.625 0 -27 -11.813 -27 -26.438V556.875c0 -1.688 1.125 -2.813 2.813 -2.813h22.5c1.688 0 2.813 1.125 2.813 2.813v33.75c0 1.688 1.125 2.813 2.813 2.813s2.813 -1.125 2.813 -2.813v-33.75c0 -1.688 1.125 -2.813 2.813 -2.813h22.5c1.688 0 2.813 1.125 2.813 2.813v38.25c0 14.625 -11.813 26.438 -26.438 26.438m-29.25 -61.875v35.438c0 11.813 9.563 20.813 20.813 20.813h8.438c11.813 0 20.813 -9.563 20.813 -20.813v-35.438h-16.875v30.938c0 4.5 -3.937 8.438 -8.438 8.438s-8.438 -3.937 -8.438 -8.438v-30.938h-16.875z"
                            />
                        </g>
                    </g>
                    <g>
                        <path
                            class="st0"
                            fill="#606161"
                            d="M87.188 500.063H2.813l5.063 -92.813c0 -4.5 3.375 -7.875 6.188 -7.875h61.875c2.813 0 5.625 3.375 6.188 7.875z"
                        />
                        <path
                            class="st1"
                            fill="#494747"
                            d="M87.188 502.875H2.813s-1.688 0 -2.25 -1.125c0 0 -0.563 -1.125 -0.563 -2.25l5.063 -92.813c0.563 -6.188 4.5 -10.688 9 -10.688h61.875c4.5 0 8.438 4.5 9 10.125l5.063 93.375s0 1.688 -0.563 2.25 -1.125 1.125 -2.25 1.125M5.625 497.25h78.188l-5.063 -90c0 -2.813 -2.25 -5.063 -3.375 -5.063H14.063c-1.125 0 -2.813 2.25 -3.375 5.063z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            class="st0"
                            fill="#606161"
                            x="3"
                            y="67"
                            width="10"
                            height="4"
                            d="M16.875 376.875H73.125V399.375H16.875V376.875z"
                        />
                        <path
                            class="st1"
                            fill="#494747"
                            d="M73.125 402.188H16.875c-1.688 0 -2.813 -1.125 -2.813 -2.813v-22.5c0 -1.688 1.125 -2.813 2.813 -2.813h56.25c1.688 0 2.813 1.125 2.813 2.813v22.5c0 1.688 -1.125 2.813 -2.813 2.813m-53.438 -5.625h50.625v-16.875h-50.625z"
                        />
                    </g>
                    <g>
                        <path
                            class="st0"
                            fill="#606161"
                            x="3"
                            y="63"
                            width="10"
                            height="4"
                            d="M16.875 354.375H73.125V376.875H16.875V354.375z"
                        />
                        <path
                            class="st1"
                            fill="#494747"
                            d="M73.125 379.688H16.875c-1.688 0 -2.813 -1.125 -2.813 -2.813v-22.5c0 -1.688 1.125 -2.813 2.813 -2.813h56.25c1.688 0 2.813 1.125 2.813 2.813v22.5c0 1.688 -1.125 2.813 -2.813 2.813m-53.438 -5.625h50.625v-16.875h-50.625z"
                        />
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <path
                        class="st0"
                        fill="#606161"
                        x="1"
                        y="55"
                        width="14"
                        height="8"
                        d="M5.625 309.375H84.375V354.375H5.625V309.375z"
                    />
                    <path
                        class="st1"
                        fill="#494747"
                        d="M84.375 357.188H5.625c-1.688 0 -2.813 -1.125 -2.813 -2.813v-45c0 -1.688 1.125 -2.813 2.813 -2.813h78.75c1.688 0 2.813 1.125 2.813 2.813v45c0 1.688 -1.125 2.813 -2.813 2.813m-75.938 -5.625h73.125v-39.375h-73.125z"
                    />
                </g>
                <g>
                    <path
                        class="st0"
                        fill="#606161"
                        d="M84.375 294.75s-20.25 -51.75 -20.25 -109.688V2.813H25.875v181.687C25.875 242.438 5.625 294.188 5.625 294.188v14.063h78.75v-14.063Z"
                    />
                    <path
                        class="st1"
                        fill="#494747"
                        d="M84.375 311.625H5.625c-1.688 0 -2.813 -1.125 -2.813 -2.813v-15.188s20.25 -52.313 20.25 -109.125V2.813c0 -1.688 1.125 -2.813 2.813 -2.813h38.25c1.688 0 2.813 1.125 2.813 2.813v181.687c0 56.813 19.688 108.563 20.25 109.125v15.188c0 1.688 -1.125 2.813 -2.813 2.813m-75.938 -5.625h73.125v-10.688c-2.25 -5.625 -20.25 -55.688 -20.25 -110.25V5.625H28.687v179.438c0 54.562 -18 105.188 -20.25 110.25z"
                    />
                </g>
            </g>
        </svg>
    `;
