import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnyAction } from "redux";
import { LocalizationKeys } from "../../../../localization/types";
import { useSelectInput } from "../../../../ui/select/hooks";
import { useLengthField } from "../../../assembly/length/hooks";
import { CableEnvironments, CableType, CableTypes, EMEAFiberCounts, FiberCounts, FiberType, FiberTypes, FlameRatings, FurcationOuterDiameters, PullingGrips, Shape, Shapes } from "../../../assembly/types";
import { setBreakoutStepState } from "../breakout-step/reducer";
import { CableSetupContext, CableSetupState, setApplyEnable, setWizardSetupState } from "../reducer";
import { setCableEnvironment, setFiberType, setFiberCount, setGeneralSetupState, setOverallLength, setFlameRating, setPullingGrip, setCableType, setOuterDiameter, setShape } from "./reducer";
import { IGeneralSetupState } from "./types";
import { initialSymmetricBreakoutStep, initialAsymmetricBreakoutStep } from "../breakout-step/types";
import { useSelector } from "react-redux";
import { setupFlameRating, setupFiberType, setupEnvironment, setupJacketColor, setupOverallLength, setupEndDesignation, updateAssemblyDescription, setupPullingGrip, setupCableType, setLegTolerances, setupOuterDiameter } from "../../../assembly/store/reducer";
import { setupFiberCount } from "../../../assembly/store/reducer";
import { Length } from "../../../assembly/length/types";
import { currentAssemblyFiberCountSelector, currentFlameRatingSelector, currentFurcationOuterDiameterSelector, currentJacketColorSelector, currentPullingGripSelector, filteredJacketColorSelector, toleranceSelector, tolerancesSelector } from "../../../assembly/store/selectors";
import { IColor, IColorDialogProps } from "../../../../ui/dialog/color/types";
import { Side, Sides } from "../../../assembly/breakout/types";
import { getDefaultDescription } from "../../../assembly/info/types";
import { unitSelector } from "../../../assembly/length/store/selectors";
import { isEMEAUserSelector, isTelcoUserSelector } from "../../../store/selectors";
import { useStoreDispatch } from "../../../../store/hooks";
import { DialogProps, IDialogActions } from "@orbit/dialog";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { TooltipPlacement } from "@orbit/icon-button";

export const useGeneralSetup = () => {
    const { state, dispatch } = useContext(CableSetupContext);
    const { wizardSetup, generalSetup } = state;
    const displayStep = wizardSetup.stepIndex === 0;
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const shapeField = useShape([state, dispatch]);
    const fiberCountProps = useFiberCount([generalSetup, dispatch]);
    const flameRating = useFlameRating([generalSetup, dispatch]);
    const cableType = useCableType([generalSetup, dispatch]);
    const fiberType = useFiberType([generalSetup, dispatch]);
    const cableEnvironment = useEnvironment([generalSetup, dispatch]);
    const aLengthField = useLengthField({ label: t(LocalizationKeys.LengthName, { name: "A0" }), length: generalSetup.lengthA });
    const bLengthField = useLengthField({ label: t(LocalizationKeys.LengthName, { name: "B0" }), length: generalSetup.lengthB });
    const { jacket, colorDialogProps } = useJacket();
    const overallLength = useOverallLength([generalSetup, dispatch]);
    const endA = useEndDesignation(Sides.SideA);
    const endB = useEndDesignation(Sides.SideB);
    const pullingGrip = usePullingGrip([generalSetup, dispatch]);
    const outerDiameter = useOuterDiameter([generalSetup, dispatch]);

    useEffect(() => {
        if (state.wizardSetup.stepIndex !== 0) {
            colorDialogProps.onClose();
        }
    }, [colorDialogProps, state.wizardSetup.stepIndex]);

    useEffect(() => {
        if (dispatch && state.wizardSetup.stepIndex === 0) {
            const enableApply = !overallLength.error && !fiberCountProps.helperText;
            dispatch(setApplyEnable(enableApply));
        }
    }, [dispatch, state, overallLength.error, fiberCountProps.helperText]);

    useEffect(() => {
        const description = getDefaultDescription(state.generalSetup.fiberType, state.generalSetup.fiberCount as number);
        storeDispatch(updateAssemblyDescription(description));
    }, [storeDispatch, state.generalSetup.fiberCount, state.generalSetup.fiberType]);

    const aLength = {
        className: "a-length-field",
        label: aLengthField.label,
        value: aLengthField.value,
        palette: MainPalettes.primary,
        error: !aLengthField.isValid,
        disabled: true,
        onChange: aLengthField.onChange
    };

    const bLength = {
        className: "b-length-field",
        label: bLengthField.label,
        value: bLengthField.value,
        palette: MainPalettes.primary,
        error: !bLengthField.isValid,
        disabled: true,
        onChange: bLengthField.onChange
    };

    return {
        shapeField,
        fiberCountProps,
        flameRating,
        cableType,
        fiberType,
        cableEnvironment,
        jacket,
        overallLength,
        endA,
        endB,
        aLength,
        bLength,
        displayStep,
        colorDialogProps,
        pullingGrip,
        outerDiameter
    }
}

const useShape = (redux: [CableSetupState, React.Dispatch<AnyAction> | undefined]) => {
    const [{ generalSetup, breakoutSetup }, stepDispatcher] = redux;
    const { shape } = generalSetup
    const { t } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);

    const dispatchBreakoutSteps = useCallback((shape: Shape) => {
        if (!stepDispatcher) return;
        if (shape === Shapes.Asymmetrical) {
            stepDispatcher(setWizardSetupState({ stepCount: 3 }))
            stepDispatcher(setBreakoutStepState({ ...initialAsymmetricBreakoutStep }))
        } else {
            stepDispatcher(setWizardSetupState({ stepCount: 2 }))
            stepDispatcher(setBreakoutStepState({ ...initialSymmetricBreakoutStep }))
        }
        stepDispatcher(setGeneralSetupState({ shape }))
    }, [stepDispatcher]);

    const onShapeChange = useCallback((e) => {
        if (breakoutSetup.isModified) {
            setDialogOpen(true);
        } else {
            const shape = e.currentTarget.value;
            dispatchBreakoutSteps(shape)
        }
    }, [breakoutSetup.isModified, dispatchBreakoutSteps]);

    const radioProps = {
        value: shape,
        options: [
            {
                className: shape === Shapes.Symmetrical ? "selected" : "",
                label: t(LocalizationKeys.Symmetrical),
                help: t(LocalizationKeys.SymmetricalHelp),
                radioButtonProps: { value: Shapes.Symmetrical, onChange: onShapeChange },
            },
            { 
                className: shape === Shapes.Asymmetrical ? "selected" : "",
                label: t(LocalizationKeys.Asymmetrical),
                help: t(LocalizationKeys.AsymmetricalHelp),
                radioButtonProps: { value: Shapes.Asymmetrical, onChange: onShapeChange },
            }
        ]
    };

    const onDialogClose = useCallback(() => {
        setDialogOpen(false);
    }, []);

    const onDialogAccept = useCallback(() => {
        dispatchBreakoutSteps(t(shape) === t(LocalizationKeys.Symmetrical) ? Shapes.Asymmetrical : Shapes.Symmetrical);
        onDialogClose();
    }, [dispatchBreakoutSteps, t, shape, onDialogClose]);

    const actions: IDialogActions = {
        cancelText: t(LocalizationKeys.Cancel),
        onCancelClick: onDialogClose,
        confirmText: t(LocalizationKeys.ResetAll), 
        onConfirmClick: onDialogAccept,
        actionGuidance: {
            button: 'confirm',
            severity: 'critical'
        },
    };
 
    const dialogProps: DialogProps = {
        open: dialogOpen,
        className: "input-dialog",
        title: `${t(LocalizationKeys.Reset)} ${t(LocalizationKeys.Shape)}`,
        message: t(LocalizationKeys.ResetAllBreakoutDialogBody),
        actions,
        onClose: onDialogClose
    };

    return {
        radioProps,
        dialogProps
    }
}

export const useFiberCount = (redux: [IGeneralSetupState, React.Dispatch<AnyAction>?]) => {
    const [state, dispatch] = redux;
    const { t } = useTranslation();
    const isEMEAUser = useSelector(isEMEAUserSelector);
    const isTelcoUser = useSelector(isTelcoUserSelector);
    const fiberCounts = isEMEAUser ? EMEAFiberCounts : FiberCounts;
    const options = fiberCounts.map(f => f.toString());
    const value = state.fiberCount.toString();
    const label = fiberCounts.findIndex(f => f === state.fiberCount) === -1 ? `${t(LocalizationKeys.Custom)} ${t(LocalizationKeys.FiberCount)}` : t(LocalizationKeys.FiberCount);
    const fiberCount = useSelector(currentAssemblyFiberCountSelector);
    const [open, setOpen] = useState(false);
    const storeDispatch = useStoreDispatch();

    const onOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const onClose = useCallback(() => {
        setOpen(false);
    } , []);

    const onChange = useCallback((value: string) => {
        const fiberCount = Number.parseInt(value);
        if (fiberCount && dispatch) {
            dispatch(setFiberCount(fiberCount))
        }
    }, [dispatch]);

    const onInputChange = useCallback((value: string) => {
        const valueInt = Number.parseInt(value);
        const fiberCount = !isNaN(valueInt) ? valueInt : state.fiberCount;
        if (fiberCount && dispatch) {
            setOpen(false);
            dispatch(setFiberCount(fiberCount));
        }
    }, [state.fiberCount, dispatch]);

    useEffect(() => {
        if (fiberCount !== state.fiberCount) {
            storeDispatch(setupFiberCount(state.fiberCount));
        }
    }, [fiberCount, state.fiberCount, storeDispatch]);

    const helperText = useMemo(() => {
        let start = `${t(LocalizationKeys.Custom)} ${t(LocalizationKeys.FiberCount).toLocaleLowerCase()}`;
        if (value.length === 0) {
            return t(LocalizationKeys.EmptyInputField)
        } else if (Number.parseInt(value) > FiberCounts[12]) {
            return `${start} ${t(LocalizationKeys.ValueGreater, { value: FiberCounts[12] }).toLowerCase()}`;
        } else if (Number.parseInt(value) < 1) {
            return `${start} ${t(LocalizationKeys.ValueLess, { value: 1 }).toLowerCase()}`;
        }
    }, [t, value]);

    const access = isTelcoUser || isEMEAUser;
    return {
        open,
        access,
        label,
        value,
        options,
        helperText,
        onOpen,
        onClose,
        onChange,
        onInputChange
    };
}

const flameRatingOptions = Object.values(FlameRatings);
export const useFlameRating = (redux: [IGeneralSetupState, React.Dispatch<AnyAction>?]) => {
    const [state, stepDispatcher] = redux;
    const { t } = useTranslation();
    const flameRatingSelect = useSelectInput(flameRatingOptions, t(LocalizationKeys.FlameRating), "", `${state.flameRating}`);
    const currentFlameRating = useSelector(currentFlameRatingSelector);
    const storeDispatch = useStoreDispatch();

    const onChange = useCallback((e) => {
        const flameRating = e.target.value;
        if (flameRating && stepDispatcher) {
            const selectedFlameRating = flameRatingOptions.find(fr => fr === flameRating);
            if (selectedFlameRating) {
                stepDispatcher(setFlameRating(selectedFlameRating));
            }
        }
    }, [stepDispatcher]);

    const flameRating = {
        label: flameRatingSelect.label,
        select: {
            variant: "filled" as "filled",
            value: flameRatingSelect.value,
            onChange
        },
        options: flameRatingOptions
    }

    useEffect(() => {
        if (currentFlameRating && currentFlameRating !== state.flameRating) {
            storeDispatch(setupFlameRating(state.flameRating));
        }
    }, [currentFlameRating, state, storeDispatch]);

    return flameRating;
}

const useCableType = (redux: [IGeneralSetupState, React.Dispatch<AnyAction>?]) => {
    const [state, dispatch] = redux;
    const { t } = useTranslation();
    const cableTypes = Object.values(CableTypes);
    const cableTypeLabel = t(LocalizationKeys.CableType);
    const cableTypeSelect = useSelectInput(cableTypes, cableTypeLabel, "", state.type);
    const { unit } = useSelector(unitSelector);
    const { milimeter: tolerance } = useSelector(tolerancesSelector);
    const displayTolerance = useSelector(toleranceSelector);
    const storeDispatch = useStoreDispatch();

    const onCableTypeChange = useCallback((e) => {
        if (dispatch) {
            const cableType = e.target.value as CableType;
            dispatch(setCableType(cableType));
        }
    }, [dispatch]);

    useEffect(() => {
        storeDispatch(setupCableType(state.type));
        storeDispatch(setLegTolerances(tolerance));
    }, [state.type, storeDispatch, tolerance]);

    return {
        label: cableTypeSelect.label,
        select: {
            variant: "filled" as "filled",
            value: cableTypeSelect.value,
            onChange: onCableTypeChange
        },
        options: cableTypes,
        hint: `${t(LocalizationKeys.Leg)} ${t(LocalizationKeys.Tolerance).toLowerCase()}: ${displayTolerance.min},+${displayTolerance.max} ${unit}`
    };
}

const useFiberType = (redux: [IGeneralSetupState, React.Dispatch<AnyAction>?]) => {
    const [state, dispatch] = redux;
    const { t } = useTranslation();
    const fiberTypes = Object.values(FiberTypes);
    const fiberTypeLabel = t(LocalizationKeys.Mode);
    const fiberTypeSelect = useSelectInput(fiberTypes, fiberTypeLabel, "", state.fiberType);
    const storeDispatch = useStoreDispatch();

    const onFiberTypeChange = useCallback((e) => {
        if (dispatch) {
            const fiberType = e.target.value as FiberType;
            dispatch(setFiberType(fiberType));
        }
    }, [dispatch]);

    useEffect(() => {
        storeDispatch(setupFiberType(state.fiberType));
    }, [state.fiberType, storeDispatch]);

    return {
        label: fiberTypeSelect.label,
        select: {
            variant: "filled" as "filled",
            value: fiberTypeSelect.value,
            onChange: onFiberTypeChange
        },
        options: fiberTypes
    };
}

const useEnvironment = (redux: [IGeneralSetupState, React.Dispatch<AnyAction>?]) => {
    const [state, stepDispatcher] = redux;
    const { environment } = state;
    const { t } = useTranslation();
    const dispatch = useStoreDispatch();

    const onEnvironmentChange = useCallback((e) => {
        const cableEnvironment = e.currentTarget.value;
        if (stepDispatcher) {
            stepDispatcher(setCableEnvironment(cableEnvironment));
            if(cableEnvironment === CableEnvironments.IndoorOutdoor) {
                stepDispatcher(setWizardSetupState({ stepCount: 3 }));
                stepDispatcher(setBreakoutStepState({ ...initialAsymmetricBreakoutStep }));
                stepDispatcher(setGeneralSetupState({ shape: "Asymmetrical" }))
            }
        }
    }, [stepDispatcher]);

    const radioProps = {
        value: environment,
        options: [
            {
                className: environment === CableEnvironments.Indoor ? "selected" : "",
                label: t(LocalizationKeys.Indoor),
                radioButtonProps: { value: CableEnvironments.Indoor, onChange: onEnvironmentChange },
            },
            {
                className: environment === CableEnvironments.Outdoor ? "selected" : "",
                label: t(LocalizationKeys.Outdoor),
                radioButtonProps: { value: CableEnvironments.Outdoor, onChange: onEnvironmentChange },
            },
            {
                className: environment === CableEnvironments.IndoorOutdoor ? "selected" : "",
                label: t(LocalizationKeys.IndoorOutdoor),
                radioButtonProps: { value: CableEnvironments.IndoorOutdoor, onChange: onEnvironmentChange }
            }
        ]
    };

    useEffect(() => {
        dispatch(setupEnvironment(state.environment))
    }, [state.environment, dispatch]);

    return { ...radioProps };
}

const useJacket = () => {
    const { t } = useTranslation();
    const jacketColors = useSelector(filteredJacketColorSelector);
    const jacketColor = useSelector(currentJacketColorSelector);
    const [showColorDialog, setShowColorDialog] = useState(false);
    const storeDispatch = useStoreDispatch();

    const openColorDialog = useCallback(() => {
        setShowColorDialog(true);
    }, []);

    const closeColorDialog = useCallback(() => {
        setShowColorDialog(false);
    }, []);

    const jacketField = {
        className: "color-picker",
        label: t(LocalizationKeys.JacketColor),
        value: jacketColor.name,
        palette: MainPalettes.primary
    };

    const jacketIcon = {
        className: "palette-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement
    }

    const jacket = {
        field: jacketField,
        icon: jacketIcon,
        onClick: openColorDialog
    };

    const onColorButtonClick = useCallback((color: IColor) => {
        storeDispatch(setupJacketColor(color.name));
    }, [storeDispatch]);

    const colorDialogProps: IColorDialogProps = {
        open: showColorDialog,
        onClose: closeColorDialog,
        className: "general-setup-jacket",
        currentColor: jacketColor,
        colors: jacketColors,
        onColorButtonClick
    }

    return { jacket, colorDialogProps };
}

const useOverallLength = (redux: [IGeneralSetupState, React.Dispatch<AnyAction>?]) => {
    const [generalSetup, dispatch] = redux;
    const storeDispatch = useStoreDispatch();
    const { t } = useTranslation();
    const onOverallLengthChange = useCallback((l: Length) => {
        if (dispatch) {
            dispatch(setOverallLength(l));
        }
        storeDispatch(setupOverallLength(l));
    }, [dispatch, storeDispatch]);
    const overallLengthField = useLengthField({ label: t(LocalizationKeys.OverallLength), length: generalSetup.overallLength, callback: onOverallLengthChange, isPrimary: false })
    
    return {
        className: "overall-length",
        label: overallLengthField.label,
        value: overallLengthField.value,
        palette: MainPalettes.primary,
        error: !overallLengthField.isValid,
        onChange: overallLengthField.onChange
    }
}

const useEndDesignation = (end: Side) => {
    const [designation, setDesignation] = useState<string | undefined>();
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();
    const onEndDesignationChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const designation = e.currentTarget.value;
        setDesignation(designation);
        storeDispatch(setupEndDesignation({ end, designation }));
    }, [end, storeDispatch]);

    return {
        className: "end-designation",
        label: t(LocalizationKeys.EndDesignation, { side: end === Sides.SideA ? "A" : "B" }),
        value: designation,
        palette: MainPalettes.primary,
        maxLength: 20,
        onChange: onEndDesignationChange,
    };
}

const usePullingGrip = (redux: [IGeneralSetupState, React.Dispatch<AnyAction>?]) => {
    const [state, dispatch] = redux;
    const pullingGrip = useSelector(currentPullingGripSelector);
    const { t } = useTranslation();
    const pullingGrips = Object.values(PullingGrips);
    const pullingGripLabel = t(LocalizationKeys.PullingGrip);
    const pullingGripSelect = useSelectInput(pullingGrips, pullingGripLabel, "", state.pullingGrip);
    const storeDispatch = useStoreDispatch();

    const onPullingGripChange = useCallback((e) => {
        if (dispatch) {
            const pullingGrip = e.target.value;
            dispatch(setPullingGrip(pullingGrip));
        }
    }, [dispatch]);

    useEffect(() => {
        if (pullingGrip !== state.pullingGrip) {
            storeDispatch(setupPullingGrip(state.pullingGrip));
        }
    }, [pullingGrip, state.pullingGrip, storeDispatch]);

    return {
        label: pullingGripSelect.label,
        select: {
            variant: "filled" as "filled",
            value: pullingGripSelect.value,
            onChange: onPullingGripChange,
        },
        options: pullingGrips
    };
}

const useOuterDiameter = (redux: [IGeneralSetupState, React.Dispatch<AnyAction>?]) => {
    const [state, dispatch] = redux;
    const outerDiameter = useSelector(currentFurcationOuterDiameterSelector);
    const { t } = useTranslation();
    const outerDiameters = FurcationOuterDiameters;
    const outerDiameterLabel = t(LocalizationKeys.FurcationOuterDiamater);
    const outerDiameterSelect = useSelectInput(outerDiameters, outerDiameterLabel, "", state.furcationOuterDiameter);
    const storeDispatch = useStoreDispatch();

    const onOuterDiameterChange = useCallback((e) => {
        if (dispatch) {
            const outerDiameter = e.target.value;
            dispatch(setOuterDiameter(outerDiameter));
        }
    }, [dispatch]);

    useEffect(() => {
        if (outerDiameter !== state.furcationOuterDiameter) {
            storeDispatch(setupOuterDiameter(state.furcationOuterDiameter));
        }
    }, [outerDiameter, state.furcationOuterDiameter, storeDispatch]);

    return {
        label: outerDiameterSelect.label,
        select: {
            variant: "filled" as "filled",
            value: outerDiameterSelect.value,
            onChange: onOuterDiameterChange
        },
        options: outerDiameters
    };
}