import { createContext } from "react";
import { Side } from "../../../../../workspace/assembly/breakout/types";
import { LC, LC_SIMPLEX, LC_UPC, SC_SIMPLEX, SC_UPC, SN, MDC, LSH, SC_APC, SC_APC_SIMPLEX, SC_UPC_SIMPLEX, LC_APC, LC_APC_UNIBOOT, LC_APC_SIMPLEX, MMC16F_NP, MMC16F_P, MMC24F_NP, MMC24F_P, ECAM1F, PUSHLOK1F_SF, PUSHLOK4F_MF, PUSHLOK8F_MF, PUSHLOK12F_MF, PUSHLOK24F_MF, OPTITIP4F, OPTITIP8F, OPTITIP12F, OPTITAP1F_FEMALE, OPTITAP1F_MALE } from "../../../../../workspace/assembly/connector/types";
import { IContainerBox } from "../../../../interactions/bounding-box/types";

export const GREY_HIGHLIGHT = 0x9E9E9E;
export const BLUE_HIGHLIGHT = 0x0098DB;
export const GREEN_HIGHLIGHT = 0x4D8809;
export const WHITE_HIGHLIGHT = 0xFFFFFF;
export const ORANGE_HIGHLIGHT = 0xF5770A;

export interface IConnectorHighlightProps {
    boundingBox: IContainerBox;
    color: number;
    borderColor: number;
    alpha: number;
    fillAlpha?: number;
}

export const HighlightStatuses = {
    Selected: "Selected",
    Assigned: "Assigned",
    AssignedSelected: "AssignedSelected"
} as const;

export type HighlightStatus = typeof HighlightStatuses[keyof typeof HighlightStatuses];

export interface IHighlightedConnector {
    side: Side;
    breakoutPosition: number;
    connectorPosition: number;
    status: HighlightStatus;
}

export const HighlightContext = createContext<IHighlightedConnector[]>([]);

export function getHighlightContainerOffset(connectorType: string) {
    switch (connectorType) {
        case SC_SIMPLEX:
        case SC_APC_SIMPLEX:
        case SC_UPC_SIMPLEX:
            return 2;
        case LC_SIMPLEX:
        case LC_APC_SIMPLEX:
        case ECAM1F: 
            return 2.5;
        case LSH:
            return 4;
        case LC:
        case LC_APC_UNIBOOT:
        case MDC:
        case SN:
        case MMC16F_NP:
        case MMC16F_P:
        case MMC24F_NP:
        case MMC24F_P:
            return 5;
        case LC_APC:
        case LC_UPC:
            return 7;
        case SC_APC:
        case SC_UPC:
            return 10;
        case OPTITAP1F_MALE:
        case OPTITAP1F_FEMALE:
        case OPTITIP4F:
        case OPTITIP8F:
        case OPTITIP12F: 
        case PUSHLOK4F_MF:
        case PUSHLOK8F_MF:
        case PUSHLOK12F_MF:
        case PUSHLOK24F_MF:
        case PUSHLOK1F_SF:
            return 1.5;
        default:
            return 10.5;
    }
}

export const ConnectorHighlightGraphics = "connector-highlight-graphics";