import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../localization/types";
import { useSelector } from "react-redux";
import { fiberMapsSelector, forceUpdatePolaritySelector, highlightedConnectorsSelector, polarityMapsSelector, showConnectorAssignmentSelector } from "../store/selectors";
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { ButtonProps } from "@orbit/button";
import { currentAssemblyFiberCountsSelector, currentAssemblyPolarityConfigurationsSelector, currentAssemblyPolaritySelector, currentAssemblySelector, currentConnectorAssignmentsSelector, sideBreakoutsSelectorFactory, sortedSideBreakoutsSelectorFactory } from "../../assembly/store/selectors";
import { IBreakout, IConnector, Side, Sides } from "../../assembly/breakout/types";
import { NavigationBarReducer, setCurrentIndex } from "../../../ui/navigation/store/reducer";
import { initialNavigationBarState } from "../../../ui/navigation/store/types";
import { addOrUpdateHighlights, ConnectorAssignmentReducer, handleAssignmentMethod, handleMultiSelection, resetAll, resetAssignmentSelection, resetSelection, setAutomaticMode, setConnectorAssignments, setPolarityAssignment, setPropagating, setSaving, updateAssignment } from "./reducer/reducer";
import { IConnectorAssignmentContext, IConnectorAssignmentMap, IConnectorAssignmentSet, IConnectorAssignmentState, IConnectorMap, initialConnectorAssignmentState } from "./reducer/types";
import { assignmentRowToConnectorMap, IAssignmentRowData, AssignmentPatterns } from "./types";
import { getConnectorType, getConnectorTypeString, isOutdoorConnector } from "../../assembly/connector/types";
import { CustomMap, CUSTOM_MAP_KEY, IPolarityMap } from "../store/types";
import { setForceUpdate, setHighlightedConnectors, showConnectorAssignment, setShowPropagation, showPolaritySchemes } from "../store/reducer";
import { generateConnectorAssignment } from "./reducer/actions";
import { addConnectorAssignments, deleteConnectorAssignments } from "../../assembly/store/reducer";
import { workspaceInactiveSelector } from "../../store/selectors";
import { PolarityContext } from "../reducer/types";
import { setConnectorAssignmentToUpdate } from "../reducer/reducer";
import { IFiberMapData } from "../fiber-map/types";
import { IPolaritySchemeProps } from "../polarity-scheme/types";
import { HighlightStatuses } from "../../../pixi/cable/breakout/connector-furcation/highlight/types";
import { setStatus } from "../../store/reducer";
import { Status } from "../../store/types";
import { useStoreDispatch } from "../../../store/hooks";
import { DialogProps, IDialogActions } from "@orbit/dialog";
import { MainPalettes, MainThemeTokens } from "@orbit/theme-provider";
import { IconButtonProps, TooltipPlacement } from "@orbit/icon-button";
import { CheckboxProps } from "@orbit/checkbox";
import { t } from "i18next";
import { SelectChangeEvent } from "@mui/material";
import { Launch } from "@mui/icons-material";

export const useConnectorAssignmentDialog = () => {
    const { dialogProps } = useDialog();
    const { sideProps: sideAProps } = useSideNavigation(Sides.SideA);
    const { sideProps: sideBProps } = useSideNavigation(Sides.SideB);
    const [state, dispatch] = useReducer(ConnectorAssignmentReducer, initialConnectorAssignmentState);
    const assignmentContext: IConnectorAssignmentContext = { state, dispatch };
    const { sideADataRows, sideBDataRows } = useAssignmentData(sideAProps.breakout, sideBProps.breakout, assignmentContext);
    const { polarity } = usePolarityMaps(state);
    const { polarityPatterns } = usePolarityPatterns(assignmentContext);
    const { selectAllProps } = useSelectAll(assignmentContext);
    const { resetAll } = useResetAll(assignmentContext);
    const { apply } = useApply(assignmentContext, polarity.select.value);
    const { propagation } = usePropagation(assignmentContext);
    const highlightedConnectors = useSelector(highlightedConnectorsSelector);
    const connectorAssignments = useSelector(currentConnectorAssignmentsSelector);
    const storeDispatch = useStoreDispatch();

    useEffect(() => {
        dispatch(setConnectorAssignments(connectorAssignments));
    }, [connectorAssignments, dispatch]);

    useEffect(() => {
        if (!dialogProps.open) {
            dispatch(resetSelection());
            dispatch(resetAssignmentSelection());
            storeDispatch(setHighlightedConnectors([]));
        }
    }, [dialogProps.open, dispatch, storeDispatch]);

    useEffect(() => {
        if (!dialogProps.open) {
            dispatch(setPropagating(false));
        }
    }, [state.propagating, dialogProps.open, dispatch]);

    useEffect(() => {
        if (dialogProps.open) {
            const assignedMaps = state.assignments.flatMap(a => [...a.connectors.sideAMapping, ...a.connectors.sideBMapping]);
            dispatch(addOrUpdateHighlights({ maps: assignedMaps, status: HighlightStatuses.Assigned }))
        }
    }, [dialogProps.open, state.assignments, dispatch]);

    useEffect(() => {
        if (dialogProps.open) {
            const storeStatuses = highlightedConnectors.map(h => h.status).filter((s, i, self) => self.indexOf(s) === i);
            const stateStatuses = state.highlights.map(h => h.status).filter((s, i, self) => self.indexOf(s) === i);
            const storeAssignedSelectedIndex = highlightedConnectors.findIndex(h => h.status === HighlightStatuses.AssignedSelected);
            const stateAssignedSelectedIndex = state.highlights.findIndex(h => h.status === HighlightStatuses.AssignedSelected);
            const hasSameStatuses = storeStatuses.length === stateStatuses.length && storeStatuses.every(s => stateStatuses.includes(s)) && storeAssignedSelectedIndex === stateAssignedSelectedIndex;
            if (state.highlights.length !== highlightedConnectors.length || !hasSameStatuses) {
                storeDispatch(setHighlightedConnectors(state.highlights));
            }
        }
    }, [dialogProps.open, highlightedConnectors, state.highlights, storeDispatch]);


    const onPolaritySchemeClick = useCallback(() => {
        storeDispatch(showPolaritySchemes(true));
    }, [storeDispatch]);


    const showPolarityButtonProps: IconButtonProps = {
        className: "show-polarity-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        placement: "bottom" as TooltipPlacement,
        onClick: onPolaritySchemeClick,
        title: t(LocalizationKeys.PolaritySchemes),
        icon: Launch
    };

    const polaritySchemes = {
        buttonProps: showPolarityButtonProps,
        label: t(LocalizationKeys.PolaritySchemes)
    };


    return { 
        dialogProps,
        polaritySchemes,
        assignmentContext,
        selectAllProps,
        sideAProps: {
            ...sideAProps,
            rows: sideADataRows
        },
        sideBProps: {
            ...sideBProps,
            rows: sideBDataRows
        }, 
        resetAll,
        polarity,
        polarityPatterns,
        apply,
        propagation
    };
}

const useDialog = () => {
    const open = useSelector(showConnectorAssignmentSelector);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const onClose = useCallback(() => {
        storeDispatch(showConnectorAssignment(false));
        storeDispatch(showPolaritySchemes(false));
    }, [storeDispatch]);

    const dialogProps: DialogProps = {
        open,
        className: "connector-assignment-dialog",
        title: t(LocalizationKeys.ConnectorAssignment),
        header: {
            collapsible: true,
        },
        modal: false,
        onClose
    };

    return { dialogProps };
}

const useSideNavigation = (side: Side) => {
    const breakouts = useSelector(sideBreakoutsSelectorFactory(side));
    const [state, dispatch] = useReducer(NavigationBarReducer, initialNavigationBarState);
    const { currentIndex, disabled } = state;
    const [breakout, setBreakout] = useState(breakouts[0]);

    useEffect(() => {
        setBreakout(breakouts[0]);
    }, [breakouts])

    const onNavigationButtonClick = useCallback((index: number) => {
        dispatch(setCurrentIndex(index));
        setBreakout(breakouts[index]);
    }, [dispatch, breakouts]);

    const onPreviousClick = useCallback(() => {
        const previousIndex = currentIndex ? currentIndex - 1 : 0;
        onNavigationButtonClick(previousIndex);
    }, [currentIndex, onNavigationButtonClick]);

    const onNextClick = useCallback(() => {
        const nextIndex = (currentIndex + 1) % breakouts.length;
        onNavigationButtonClick(nextIndex);
    }, [currentIndex, breakouts, onNavigationButtonClick]);

    const sideCharacter = side === Sides.SideA ? "A" : "B"; 
    const previousDisabled = currentIndex === 0 || disabled;
    const previousProps = {
        className: "previous-breakout-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: previousDisabled ? "" : `${sideCharacter}${breakout.position - 1}`,
        placement: "bottom" as TooltipPlacement,
        disabled: previousDisabled,
        onClick: onPreviousClick
    };

    const nextDisabled = currentIndex + 1 === breakouts.length || disabled;
    const nextProps = {
        className: "next-breakout-icon",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        title: nextDisabled ? "" : `${sideCharacter}${breakout.position + 1}`,
        placement: "bottom" as TooltipPlacement,
        disabled: nextDisabled,
        onClick: onNextClick
    };

    return { 
        sideProps: {
            breakout,
            title: `${sideCharacter}${breakout.position}`,
            previousProps,
            nextProps
        }
     };
}

const useAssignmentData = (sideABreakout: IBreakout, sideBBreakout: IBreakout, context: IConnectorAssignmentContext) => {
    const { state, dispatch } = context;
	const workspaceInactive = useSelector(workspaceInactiveSelector);
    const forceUpdate = useSelector(forceUpdatePolaritySelector);
    const assignments = useSelector(currentConnectorAssignmentsSelector);    
    const { state: polarityState, dispatch: polarityDispatch } = useContext(PolarityContext);
    const { connectorAssignmentToUpdate } = polarityState;
    const storeDispatch = useStoreDispatch();

    useEffect(() => {
        if (workspaceInactive || forceUpdate) {
            if (forceUpdate) {
                dispatch(setConnectorAssignments(assignments.length ? assignments : []));
                storeDispatch(setForceUpdate(false));
            }
        }
    }, [workspaceInactive, forceUpdate, dispatch, assignments, storeDispatch]);

    useEffect(() => {
        if (connectorAssignmentToUpdate) {
            dispatch(updateAssignment(connectorAssignmentToUpdate));
            polarityDispatch(setConnectorAssignmentToUpdate());
        }
    }, [connectorAssignmentToUpdate, dispatch, polarityDispatch]);

    const sideADataRows = useMemo(() => {
        const sideAConnectors = sideABreakout.furcation.groups.flatMap(g => g.connectors);
        return getAssignmentDataRows(Sides.SideA, sideABreakout.position, sideAConnectors, state);
    }, [sideABreakout, state]);

    const sideBDataRows = useMemo(() => {
        const sideBConnectors = sideBBreakout.furcation.groups.flatMap(g => g.connectors);
        return getAssignmentDataRows(Sides.SideB, sideBBreakout.position, sideBConnectors, state);
    }, [sideBBreakout, state]);

    return { sideADataRows, sideBDataRows };
}

const getAssignmentDataRows = (side: Side, position: number, connectors: IConnector[], state: IConnectorAssignmentState) => {
    const { selection, assignedSelection, assignments, propagating } = state;
    const { sideAMapping, sideBMapping } = selection;
    const rows: IAssignmentRowData[] = [];

    for (let i = 0; i < connectors.length; i++) {
        const connector = connectors[i];
        const rowId = i + 1;
        let assignmentId: number | undefined;
        const connectorPosition = connector.position + 1;
        let fiberCount = 0;
        if (connector.type) {
            const connectorType = getConnectorType(connector.type);
            fiberCount = connectorType.fiberCount;
        }
        let assigned = false;
        let assignedIndex = -1;
        let assignedSelectionIndex = -1;
        let selectionIndex = -1;
        let unassignedFibers = fiberCount;
        let polarityKey = -1;
        let blockedFiberCount = 0;
        if (side === Sides.SideA) {
            selectionIndex = sideAMapping.findIndex(m => m.breakoutPosition === position && m.index === rowId);
            assignedSelectionIndex = assignedSelection.sideAMapping.findIndex(m => m.breakoutPosition === position && m.index === rowId);
            const assignedSourceMap = assignments.find(a => a.connectors.sideAMapping.find(m => m.breakoutPosition === position && m.index === rowId));
            if (assignedSourceMap) {
                assignmentId = assignedSourceMap.id;
                polarityKey = assignedSourceMap.polarityKey;
                const sourceMap = assignedSourceMap.connectors.sideAMapping.find(m => m.breakoutPosition === position && m.index === rowId);
                if (sourceMap) {
                    blockedFiberCount = sourceMap.blockedFiberCount || 0
                    assigned = true;
                    assignedIndex = assignedSourceMap.connectors.sideAMapping.indexOf(sourceMap);
                    unassignedFibers = sourceMap.unassignedFibers;
                }
            }
        } else {
            selectionIndex = sideBMapping.findIndex(m => m.breakoutPosition === position && m.index === rowId);
            assignedSelectionIndex = assignedSelection.sideBMapping.findIndex(m => m.breakoutPosition === position && m.index === rowId);
            const assignedDestinationMap = assignments.find(a => a.connectors.sideBMapping.find(m => m.breakoutPosition === position && m.index === rowId));
            if (assignedDestinationMap) {
                assignmentId = assignedDestinationMap.id;
                polarityKey = assignedDestinationMap.polarityKey;
                const destinationMap = assignedDestinationMap.connectors.sideBMapping.find(m => m.breakoutPosition === position && m.index === rowId);
                if (destinationMap) {
                    blockedFiberCount = destinationMap.blockedFiberCount || 0
                    assigned = true;
                    assignedIndex = assignedDestinationMap.connectors.sideBMapping.indexOf(destinationMap);
                    unassignedFibers = destinationMap.unassignedFibers;
                }
            }
        }


        const selected = selectionIndex !== -1 || assignedSelectionIndex !== -1;
        let className: string | undefined = undefined;
        if (assigned) {
            className = selected ? "assigned selected" : "assigned";
        } else {
            className = selected ? "selected" : undefined;
        }
        const fiberAssignmentLabel = polarityKey === CUSTOM_MAP_KEY ? "0/" + fiberCount : fiberCount - unassignedFibers + "/" + (fiberCount - blockedFiberCount);
        const selectionLabel = assigned ? (assignedIndex + 1).toString() : (selectionIndex + 1).toString();
        
        let propagated: boolean | undefined = undefined;

        const assignment = assignments.find(a => a.id === assignmentId);
        if (propagating && assignment) {
            const { propagatedTriggerColor, propagatedLabelColor, propagatedLabelText } = assignment;
            const propagationAttributes = [propagatedTriggerColor, propagatedLabelColor, propagatedLabelText].filter(b => b !== undefined);
            if (propagationAttributes.length > 0) {
                propagated = propagationAttributes.every(p => p);
            }
        }
        rows.push({
            id: rowId,
            assignmentId,
            className,
            side,
            position,
            connectorPosition,
            fiberCount,
            unassignedFibers,
            fiberAssignmentLabel,
            assigned,
            selected,
            selectionIndex,
            selectionLabel,
            propagated,
            connectorType: connector.type || '',
        });
    }
    return rows;
}

const usePolarityMaps = (state: IConnectorAssignmentState) => {
    const { selection, assignments, saving, highlights } = state;
    const { sideAMapping, sideBMapping } = selection;
    const { t } = useTranslation();
    const predefinedPolarityMaps = useSelector(polarityMapsSelector);
    const [polarityType, setPolarityType] = useState("-1");

    const polarityMaps = useMemo(() => {
        const fiberMapKey = isNaN(Number(polarityType)) ? CUSTOM_MAP_KEY : Number(polarityType);
        const selectPolarity: IPolarityMap = { key: -1, description: t(LocalizationKeys.SelectPolarity) };
        let polarityMaps: IPolarityMap[] = [];
        if (sideAMapping.length && sideBMapping.length) {
            polarityMaps = predefinedPolarityMaps.filter(m => {
                return m.from && sideAMapping.every(c => c.fiberCount === m.from?.[0]) &&
                       m.to && sideBMapping.every(c => c.fiberCount === m.to?.[0])
            });

            if (polarityType === "-1") {
                if (polarityMaps.length && polarityMaps[0].key === CUSTOM_MAP_KEY) {
                    setPolarityType(polarityMaps[0].description);
                }

                const typeBMap = polarityMaps.find(m => (m.description === "Type B" || m.description === "A to B") && m.key !== 9999);
                if (typeBMap) {
                    setPolarityType(typeBMap.key.toString());
                }
    
                const typeAMTP24 = polarityMaps.find(m => ((m.from?.includes(24)|| m.to?.includes(24))) && m.description === "Type A" && m.key !== 9999);
                if (typeAMTP24) {
                    setPolarityType(typeAMTP24.key.toString());
                }

                if (polarityMaps.length === 0) {
                    setPolarityType("Custom");
                }
            }
        } else if (highlights.some(h=>h.status === 'AssignedSelected')) {
            const assignedSelectedHighlights = highlights.filter(h => h.status === "AssignedSelected");
            const assignedSelectedSideA = assignedSelectedHighlights.filter(h => h.side === "SideA");
            const assignedSelectedSideB = assignedSelectedHighlights.filter(h => h.side === "SideB");
            const matchingAssignment = assignments.find(
                a =>
                    assignedSelectedSideA.every(aSA =>
                        a.connectors.sideAMapping.some(c => c.breakoutPosition === aSA.breakoutPosition && c.index === aSA.connectorPosition)
                    ) &&
                    assignedSelectedSideB.every(aSB =>
                        a.connectors.sideBMapping.some(c => c.breakoutPosition === aSB.breakoutPosition && c.index === aSB.connectorPosition)
                    )
            );
            if (matchingAssignment) {
                let polarityMap: IPolarityMap | undefined = undefined;
                let polarityMapKey = Number(matchingAssignment.fiberMapData.key)
                if (polarityMapKey !== CUSTOM_MAP_KEY) {
                    polarityMap = predefinedPolarityMaps.find(m => m.key === polarityMapKey);
                    setPolarityType(polarityMapKey.toString());
                } else {
                    polarityMap = predefinedPolarityMaps.find(m => m.description === matchingAssignment.fiberMapData.name);
                    const customPolarityType = polarityMap?.description ?? "Custom";
                    setPolarityType(customPolarityType);
                }

                if (polarityMap) {
                    polarityMaps.push(polarityMap)
                } 
            }
        } else {
            setPolarityType("-1");
        }
            console.log("test", sideAMapping)

        polarityMaps = [selectPolarity, ...polarityMaps, CustomMap];
        if (!polarityMaps.find(m => m.key === fiberMapKey) || (fiberMapKey === CUSTOM_MAP_KEY && !polarityMaps.find(m => m.key === CUSTOM_MAP_KEY && m.description === polarityType))) {
            setPolarityType("-1");
        }
        if(sideAMapping.some(m => isOutdoorConnector(getConnectorTypeString(m.connectorType))) || sideBMapping.some(m => isOutdoorConnector(getConnectorTypeString(m.connectorType)))) {
            polarityMaps = [CustomMap];
            setPolarityType("Custom")
        }
        return polarityMaps;
    }, [sideAMapping, sideBMapping, polarityType, t, predefinedPolarityMaps, assignments, highlights]);

    const onPolarityChange = useCallback((e) => {
        const polarity = e.target.value;
        if (polarity !== -1) {
            setPolarityType(polarity);
        }
    }, []);

    const options = polarityMaps.map(m => ({ value: m.key === CUSTOM_MAP_KEY ? m.description : m.key, label: m.description }));

    const polarityDisabled  = (selection.sideAMapping.length === 0 || selection.sideBMapping.length === 0) || saving;

    return { 
        polarity: {
            select: {
                value: polarityType,
                disabled: polarityDisabled,
                onChange: onPolarityChange
            },
            options,
        }
     };
}

const usePolarityPatterns = (context: IConnectorAssignmentContext) => {
    const currentAssembly = useSelector(currentAssemblySelector);
    const { state, dispatch } = context;
    const { saving, automaticMode, patternType } = state;

    useEffect(() => {
        if (currentAssembly && currentAssembly.polarityAssignment && currentAssembly.polarityAssignment.length > 0) {
            if (patternType !== currentAssembly.polarityAssignment) {
                dispatch(setPolarityAssignment(currentAssembly.polarityAssignment));
            }
        }
    }, [patternType, currentAssembly, dispatch]);

    const onAssignmentMethodChange = useCallback(
        (e: SelectChangeEvent<string>) => {
            const assignment = e.target.value;
            dispatch(handleAssignmentMethod(assignment));
        },
        [dispatch]
    );

    const options = [{ value: AssignmentPatterns.Standard, label: t(LocalizationKeys.AssignmentPatternStandard) }];
    return {
        polarityPatterns: {
            select: {
                value: patternType,
                disabled: !automaticMode || saving,
                onChange: onAssignmentMethodChange,
            },
            options,
        },
    };
};

const useSelectAll = (context: IConnectorAssignmentContext) => {
    const { state, dispatch } = context;
    const { saving, assignments, highlights, automaticMode } = state;
    const sideABreakouts = useSelector(sortedSideBreakoutsSelectorFactory(Sides.SideA));
    const sideBBreakouts = useSelector(sortedSideBreakoutsSelectorFactory(Sides.SideB));
    const { sideAFiberCounts, sideBFiberCounts } = useSelector(currentAssemblyFiberCountsSelector);
    const { t } = useTranslation();

    const allConnectorsAssigned = useMemo(() => {
        const nbAssignedConnectors = assignments.flatMap(a => [...a.connectors.sideAMapping, ...a.connectors.sideBMapping]).length;
        const nbConnectors = [
            ...sideABreakouts
                .map(b => b.furcation)
                .flatMap(f => f.groups)
                .flatMap(g => g.connectors),
            ...sideBBreakouts
                .map(b => b.furcation)
                .flatMap(f => f.groups)
                .flatMap(g => g.connectors),
        ].length;
        return nbAssignedConnectors === nbConnectors;
    }, [assignments, sideABreakouts, sideBBreakouts]);

    const connectorMaps = useMemo(() => {
        const connectorMaps: IConnectorMap[] = [];
        for (const sideABreakout of sideABreakouts) {
            const breakoutConnectors = sideABreakout.furcation.groups.flatMap(g => g.connectors);
            const dataRows = getAssignmentDataRows(Sides.SideA, sideABreakout.position, breakoutConnectors, state);
            for (const row of dataRows) {
                connectorMaps.push(assignmentRowToConnectorMap(row));
            }
        }
        for (const sideBBreakout of sideBBreakouts) {
            const breakoutConnectors = sideBBreakout.furcation.groups.flatMap(g => g.connectors);
            const dataRows = getAssignmentDataRows(Sides.SideB, sideBBreakout.position, breakoutConnectors, state);
            for (const row of dataRows) {
                connectorMaps.push(assignmentRowToConnectorMap(row));
            }
        }
        return connectorMaps;
    }, [sideABreakouts, state, sideBBreakouts]);

    useEffect(() => {
        dispatch(setAutomaticMode(highlights.length > 0 && highlights.some(h => h.status === "Selected") && connectorMaps.length === highlights.length));
    }, [dispatch, highlights, connectorMaps]);

    const onSelectAllCheckboxChanged = useCallback(
        (checked: boolean) => {
            dispatch(setAutomaticMode(checked));
            if (checked) {
                dispatch(handleMultiSelection(connectorMaps));
            } else {
                dispatch(resetSelection());
            }
        },
        [dispatch, connectorMaps]
    );

    const selectAllDisabled = sideAFiberCounts.length > 1 || sideBFiberCounts.length > 1 || allConnectorsAssigned || saving;
    const selectAllProps: CheckboxProps = {
        palette: MainPalettes.primary,
        placement: "end",
        label: t(LocalizationKeys.SelectAllConnectors),
        disabled: selectAllDisabled,
        checked: automaticMode,
        onChange: e => onSelectAllCheckboxChanged(e.currentTarget.checked),
    };

    return { selectAllProps };
};

const useResetAll = (context: IConnectorAssignmentContext) => {
    const { state, dispatch: assignmentDispatch } = context;
    const polarity = useSelector(currentAssemblyPolaritySelector);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const resetAllDisabled = state.assignments.length === 0;
    const [showResetDialog, setShowResetDialog] = useState(false);
    const onResetAll = useCallback(() => {
        setShowResetDialog(true);
    }, []);

    const onResetClose = useCallback(() => {
        setShowResetDialog(false);
    }, [])

    const onResetConfirm = useCallback(() => {
        if (polarity?.id) {
            storeDispatch(setStatus(Status.Synchronizing));
            assignmentDispatch(resetAll());
            storeDispatch(deleteConnectorAssignments(polarity.id));
            onResetClose();
        }
    }, [polarity, storeDispatch, assignmentDispatch, onResetClose]);

    const buttonProps: ButtonProps = {
        className: "reset-button",
        palette: MainPalettes.error,
        token: MainThemeTokens.main,
        disabled: resetAllDisabled,
        onClick: onResetAll,
    };

    const actions: IDialogActions = {
        cancelText: t(LocalizationKeys.Cancel),
        onCancelClick: onResetClose,
        confirmText: t(LocalizationKeys.Reset), 
        onConfirmClick: onResetConfirm,
        actionGuidance: {
            button: 'confirm',
            severity: 'critical'
        },
    };
 
    const dialogProps: DialogProps = {
        open: showResetDialog,
        className: "reset-connector-assignement-dialog",
        title: t(LocalizationKeys.ResetAll),
        message: t(LocalizationKeys.ResetConnectorAssignment),
        actions,
        onClose: onResetClose
    };

    return { 
        resetAll: {
            buttonProps,
            label: t(LocalizationKeys.ResetAll),
            dialogProps
        } 
    };
}

const useApply = (context: IConnectorAssignmentContext, polarityType: string) => {
    const { state, dispatch } = context;
    const { selection, saving } = state;
    const connectorSet = selection;
    const { t } = useTranslation();
    const fiberMaps = useSelector(fiberMapsSelector);
    const predefinedPolarityMaps = useSelector(polarityMapsSelector);
    const selectedConfiguration = useSelector(currentAssemblyPolarityConfigurationsSelector)[0];
    const polaritySchemes = predefinedPolarityMaps.filter(m => selectedConfiguration.polarityMaps.includes(m)).map(m => {
        const polarityScheme: IPolaritySchemeProps = { id: m.id, key: m.key, label: m.description, imageUri: m.imageUri, isCustom: m.key === CUSTOM_MAP_KEY };
        return polarityScheme;
    });
    const { id: assemblyId } = useSelector(currentAssemblySelector);
    const storeDispatch = useStoreDispatch();

    const dispatchConnectorAssignments = useCallback((fiberMapKey: number, predefinedFiberMap?: IFiberMapData) => {
        if (assemblyId) {
            const connectorAssignments: IConnectorAssignmentMap[] = [];
            const { sideAMapping, sideBMapping } = connectorSet;
            if (predefinedFiberMap) {
                const polarityMap = predefinedPolarityMaps.find(m => m.key === CUSTOM_MAP_KEY ? m.id === predefinedFiberMap.id : m.key === predefinedFiberMap.key);
                if (polarityMap && polarityMap.from && polarityMap.to) {
                    let sideAIndex = 0
                    let sideBIndex = 0;
                    const { from, to } = polarityMap;
                    while (sideAIndex < sideAMapping.length || sideBIndex < sideBMapping.length) {
                        const sideAMap = sideAMapping.slice(sideAIndex, sideAIndex + from.length)
                        const sideBMap = sideBMapping.slice(sideBIndex, sideBIndex + to.length);
                        if (sideAMap.length > 0 && sideBMap.length > 0) {
                            const set: IConnectorAssignmentSet = { // Each set needs to be considered as their own selection
                                sideAMapping: sideAMap.map((c, i) => { return { ...c, orderIndex: i }}),
                                sideBMapping: sideBMap.map((c, i) => { return { ...c, orderIndex: i }}) 
                            };
                            const connectorAssignment = generateConnectorAssignment(set, fiberMapKey, predefinedFiberMap);
                            connectorAssignments.push(connectorAssignment);
                        }
                        sideAIndex += from.length;
                        sideBIndex += to.length;
                    }
                }
            } else {
                const connectorAssignment = generateConnectorAssignment(connectorSet, fiberMapKey);
                connectorAssignments.push(connectorAssignment);
            }
            storeDispatch(addConnectorAssignments(assemblyId, connectorAssignments, dispatch));
        }
    }, [connectorSet, predefinedPolarityMaps, storeDispatch, assemblyId, dispatch]);

    const onApply = useCallback(() => {
        dispatch(setSaving(true));
        storeDispatch(setStatus(Status.Saving));
        const fiberMapKey = isNaN(Number(polarityType)) ? CUSTOM_MAP_KEY : Number(polarityType);
        const fiberMapID = polaritySchemes.find(s => s.label === polarityType && s.key === fiberMapKey)?.id;
        const predefinedFiberMap = fiberMapKey === CUSTOM_MAP_KEY ? fiberMaps.find(f => f.id === fiberMapID && f.key === fiberMapKey && f.name === polarityType) : fiberMaps.find(f => f.key === fiberMapKey);
        dispatchConnectorAssignments(fiberMapKey, predefinedFiberMap);
    }, [dispatch, storeDispatch, polarityType, polaritySchemes, fiberMaps, dispatchConnectorAssignments]);

    const applyDisabled = selection.sideAMapping.length === 0 || selection.sideBMapping.length === 0 || polarityType === "-1" || saving;
    const applyButtonProps: ButtonProps = {
        className: "apply-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: applyDisabled,
        onClick: onApply
    }

    const apply = {
        buttonProps: applyButtonProps,
        label: t(LocalizationKeys.Apply)
    }

    return { apply };
}

export const usePropagation = ({ state, dispatch }: IConnectorAssignmentContext) => {
    const connectorAssignments = useSelector(currentConnectorAssignmentsSelector);
    const { t } = useTranslation();
    const storeDispatch = useStoreDispatch();

    const onPropagationClick = useCallback(() => { 
        if (state.propagating) {
            dispatch(setPropagating(false));
        } else {
            storeDispatch(setShowPropagation(true));
        }
    },[state.propagating, dispatch, storeDispatch]);

    const buttonProps: ButtonProps = {
        className: "propagation-button",
        palette: MainPalettes.primary,
        token: MainThemeTokens.main,
        disabled: connectorAssignments.length === 0,
        onClick: onPropagationClick
    }

    return {
        propagation: {
            buttonProps,
            label: t(LocalizationKeys.Propagation, { status: state.propagating ? t(LocalizationKeys.On) : t(LocalizationKeys.Off) })
        }
    }
}