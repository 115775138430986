import React from "react";

export const OPTITAPFemale: React.FC = () => {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 90 443.25"
            width="90"
            height="443.25"
        >
            <g>
                <g>
                    <path
                        className="st0"
                        fill="#606161"
                        x=".5"
                        y="73.3"
                        width="19"
                        height="11.4"
                        d="M2.25 329.85H87.75V381.15H2.25V329.85z"
                    />
                    <path
                        className="st1"
                        fill="#494747"
                        d="M87.75 382.95H2.25c-1.35 0 -2.25 -0.9 -2.25 -2.25V329.4c0 -1.35 0.9 -2.25 2.25 -2.25h85.5c1.35 0 2.25 0.9 2.25 2.25v51.3c0 1.35 -0.9 2.25 -2.25 2.25M4.5 378.45h81.45V331.65H4.5z"
                    />
                </g>
                <g>
                    <g>
                        <path
                            className="st0"
                            fill="#606161"
                            x="4.4"
                            y="84.8"
                            width="11.4"
                            height="1.9"
                            d="M19.8 381.6H71.1V390.15H19.8V381.6z"
                        />
                        <path
                            className="st1"
                            fill="#494747"
                            d="M70.65 391.95H19.35c-1.35 0 -2.25 -0.9 -2.25 -2.25v-8.55c0 -1.35 0.9 -2.25 2.25 -2.25h51.3c1.35 0 2.25 0.9 2.25 2.25v8.55c0 1.35 -0.9 2.25 -2.25 2.25m-49.05 -4.5h47.25v-4.05H21.6z"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                fill="#606161"
                                x="5.2"
                                y="86.6"
                                width="9.5"
                                height="1.9"
                                d="M23.4 389.7H66.15V398.25H23.4V389.7z"
                            />
                            <path
                                className="st1"
                                fill="#494747"
                                d="M66.6 400.95H23.4c-1.35 0 -2.25 -0.9 -2.25 -2.25v-8.55c0 -1.35 0.9 -2.25 2.25 -2.25h42.75c1.35 0 2.25 0.9 2.25 2.25v8.55c0 1.35 -0.9 2.25 -2.25 2.25zM25.65 396h38.7v-4.05H25.65z"
                            />
                        </g>
                        <g>
                            <g>
                                <path
                                    className="st0"
                                    fill="#606161"
                                    d="M70.65 398.25v33.3c0 4.95 -4.05 9 -9 9H28.35c-4.95 0 -9 -4.05 -9 -9v-33.3z"
                                />
                                <path
                                    className="st1"
                                    fill="#494747"
                                    d="M62.1 443.25H27.9c-5.85 0 -10.8 -4.95 -10.8 -11.25v-33.3c0 -1.35 0.9 -2.25 2.25 -2.25h51.3c1.35 0 2.25 0.9 2.25 2.25V432c0 6.3 -4.95 11.25 -10.8 11.25m-40.5 -42.3V432c0 3.6 2.7 6.75 6.3 6.75h34.2c3.6 0 6.3 -3.15 6.3 -6.75v-31.05z"
                                />
                            </g>
                            <g>
                                <path
                                    className="st0"
                                    fill="#606161"
                                    d="M36.9 405h16.65c2.7 0 4.5 2.25 4.5 4.5v20.7c0 2.7 -1.8 4.5 -4.5 4.5H36.9c-2.7 0 -4.5 -2.25 -4.5 -4.5V409.5c0 -2.7 1.8 -4.5 4.5 -4.5"
                                />
                                <path
                                    className="st1"
                                    fill="#494747"
                                    d="M53.55 436.95H36.45c-3.6 0 -6.3 -3.15 -6.3 -6.75V409.5c0 -3.6 2.7 -6.75 6.3 -6.75h17.1c3.6 0 6.3 3.15 6.3 6.75v20.7c0 3.6 -2.7 6.75 -6.3 6.75m-17.1 -29.7c-1.35 0 -2.25 0.9 -2.25 2.25v20.7c0 1.35 0.9 2.25 2.25 2.25h17.1c1.35 0 2.25 -0.9 2.25 -2.25V409.5c0 -1.35 -0.9 -2.25 -2.25 -2.25z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                className="st0"
                                fill="#606161"
                                points="14.1 .5 5.7 .5 3.2 8.4 9.9 8.4 16.7 8.4 14.1 .5"
                                d="M63.45 2.25L25.65 2.25L14.4 37.8L44.55 37.8L75.15 37.8L63.45 2.25Z"
                            />
                            <path
                                className="st1"
                                fill="#494747"
                                d="M75.15 40.05H14.4c-0.9 0 -1.35 0 -1.8 -0.9s0 -1.35 0 -2.25L23.85 1.35c0 -0.9 0.9 -1.35 2.25 -1.35h37.35c0.9 0 1.8 0.45 2.25 1.35l11.25 35.1v2.25c0 0.9 -0.9 0.9 -1.8 0.9h-0.45ZM18 35.55h52.65l-9 -30.6H27.45L18 36Z"
                            />
                        </g>
                        <g>
                            <path
                                className="st0"
                                fill="#606161"
                                x="3.3"
                                y="8.4"
                                width="13.3"
                                height="12.9"
                                d="M14.85 37.8H74.7V95.85H14.85V37.8z"
                            />
                            <path
                                className="st1"
                                fill="#494747"
                                d="M75.15 98.1H14.4c-1.35 0 -2.25 -0.9 -2.25 -2.25V37.8c0 -1.35 0.9 -2.25 2.25 -2.25h59.85c1.35 0 2.25 0.9 2.25 2.25v58.05c0 1.35 -0.9 2.25 -2.25 2.25h0.45Zm-58.5 -4.5h55.8V40.05H16.65z"
                            />
                        </g>
                    </g>
                    <g>
                        <path
                            className="st0"
                            fill="#606161"
                            points="17.3 21.3 9.9 21.3 2.5 21.3 1.3 40.1 9.9 40.1 18.6 40.1 17.3 21.3"
                            d="M77.85 95.85L44.55 95.85L11.25 95.85L5.85 180.45L44.55 180.45L83.7 180.45L77.85 95.85Z"
                        />
                        <path
                            className="st1"
                            fill="#494747"
                            d="M83.7 182.7H5.85c-0.45 0 -1.35 0 -1.35 -0.9s-0.45 -0.9 -0.45 -1.8l5.4 -84.6c0 -1.35 0.9 -2.25 2.25 -2.25h66.6c0.9 0 2.25 0.9 2.25 2.25L85.95 180s0 1.35 -0.45 1.8c0 0 -0.9 0.9 -1.35 0.9h-0.9Zm-75.6 -4.5h72.45l-4.95 -80.1H13.05z"
                        />
                    </g>
                </g>
                <g>
                    <path
                        className="st0"
                        fill="#606161"
                        x="1.4"
                        y="40.1"
                        width="17.1"
                        height="41.6"
                        d="M6.3 180.45H83.25V367.65H6.3V180.45z"
                    />
                    <path
                        className="st1"
                        fill="#494747"
                        d="M83.7 369.9H5.85c-1.35 0 -2.25 -0.9 -2.25 -2.25V180.45c0 -1.35 0.9 -2.25 2.25 -2.25h76.95c1.35 0 2.25 0.9 2.25 2.25v187.2c0 1.35 -0.9 2.25 -2.25 2.25h0.45Zm-75.6 -4.5H81V182.7H8.1z"
                    />
                </g>
            </g>
        </svg>
    );
};

export const getOPTITAPFemaleSVGString = () =>
    `  
       <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 90 443.25"
            width="90"
            height="443.25"
        >
            <g>
                <g>
                    <path
                        class="st0"
                        fill="#606161"
                        x=".5"
                        y="73.3"
                        width="19"
                        height="11.4"
                        d="M2.25 329.85H87.75V381.15H2.25V329.85z"
                    />
                    <path
                        class="st1"
                        fill="#494747"
                        d="M87.75 382.95H2.25c-1.35 0 -2.25 -0.9 -2.25 -2.25V329.4c0 -1.35 0.9 -2.25 2.25 -2.25h85.5c1.35 0 2.25 0.9 2.25 2.25v51.3c0 1.35 -0.9 2.25 -2.25 2.25M4.5 378.45h81.45V331.65H4.5z"
                    />
                </g>
                <g>
                    <g>
                        <path
                            class="st0"
                            fill="#606161"
                            x="4.4"
                            y="84.8"
                            width="11.4"
                            height="1.9"
                            d="M19.8 381.6H71.1V390.15H19.8V381.6z"
                        />
                        <path
                            class="st1"
                            fill="#494747"
                            d="M70.65 391.95H19.35c-1.35 0 -2.25 -0.9 -2.25 -2.25v-8.55c0 -1.35 0.9 -2.25 2.25 -2.25h51.3c1.35 0 2.25 0.9 2.25 2.25v8.55c0 1.35 -0.9 2.25 -2.25 2.25m-49.05 -4.5h47.25v-4.05H21.6z"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                class="st0"
                                fill="#606161"
                                x="5.2"
                                y="86.6"
                                width="9.5"
                                height="1.9"
                                d="M23.4 389.7H66.15V398.25H23.4V389.7z"
                            />
                            <path
                                class="st1"
                                fill="#494747"
                                d="M66.6 400.95H23.4c-1.35 0 -2.25 -0.9 -2.25 -2.25v-8.55c0 -1.35 0.9 -2.25 2.25 -2.25h42.75c1.35 0 2.25 0.9 2.25 2.25v8.55c0 1.35 -0.9 2.25 -2.25 2.25zM25.65 396h38.7v-4.05H25.65z"
                            />
                        </g>
                        <g>
                            <g>
                                <path
                                    class="st0"
                                    fill="#606161"
                                    d="M70.65 398.25v33.3c0 4.95 -4.05 9 -9 9H28.35c-4.95 0 -9 -4.05 -9 -9v-33.3z"
                                />
                                <path
                                    class="st1"
                                    fill="#494747"
                                    d="M62.1 443.25H27.9c-5.85 0 -10.8 -4.95 -10.8 -11.25v-33.3c0 -1.35 0.9 -2.25 2.25 -2.25h51.3c1.35 0 2.25 0.9 2.25 2.25V432c0 6.3 -4.95 11.25 -10.8 11.25m-40.5 -42.3V432c0 3.6 2.7 6.75 6.3 6.75h34.2c3.6 0 6.3 -3.15 6.3 -6.75v-31.05z"
                                />
                            </g>
                            <g>
                                <path
                                    class="st0"
                                    fill="#606161"
                                    d="M36.9 405h16.65c2.7 0 4.5 2.25 4.5 4.5v20.7c0 2.7 -1.8 4.5 -4.5 4.5H36.9c-2.7 0 -4.5 -2.25 -4.5 -4.5V409.5c0 -2.7 1.8 -4.5 4.5 -4.5"
                                />
                                <path
                                    class="st1"
                                    fill="#494747"
                                    d="M53.55 436.95H36.45c-3.6 0 -6.3 -3.15 -6.3 -6.75V409.5c0 -3.6 2.7 -6.75 6.3 -6.75h17.1c3.6 0 6.3 3.15 6.3 6.75v20.7c0 3.6 -2.7 6.75 -6.3 6.75m-17.1 -29.7c-1.35 0 -2.25 0.9 -2.25 2.25v20.7c0 1.35 0.9 2.25 2.25 2.25h17.1c1.35 0 2.25 -0.9 2.25 -2.25V409.5c0 -1.35 -0.9 -2.25 -2.25 -2.25z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                class="st0"
                                fill="#606161"
                                points="14.1 .5 5.7 .5 3.2 8.4 9.9 8.4 16.7 8.4 14.1 .5"
                                d="M63.45 2.25L25.65 2.25L14.4 37.8L44.55 37.8L75.15 37.8L63.45 2.25Z"
                            />
                            <path
                                class="st1"
                                fill="#494747"
                                d="M75.15 40.05H14.4c-0.9 0 -1.35 0 -1.8 -0.9s0 -1.35 0 -2.25L23.85 1.35c0 -0.9 0.9 -1.35 2.25 -1.35h37.35c0.9 0 1.8 0.45 2.25 1.35l11.25 35.1v2.25c0 0.9 -0.9 0.9 -1.8 0.9h-0.45ZM18 35.55h52.65l-9 -30.6H27.45L18 36Z"
                            />
                        </g>
                        <g>
                            <path
                                class="st0"
                                fill="#606161"
                                x="3.3"
                                y="8.4"
                                width="13.3"
                                height="12.9"
                                d="M14.85 37.8H74.7V95.85H14.85V37.8z"
                            />
                            <path
                                class="st1"
                                fill="#494747"
                                d="M75.15 98.1H14.4c-1.35 0 -2.25 -0.9 -2.25 -2.25V37.8c0 -1.35 0.9 -2.25 2.25 -2.25h59.85c1.35 0 2.25 0.9 2.25 2.25v58.05c0 1.35 -0.9 2.25 -2.25 2.25h0.45Zm-58.5 -4.5h55.8V40.05H16.65z"
                            />
                        </g>
                    </g>
                    <g>
                        <path
                            class="st0"
                            fill="#606161"
                            points="17.3 21.3 9.9 21.3 2.5 21.3 1.3 40.1 9.9 40.1 18.6 40.1 17.3 21.3"
                            d="M77.85 95.85L44.55 95.85L11.25 95.85L5.85 180.45L44.55 180.45L83.7 180.45L77.85 95.85Z"
                        />
                        <path
                            class="st1"
                            fill="#494747"
                            d="M83.7 182.7H5.85c-0.45 0 -1.35 0 -1.35 -0.9s-0.45 -0.9 -0.45 -1.8l5.4 -84.6c0 -1.35 0.9 -2.25 2.25 -2.25h66.6c0.9 0 2.25 0.9 2.25 2.25L85.95 180s0 1.35 -0.45 1.8c0 0 -0.9 0.9 -1.35 0.9h-0.9Zm-75.6 -4.5h72.45l-4.95 -80.1H13.05z"
                        />
                    </g>
                </g>
                <g>
                    <path
                        class="st0"
                        fill="#606161"
                        x="1.4"
                        y="40.1"
                        width="17.1"
                        height="41.6"
                        d="M6.3 180.45H83.25V367.65H6.3V180.45z"
                    />
                    <path
                        class="st1"
                        fill="#494747"
                        d="M83.7 369.9H5.85c-1.35 0 -2.25 -0.9 -2.25 -2.25V180.45c0 -1.35 0.9 -2.25 2.25 -2.25h76.95c1.35 0 2.25 0.9 2.25 2.25v187.2c0 1.35 -0.9 2.25 -2.25 2.25h0.45Zm-75.6 -4.5H81V182.7H8.1z"
                    />
                </g>
            </g>
        </svg>
    `;
