import { Unit, Units } from "../workspace/assembly/length/types";

// Do not forget to upgrade this version when changing the terms and conditions
export const TNC_CURRENT_VERSION = "v1";

export const LocalizationKeys = {
    Accept: "accept",
    AddBreakouts: "add-breakouts",
    AddNewBreakout: "add-new-breakout",
    Annotations: "annotations",
    Application: "application",
    Apply: "apply",
    ApplyAllBreakouts: "apply-all-breakouts",
    ApplyToAllBreakouts: "apply-to-all-breakouts",
    ApprovalDate: "approval-date",
    Ascending: "ascending",
    AsImage: "as-image",
    AsPDF: "as-pdf",
    AsCSV: "as-csv",
    AssemblyDrawingNumber: "assembly-drawing-number",
    AssemblyInfo: "assembly-info",
    AssemblyName: "assembly-name",
    AssemblyNewName: "assembly-new-name",
    AssemblySetup: "assembly-setup",
    AssignedFibers: "assigned-fibers",
    AssignmentPatternStandard: "assignment-pattern-standard",
    AssignmentPatternDistributed: "assignment-pattern-distributed",
    Asymmetrical: "asymmetrical",
    AsymmetricalHelp: "asymmetrical-help",
    AutoAdjustFiberCount: "auto-adjust-fiber-count",
    Back: "back",
    BackToWork: "back-to-work",
    Breakout: "breakout",
    BreakoutAttributes: "breakout-attributes",
    BreakoutLabel: "breakout-label",
    Breakouts: "breakouts",
    BreakoutsA: 'breakouts-a',
    BreakoutsAB: "breakouts-ab",
    BreakoutsB: "breakouts-b",
    BreakoutSection: "breakout-section",
    BreakoutsSaved: "breakouts-saved",
    BreakoutsSection: "breakouts-section",
    BreakoutStepHint: "breakout-step-hint",
    BuildPlan: "build-plan",
    BuildPlanHelp: "build-plan-help",
    CableCount: "cable-count",
    CabTitle: "cab-title",
    CableType: "cable-type",
    CableOverview: "cable-overview",
    CalloutDescription: "callout-description",
    Cancel: "cancel",
    Centimeter: "centimeter",
    Clear: "clear",
    ClearAll: "clear-all",
    ClearFiberMapping: "clear-fiber-mapping",
    Close: "close",
    Collapse: "collapse",
    Colon: "colon",
    ColorSelection: "color-selection",
    Configuration: "configuration",
    Connector: "connector",
    ConnectorAssignment: "connector-assignment",
    ConnectorColor: "connector-color",
    ConnectorNumber: "connector-number",
    ConnectorType: "connector-type",
    ContactCorning: "contact-corning",
    Continue: "continue",
    Copyright: "copyright",
    CreateCableAssembly: "create-cable-assembly",
    CustomizeConnectors: "customize-connectors",
    Custom: "custom",
    CustomPolarity: "custom-polarity",
    DefaultCableName: "default-cable-name",
    DefaultTolerances: "default-tolerances",
    DeleteCustomPolarity: "delete-custom-polarity",
    DeleteCustomPolarityMessage: "delete-custom-polarity-message",
    Delete: "delete",
    DeleteCable: "delete-cable",
    Descending: "descending",
    Description: "description",
    Details: "details",
    DrawingDetails: "drawing-details",
    DrawingInformation: "drawing-information",
    DrawingSpecs: "drawing-specs",
    DrawingTitle: "drawing-title",
    DrawnBy: "drawn-by",
    Duplicate: "duplicate",
    DuplicateAssembly: "duplicate-assembly",
    DuplicateCable: "duplicate-cable",
    DuplexBootColor: "duplex-boot-color",
    Edit: "edit",
    EditPolarityName: "edit-polarity-name",
    EditAssembly: "edit-assembly",
    EditAssemblyProperties: "edit-assembly-properties",
    EditBreakoutProperties: "edit-breakout-properties",
    EditCableInformation: "edit-cable-information",
    Email: "email",
    EmptyInputField: "empty-input-field",
    EndDesignation: "end-designation",
    EndLabel: "end-label",
    Environment: "environment",
    Example: "example",
    Expand: "expand",
    Feet: "feet",
    Fiber: "fiber",
    FiberCount: "fiber-count",
    FiberPosition: "fiber-position",
    Finish: "finish",
    First: "first",
    FitToHeight: "fit-to-height",
    FitToWidth: "fit-to-width",
    FlameRating: "flame-rating",
    Footer: "footer",
    FurcationOD: "furcation-od",
    FurcationOuterDiamater: "furcation-outer-diameter",
    General: "general",
    GeneralInformation: "general-information",
    Group: "group",
    GroupOffsetNumberLabel: "group-offset-number-label",
    GroupOffsetLabel: "group-offset-label",
    Header: "header",
    HeaderTitle: "header-title",
    Help: "help",
    HighVisibilityWindow: "high-visibility-window",
    IdledSessionTitle: "idled-session-title",
    IdledSessionMessage: "idled-session-message",
    Imperial: "imperial",
    Inches: "inches",
    Indoor: "indoor",
    IndoorOutdoor: "indoor-outdoor",
    InServiceDate: "in-service-date",
    Instructions: "instructions",
    InvalidEntry: "invalid-entry",
    JacketColor: "jacket-color",
    KeyUp: "key-up",
    Label: "label",
    LabelColor: "label-color",
    LabelTolerance: "label-tolerance",
    LabelText: "label-text",
    Last: "last",
    Layout: "layout",
    LCDuplexBootColor: "lc-duplex-boot-color",
    Leg: "leg",
    Legs: "legs",
    LegsAttributes: "legs-attributes",
    LegsAttributesConfirm: "legs-attributes-confirm",
    LegTable: "leg-table",
    LegTolerance: "leg-tolerance",
    LegPrimeTolerance: "leg-prime-tolerance",
    LengthA0Help: "length-a0-help",
    LengthA1Help: "length-a1-help",
    LengthA1PHelp: "length-a1p-help",
    LengthB0Help: "length-b0-help",
    LengthName: "length-name",
    LengthUnit: "length-unit",
    LengthLabel: "length-label",
    LoadCable: "load-cable",
    Loading: "loading",
    Location: "location",
    Login: "login",
    LoginAgain: "login-again",
    LoginFailed: "login-failed",
    LoginHelp: "login-help",
    LogOut: "log-out",
    Menu: "menu",
    Meter: "meter",
    Metric: "metric",
    Millimeter: "millimeter",
    MinTolerance: "minTolerance",
    MaxTolerance: "maxTolerance",
    MaxNoteNewLines: "max-note-newlines",
    MissingValue: "missing-value",
    Mode: "mode",
    MultiMode: "multi-mode",
    Name: "name",
    NBreakouts: "n-breakouts",
    NBreakoutsHelp: "n-breakouts-help",
    NConnPerGroup: "n-conn-per-group",
    NewAssembly: "new-assembly",
    Next: "next",
    NGroups: "n-groups",
    None: "none",
    Notes: "notes",
    NotesEnter: "notes-enter",
    NotesSaved: "notes-saved",
    NSpareConnectors: "n-spare-connectors",
    NSpareConnectorsHelp: "n-spare-connectors-help",
    Number: "number",
    Off: "off",
    Offsets: "offsets",
    On: "on",
    Outdoor: "outdoor",
    OverallLength: "overall-length",
    OverallLengthHelp: "overall-length-help",
    OverallLengthMarker: "overall-length-marker",
    PageResize: "page-resize",
    Pages: "pages",
    Password: "password",
    PartNumber: "part-number",
    Polarity: "polarity",
    PolarityExists: "polarity-exists",
    PolarityImportantNote: "polarity-important-note",
    PolarityInstructions: "polarity-instructions",
    PolarityName: "polarity-name",
    PolarityNoDiagram: "polarity-no-diagram",
    PolarityProcess: "polarity-process",
    PolarityReference: "polarity-reference",
    PolarityReport: "polarity-report",
    PolaritySchemes: "polarity-schemes",
    PolarityType: "polarity-type",
    PolarityUnavailable: "polarity-unavailable",
    PositionColor: "position-color",
    Previous: "previous",
    PrintDialogNote: "print-dialog-note",
    Printing: "printing",
    PrintAReport: "print-a-report",
    PrintReport: "print-report",
    PrintSettings: "print-settings",
    Propagation: "propagation",
    PropagateAttributes: "propagate-attributes",
    PropagateEndToEnd: "propagate-end-to-end",
    PropagationSuccessful: "propagation-successful",
    PropagationFailed: "propagation-failed",
    PullingGrip: "pulling-grip",
    PullingGripHelp: "pulling-grip-help",
    Qty: "qty",
    Quantity: "quantity",
    Report: "report",
    ReportDefaultFileName: "report-default-filename",
    ReportCustomTolerance: "report-custom-tolerances",
    ReportFooterTitle: "report-footer-title",
    ReportLabelTolerance: "report-label-tolerance",
    ReportLegsTolerance: "report-legs-tolerance",
    ReportSubunitsTolerance: "report-subunits-tolerance",
    ReportTitle: "report-title",
    ReportToleranceNote: "report-tolerance-note",
    ReportTolerancesValue: "report-tolerances-value",
    ReportUserProvidedImagesNote: "report-user-provided-images-note",
    Reset: "reset",
    ResetAll: "reset-all",
    ResetAllBreakoutDialogBody: "reset-all-breakout-dialog-body",
    ResetBreakoutsDialogBody: "reset-breakouts-dialog-body",
    ResetConnectorAssignment: "reset-connector-assignment",
    Revision: "revision",
    Save: "save",
    SaveAs: "save-as",
    Saved: "saved",
    SaveNewVersion: "save-new-version",
    Saving: "saving",
    SCDuplexBootColor: "sc-duplex-boot-color",
    SelectAllConnectors: "select-all-connectors",
    SelectPolarity: "select-polarity",
    Settings: "settings",
    Shape: "shape",
    ShowCableBaseReference: "show-cable-base-references",
    ShowHideAnnotation: "show-hide-annotation",
    ShowHideDrawingSpecs: "show-hide-drawing-specs",
    ShowPullingGrip: "show-pulling-grip",
    ShowTolerance: "show-tolerance",
    SideA: "sidea",
    SideAB: "sideab",
    SideB: "sideb",
    SideJacketColor: "side-jacket-color",
    SideView: "side-view",
    SingleMode: "single-mode",
    SortBy: "sort-by",
    StaggerNumberLabel: "stagger-number-label",
    StaggerLabel: "stagger-label",
    ShowLabels: "show-labels",
    StaggerHelp: "stagger-help",
    StepNumber: "step-number",
    Summary: "summary",
    Symmetrical: "symmetrical",
    SymmetricalHelp: "symmetrical-help",
    Synchronizing: "synchronizing",
    Table: "table",
    TactilePattern: "tactile-pattern",
    Target: "target",
    TermsAndConditionsAccepted: "terms-and-conditions-accepted",
    TermsAndConditionsTitle: "terms-and-conditions-title",
    TextFieldTemplate: "textfield-template",
    TimeOutMessage: "time-out-message",
    TimeOutTitle: "time-out-title",
    TotalLength: "total-length",
    Tolerance: "tolerance",
    Tolerances: "tolerances",
    TriggerHousingColor: "trigger-housing-color",
    TypeToType: "type-to-type",
    Undo: "undo",
    Uniform: "uniform",
    Unit: "unit",
    Units: "units",
    UnitsSetup: "units-setup",
    UnitsSetupHint: "units-setup-hint",
    UOM: "uom",
    UploadImage: "upload-image",
    UserMenu: "user-menu",
    ValueGreater: "value-greater",
    ValueLess: "value-less",
    WelcomeBack: "welcome-back",
    ZoomIn: "zoom-in",
    ZoomOut: "zoom-out",
} as const;

export type Localization = typeof LocalizationKeys[keyof typeof LocalizationKeys]


export function localizeUnit(unit: Unit) {
    switch (unit) {
        case Units.Millimeter:
            return LocalizationKeys.Millimeter
        case Units.Centimeter:
            return LocalizationKeys.Centimeter
        case Units.Meter:
            return LocalizationKeys.Meter
        case Units.Inches:
            return LocalizationKeys.Inches
        case Units.Feet:
            return LocalizationKeys.Feet
        default:
            return '';
    }
}

export function capitalize(value: string) {
    const toLowerCase = value.toLowerCase();
    return `${toLowerCase[0].toUpperCase()}${toLowerCase.slice(1)}`;
};