import React from "react";

export const PUSHLOKSF: React.FC = () => {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 90 694.286"
            width="90"
            height="694.286"
        >
            <g>
                <path
                    className="st0"
                    fill="#231f20"
                    points="11.7 28.3 9.8 26.3 9.8 .5 4.2 .5 4.2 26.3 2.3 28.3 2.3 50.1 11.7 50.1 11.7 28.3"
                    d="M75.214 181.929L63 169.071L63 3.214L27 3.214L27 169.071L14.786 181.929L14.786 322.071L75.214 322.071L75.214 181.929Z"
                />
                <path
                    className="st1"
                    fill="#494747"
                    d="M12.214 325.286V180.643l12.214 -12.857V0h41.786v167.786l12.214 12.857v144.643zm5.786 -142.071v135.643h54V183.214l-12.214 -12.857V6.429H29.571v163.929l-12.214 12.857Z"
                />
            </g>
            <g>
                <path
                    className="st0"
                    fill="#231f20"
                    d="M86.786 322.071H2.571c0 313.071 2.571 363.857 38.571 369h7.071c36.643 -5.143 38.571 -55.929 38.571 -369m-44.357 356.143c-9 -1.929 -14.786 -11.571 -18.643 -36 -0.643 -3.857 1.929 -7.714 5.786 -7.714h30.857c3.857 0 6.429 3.857 5.786 7.714 -3.857 24.429 -10.286 34.071 -18.643 36z"
                />
                <path
                    className="st1"
                    fill="#494747"
                    d="M0 322.071v-3.214h90v3.214c0 320.786 -2.571 367.071 -41.143 372.214H41.786C2.571 689.143 0 642.214 0 322.071m84.214 3.214H6.429c0 308.571 2.571 358.071 36 363.214h6.429c32.786 -4.5 35.357 -54.643 35.357 -363.214M20.571 641.571c0 -2.571 0.643 -4.5 1.929 -6.429 1.929 -1.929 4.5 -3.214 7.071 -3.214h30.857c2.571 0 5.143 1.286 7.071 3.214s2.571 5.143 1.929 7.714c-3.857 25.071 -10.286 36 -21.214 38.571h-5.786c-10.929 -1.929 -17.357 -13.5 -21.214 -38.571v-1.929h-0.643Zm43.071 0v-1.929c0 -0.643 -1.286 -1.286 -2.571 -1.286H30.214c-1.286 0 -1.929 0 -2.571 1.286 0 0 -0.643 1.929 -0.643 2.571 4.5 27 10.929 32.143 16.071 33.429h5.143c4.5 0 11.571 -5.786 16.071 -33.429Z"
                />
            </g>
        </svg>
    );
};

export const getPUSHLOKSFSVGString = () =>
    `<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 90 703.929" width="90" height="703.929">
   <g>
      <path class="st0" fill="#231f20" points="11.6 28.3 9.8 26.3 9.8 .5 4.1 .5 4.1 26.3 2.3 28.3 2.3 51.5 11.6 51.5 11.6 28.3" d="M74.571 181.929L63 169.071L63 3.214L26.357 3.214L26.357 169.071L14.786 181.929L14.786 331.071L74.571 331.071L74.571 181.929Z"/>
      <path class="st1" fill="#494747" d="M12.214 334.286V180.643l12.214 -12.857V0h41.786v167.786l12.214 12.857V334.286zm5.786 -151.071V327.857h54V183.214l-12.214 -12.857V6.429H29.571v163.929l-12.214 12.857Z"/>
   </g>
   <g>
      <path class="st0" fill="#231f20" d="M86.786 331.714H2.571c0 313.071 2.571 363.857 38.571 369h7.071c36.643 -5.143 38.571 -55.929 38.571 -369M42.429 687.857c-9 -1.929 -14.786 -11.571 -18.643 -36 0 -3.857 1.929 -7.714 5.786 -7.714h30.857c3.857 0 6.429 3.857 5.786 7.714 -3.857 24.429 -10.286 34.071 -18.643 36z"/>
      <path class="st1" fill="#494747" d="M0 331.714v-3.214h90v3.214c0 320.786 -2.571 367.071 -41.143 372.214H41.786C2.571 698.786 0 651.857 0 331.714m84.214 3.214H6.429c0 308.571 2.571 358.071 36 363.214h6.429c32.786 -4.5 35.357 -54.643 35.357 -363.214m-61.714 309.857c1.929 -1.929 4.5 -3.214 7.071 -3.214h30.857c2.571 0 5.143 1.286 7.071 3.214s2.571 5.143 1.929 7.714c-3.857 25.071 -10.286 36 -21.214 38.571h-5.786c-10.929 -1.929 -17.357 -13.5 -21.214 -38.571v-1.929s0 -3.857 1.286 -5.786m41.143 4.5c0 -0.643 -1.286 -1.286 -2.571 -1.286H30.214c-1.286 0 -1.929 0 -2.571 1.286v2.571c4.5 27 10.929 32.143 16.071 33.429h5.143c4.5 0 11.571 -5.786 16.071 -33.429v-2.571Z"/>
   </g>
</svg>
 `;
