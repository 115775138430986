import React from "react";

export const PUSHLOKMF: React.FC = () => {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 90 948.75"
            width="90"
            height="948.75"
        >
            <g>
                <path
                    className="st0"
                    fill="#606161"
                    d="M48 945c35.25 -5.25 27 -76.5 37.5 -380.25H3.75c10.5 303.75 2.25 375 37.5 380.25zm-17.25 -27 -1.5 -20.25h31.5l-1.5 20.25c0 8.25 -6.75 14.25 -14.25 14.25s-13.5 -6 -14.25 -14.25"
                />
                <g>
                    <path
                        className="st3"
                        fill="#494747v"
                        d="M63 894.75c0 -0.75 -1.5 -1.5 -2.25 -1.5H29.25c-0.75 0 -1.5 0 -2.25 1.5 0 0.75 -0.75 2.25 -0.75 3l1.5 20.25c0 10.5 8.25 18 17.25 18s17.25 -7.5 17.25 -18l1.5 -20.25s0 -2.25 -0.75 -3m-7.5 22.5c0 6 -5.25 11.25 -10.5 11.25s-10.5 -5.25 -10.5 -11.25l-1.5 -15.75h24z"
                    />
                    <path
                        className="st3"
                        fill="#494747v"
                        d="M85.5 560.25H3.75l-2.25 1.5c-0.75 0.75 -0.75 2.25 -0.75 3 2.25 65.25 3.75 127.5 4.5 171.75 4.5 166.5 5.25 207 35.25 212.25h8.25c30 -5.25 30.75 -45.75 35.25 -212.25 0 -44.25 2.25 -106.5 4.5 -171.75v-3c-0.75 -1.5 -1.5 -1.5 -2.25 -1.5Zm-7.5 176.25c-3.75 159 -5.25 201 -30 204.75h-6c-24.75 -3 -26.25 -45.75 -30 -204.75 0 -42.75 -2.25 -105 -4.5 -168h75c-2.25 63 -3.75 125.25 -4.5 168"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        className="st1"
                        fill="#231f20"
                        d="M67.5 4.5H22.5C16.5 90 9.75 261 3.75 564.75h81.75C79.5 261.75 72.75 90 66.75 4.5z"
                    />
                    <path
                        className="st3"
                        fill="#494747v"
                        d="M90 568.5H0v-4.5C5.25 303 11.25 109.5 18.75 3.75V0h52.5v3.75c7.5 105 13.5 298.5 18.75 560.25zM8.25 560.25H82.5c-5.25 -255.75 -11.25 -446.25 -18 -552H26.25c-6.75 105 -12.75 295.5 -18 552"
                    />
                </g>
                <g>
                    <g>
                        <path
                            className="st2"
                            fill="#606060"
                            d="M86.25 564H3.75c0.75 -39.75 1.5 -76.5 2.25 -112.5h77.25c0.75 35.25 1.5 72 2.25 112.5Z"
                        />
                        <path
                            className="st3"
                            fill="#494747v"
                            d="M89.25 567.75H0v-22.5c1.5 -32.25 1.5 -63.75 2.25 -93v-3.75h84v3.75c0.75 35.25 1.5 72 2.25 112.5v3.75ZM7.5 560.25h75c0 -36.75 -1.5 -71.25 -2.25 -105H9.75c0 28.5 -1.5 57.75 -2.25 89.25v15Z"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                className="st2"
                                fill="#606060"
                                d="M72.75 477.75v60.75c0 3.75 -3 6.75 -6.75 6.75s-6.75 -3 -6.75 -6.75v-60.75c0 -3.75 3 -6.75 6.75 -6.75s6.75 3 6.75 6.75"
                            />
                            <path
                                className="st3"
                                fill="#494747v"
                                d="M65.25 548.25c-5.25 0 -9.75 -4.5 -9.75 -10.5v-59.25c0 -6 4.5 -10.5 9.75 -10.5s9.75 4.5 9.75 10.5v59.25c0 6 -4.5 10.5 -9.75 10.5m0 -75c-2.25 0 -3.75 2.25 -3.75 4.5v59.25c0 2.25 1.5 4.5 3.75 4.5s3.75 -2.25 3.75 -4.5v-59.25c0 -2.25 -1.5 -4.5 -3.75 -4.5"
                            />
                        </g>
                        <g>
                            <path
                                className="st2"
                                fill="#606060"
                                d="M31.5 477.75v60.75c0 3.75 -3 6.75 -6.75 6.75s-6.75 -3 -6.75 -6.75v-60.75c0 -3.75 3 -6.75 6.75 -6.75s6.75 3 6.75 6.75"
                            />
                            <path
                                className="st3"
                                fill="#494747v"
                                d="M24.75 548.25c-5.25 0 -9.75 -4.5 -9.75 -10.5v-59.25c0 -6 4.5 -10.5 9.75 -10.5s9.75 4.5 9.75 10.5v59.25c0 6 -4.5 10.5 -9.75 10.5m0 -75c-2.25 0 -3.75 2.25 -3.75 4.5v59.25c0 2.25 1.5 4.5 3.75 4.5s3.75 -2.25 3.75 -4.5v-59.25c0 -2.25 -1.5 -4.5 -3.75 -4.5"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const getPUSHLOKMFSVGString = () =>
    `   <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 90 948.75"
            width="90"
            height="948.75"
        >
            <g>
                <path
                    class="st0"
                    fill="#606161"
                    d="M48 945c35.25 -5.25 27 -76.5 37.5 -380.25H3.75c10.5 303.75 2.25 375 37.5 380.25zm-17.25 -27 -1.5 -20.25h31.5l-1.5 20.25c0 8.25 -6.75 14.25 -14.25 14.25s-13.5 -6 -14.25 -14.25"
                />
                <g>
                    <path
                        class="st3"
                        fill="#494747v"
                        d="M63 894.75c0 -0.75 -1.5 -1.5 -2.25 -1.5H29.25c-0.75 0 -1.5 0 -2.25 1.5 0 0.75 -0.75 2.25 -0.75 3l1.5 20.25c0 10.5 8.25 18 17.25 18s17.25 -7.5 17.25 -18l1.5 -20.25s0 -2.25 -0.75 -3m-7.5 22.5c0 6 -5.25 11.25 -10.5 11.25s-10.5 -5.25 -10.5 -11.25l-1.5 -15.75h24z"
                    />
                    <path
                        class="st3"
                        fill="#494747v"
                        d="M85.5 560.25H3.75l-2.25 1.5c-0.75 0.75 -0.75 2.25 -0.75 3 2.25 65.25 3.75 127.5 4.5 171.75 4.5 166.5 5.25 207 35.25 212.25h8.25c30 -5.25 30.75 -45.75 35.25 -212.25 0 -44.25 2.25 -106.5 4.5 -171.75v-3c-0.75 -1.5 -1.5 -1.5 -2.25 -1.5Zm-7.5 176.25c-3.75 159 -5.25 201 -30 204.75h-6c-24.75 -3 -26.25 -45.75 -30 -204.75 0 -42.75 -2.25 -105 -4.5 -168h75c-2.25 63 -3.75 125.25 -4.5 168"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        class="st1"
                        fill="#231f20"
                        d="M67.5 4.5H22.5C16.5 90 9.75 261 3.75 564.75h81.75C79.5 261.75 72.75 90 66.75 4.5z"
                    />
                    <path
                        class="st3"
                        fill="#494747v"
                        d="M90 568.5H0v-4.5C5.25 303 11.25 109.5 18.75 3.75V0h52.5v3.75c7.5 105 13.5 298.5 18.75 560.25zM8.25 560.25H82.5c-5.25 -255.75 -11.25 -446.25 -18 -552H26.25c-6.75 105 -12.75 295.5 -18 552"
                    />
                </g>
                <g>
                    <g>
                        <path
                            class="st2"
                            fill="#606060"
                            d="M86.25 564H3.75c0.75 -39.75 1.5 -76.5 2.25 -112.5h77.25c0.75 35.25 1.5 72 2.25 112.5Z"
                        />
                        <path
                            class="st3"
                            fill="#494747v"
                            d="M89.25 567.75H0v-22.5c1.5 -32.25 1.5 -63.75 2.25 -93v-3.75h84v3.75c0.75 35.25 1.5 72 2.25 112.5v3.75ZM7.5 560.25h75c0 -36.75 -1.5 -71.25 -2.25 -105H9.75c0 28.5 -1.5 57.75 -2.25 89.25v15Z"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                class="st2"
                                fill="#606060"
                                d="M72.75 477.75v60.75c0 3.75 -3 6.75 -6.75 6.75s-6.75 -3 -6.75 -6.75v-60.75c0 -3.75 3 -6.75 6.75 -6.75s6.75 3 6.75 6.75"
                            />
                            <path
                                class="st3"
                                fill="#494747v"
                                d="M65.25 548.25c-5.25 0 -9.75 -4.5 -9.75 -10.5v-59.25c0 -6 4.5 -10.5 9.75 -10.5s9.75 4.5 9.75 10.5v59.25c0 6 -4.5 10.5 -9.75 10.5m0 -75c-2.25 0 -3.75 2.25 -3.75 4.5v59.25c0 2.25 1.5 4.5 3.75 4.5s3.75 -2.25 3.75 -4.5v-59.25c0 -2.25 -1.5 -4.5 -3.75 -4.5"
                            />
                        </g>
                        <g>
                            <path
                                class="st2"
                                fill="#606060"
                                d="M31.5 477.75v60.75c0 3.75 -3 6.75 -6.75 6.75s-6.75 -3 -6.75 -6.75v-60.75c0 -3.75 3 -6.75 6.75 -6.75s6.75 3 6.75 6.75"
                            />
                            <path
                                class="st3"
                                fill="#494747v"
                                d="M24.75 548.25c-5.25 0 -9.75 -4.5 -9.75 -10.5v-59.25c0 -6 4.5 -10.5 9.75 -10.5s9.75 4.5 9.75 10.5v59.25c0 6 -4.5 10.5 -9.75 10.5m0 -75c-2.25 0 -3.75 2.25 -3.75 4.5v59.25c0 2.25 1.5 4.5 3.75 4.5s3.75 -2.25 3.75 -4.5v-59.25c0 -2.25 -1.5 -4.5 -3.75 -4.5"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    `;
