import { useSelector } from "react-redux";
import { canToggleDrawingSpecsSelector, showSettingsSelector } from "../store/selectors";
import { ChangeEvent, useCallback } from "react";
import { setStatus, showSettings } from "../store/reducer";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../localization/types";
import { PolaritySortType, PolaritySortTypes } from "../assembly/settings/types";
import { currentAssemblyInfoSelector, currentAssemblySelector, currentPullingGripSelector, showCableBaseReferencesSelector, showPullingGripSelector, showToleranceMarkerSelector, showLabelsSelector } from "../assembly/store/selectors";
import { updateAssemblyInfo } from "../assembly/store/reducer";
import { Status } from "../store/types";
import { useStoreDispatch } from "../../store/hooks";
import { DialogProps } from "@orbit/dialog";
import { CheckboxProps } from "@orbit/checkbox";
import { MainPalettes } from "@orbit/theme-provider";

export const useSettingsDialog = () => {
    const open = useSelector(showSettingsSelector);
    const { id: assemblyId } = useSelector(currentAssemblySelector);
    const info = useSelector(currentAssemblyInfoSelector);
    const cableBaseReferencesChecked = useSelector(showCableBaseReferencesSelector);
    const showLabels = useSelector(showLabelsSelector);
    const toleranceMarkerChecked = useSelector(showToleranceMarkerSelector);
    const showPullingGripChecked = useSelector(showPullingGripSelector);
    const polaritySortType = info?.polaritySortType ?? PolaritySortTypes.EndA;
    const canToggle = useSelector(canToggleDrawingSpecsSelector);
    const pullingGrip = useSelector(currentPullingGripSelector);
    const dispatch = useStoreDispatch();
    const { t } = useTranslation();

    const onClose = useCallback(() => {
        dispatch(showSettings(false));
    }, [dispatch]);

    const dialogProps: DialogProps = {
        open,
        className: "settings-dialog",
        title: t(LocalizationKeys.Settings),
        modal: false,
        onClose
    };
    const onCableBaseCheckboxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setStatus(Status.Synchronizing));
        dispatch(updateAssemblyInfo({ ...info, assemblyId, showA0B0: !cableBaseReferencesChecked}));
    }, [dispatch, cableBaseReferencesChecked, info, assemblyId]);

    const cableBaseCheckboxProps: CheckboxProps = {
        palette: MainPalettes.primary,
        placement: "end",
        label: t(LocalizationKeys.ShowCableBaseReference),
        checked: cableBaseReferencesChecked,
        disabled: !canToggle,
        onChange: onCableBaseCheckboxChanged
    };
    const onShowLabelCheckboxChanged = useCallback(() => {
        dispatch(setStatus(Status.Synchronizing));
        dispatch(updateAssemblyInfo({ ...info, assemblyId, showLabels: !showLabels}))
    }, [dispatch, showLabels, info, assemblyId])

    const showLabelsProps: CheckboxProps = {
        palette: MainPalettes.primary,
        placement: "end",
        label: t(LocalizationKeys.ShowLabels),
        checked: showLabels,
        onChange: onShowLabelCheckboxChanged,
    }

    const onToleranceCheckboxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setStatus(Status.Synchronizing));
        dispatch(updateAssemblyInfo({ ...info, assemblyId, showLabelDistance: !toleranceMarkerChecked}));
    }, [assemblyId, dispatch, info, toleranceMarkerChecked]);

    const toleranceCheckboxProps: CheckboxProps = {
        palette: MainPalettes.primary,
        placement: "end",
        label: t(LocalizationKeys.ShowTolerance),
        checked: toleranceMarkerChecked,
        disabled: !canToggle,
        onChange: onToleranceCheckboxChanged
    };

    const onPullingGripCheckboxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setStatus(Status.Synchronizing));
        dispatch(updateAssemblyInfo({ ...info, assemblyId, showPullingGrip: !showPullingGripChecked}));
    }, [assemblyId, dispatch, info, showPullingGripChecked]);

    const pullingGripCheckboxProps: CheckboxProps = {
        palette: MainPalettes.primary,
        placement: "end",
        label: t(LocalizationKeys.ShowPullingGrip),
        checked: pullingGrip !== "None" && showPullingGripChecked,
        disabled: pullingGrip === "None",
        onChange: onPullingGripCheckboxChanged
    };

    const onPolaritySortTypeChange = useCallback((e) => {
        dispatch(setStatus(Status.Synchronizing));
        const polaritySortType = e.currentTarget.value as PolaritySortType;
        dispatch(updateAssemblyInfo({ ...info, assemblyId, polaritySortType }));
    }, [info, dispatch, assemblyId]);

    const endALabel = t(LocalizationKeys.EndLabel, { side: "A" });
    const endBLabel = t(LocalizationKeys.EndLabel, { side: "B" });
    const polaritySortTypeProps = {
        label: t(LocalizationKeys.PolarityReport),
        value: polaritySortType,
        options: [
            { 
                className: polaritySortType === endALabel ? "selected" : "",
                label: endALabel,
                radioButtonProps: { value: endALabel, onChange: onPolaritySortTypeChange }
            },
            {
                className: polaritySortType === endBLabel ? "selected" : "",
                label: endBLabel,
                radioButtonProps: { value: endBLabel, onChange: onPolaritySortTypeChange }
            }
        ]
    };

    return { 
        dialogProps,
        cableBaseCheckboxProps,
        toleranceCheckboxProps,
        pullingGripCheckboxProps,
        polaritySortType: polaritySortTypeProps,
        showLabelsProps
     };
}