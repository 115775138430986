export const MTP = "12 F, MTP"; // default mtp
export const MTP8F_NP = "8 F, MTP, non-pinned";
export const MTP8F_P = "8 F, MTP, pinned";
export const MTP12F_NP = "12 F, MTP, non-pinned";
export const MTP12F_P = "12 F, MTP, pinned";
export const MTP12F_FP_NP = "12 F, MTP Ferrule Push, non-pinned";
export const MTP12F_FP_P = "12 F, MTP Ferrule Push, pinned";
export const MTP24F_NP = "24 F, MTP, non-pinned";
export const MTP24F_P = "24 F, MTP, pinned";
export const MTP24F_FP_NP = "24 F, MTP Ferrule Push, non-pinned";
export const MTP24F_FP_P = "24 F, MTP Ferrule Push, pinned";
export const LC = "2 F, LC Uniboot";
export const LC_APC_UNIBOOT = "2 F, LC APC Uniboot";
export const LC_APC = "2 F, LC APC duplex";
export const LC_UPC = "2 F, LC UPC duplex";
export const SC_APC = "2 F, SC APC duplex";
export const SC_UPC = "2 F, SC UPC duplex";
export const MDC = "2 F, MDC";
export const SN = "2 F, SN";
export const LSH = "1 F, LSH";
export const LC_SIMPLEX = "1 F, LC simplex";
export const LC_APC_SIMPLEX = "1 F, LC APC simplex";
export const SC_SIMPLEX = "1 F, SC simplex";
export const SC_APC_SIMPLEX = "1 F, SC APC simplex";
export const SC_UPC_SIMPLEX = "1 F, SC UPC simplex";
export const PIGTAIL_LEG = "Pigtail, With Legs";
export const PIGTAIL_NO_LEG = "Pigtail, No Leg";
export const MMC16F_NP = "16 F, MMC, non-pinned";
export const MMC16F_P = "16 F, MMC, pinned";
export const MMC24F_NP = "24 F, MMC, non-pinned";
export const MMC24F_P = "24 F, MMC, pinned";
// Outdoor connectors
export const OPTITAP1F_FEMALE = "1 F, OPTITAP Female";
export const OPTITAP1F_MALE = "1 F, OPTITAP Male";
export const OPTITIP4F = "4 F, OPTITIP";
export const OPTITIP8F = "8 F, OPTITIP";
export const OPTITIP12F = "12 F, OPTITIP";
export const PUSHLOK1F_SF = "1 F, Pushlok SF";
export const PUSHLOK4F_MF = "4 F, Pushlok MF";
export const PUSHLOK8F_MF = "8 F, Pushlok MF";
export const PUSHLOK12F_MF = "12 F, Pushlok MF";
export const PUSHLOK24F_MF = "24 F, Pushlok MF";
export const ECAM1F = "1 F, ECAM";

export const ConnectorNames = {
  PIGTAIL_LEG, // Pigtail, With Leg
  PIGTAIL_NO_LEG, // Pigtail, No Leg
  MTP, // Default MTP that can be used as a placeholder (12F MTP)
  MTP8F_NP, // 8F non-pinned MTP connector
  MTP8F_P, // 8F pinned MTP connector
  MTP12F_NP, // 12F non-pinned MTP connector
  MTP12F_P, // 12F pinned MTP connector
  MTP12F_FP_NP, // 12F non-pinned ferrule push MTP connector
  MTP12F_FP_P, // 12F pinned ferrule push MTP connector
  MTP24F_NP, // 24F non-pinned MTP connector
  MTP24F_P, // 24F pinned MTP connector
  MTP24F_FP_NP, // 24F non-pinned ferrule push MTP connector
  MTP24F_FP_P, // 24F pinned ferrule push MTP connector
  LC, // 2F LC uniboot connector
  LC_APC_UNIBOOT, // 2F LC APC uniboot connector
  LC_APC, // 2F LC APC duplex connector
  LC_UPC, // 2F LC UPC duplex connector
  SC_APC, // 2F SC APC duplex connector
  SC_UPC, // 2F SC UPC duplex connector
  MDC, // 2F MDC connector
  SN, // 2F Senko Nano connector
  LSH, // 1F Laser Shock Hardering connector
  LC_SIMPLEX, // 1F LC simplex connector
  LC_APC_SIMPLEX, // 1F LC APC simplex connector
  SC_SIMPLEX, // 1F SC simplex connector
  SC_APC_SIMPLEX, // 1F SC APC simplex connector
  SC_UPC_SIMPLEX, // 1F SC UPC simplex connector
  MMC16F_NP, // 16F MMC Non-pinned
  MMC16F_P, // 16F MMC Pinned
  MMC24F_NP, // 24F MMC Non-pinned
  MMC24F_P, // 24F MMC Pinned
  OPTITAP1F_FEMALE, // 1F, OPTITAP FEMALE
  OPTITAP1F_MALE, // 1F, OPTITAP MALE
  OPTITIP4F, // 4F, OPTITIP
  OPTITIP8F, // 8F, OPTITIP
  OPTITIP12F, // 12F, OPTITIP
  PUSHLOK1F_SF, // 1F, Pushlok SF
  PUSHLOK4F_MF, // 4F, Pushlok MF
  PUSHLOK8F_MF, // 8F, Pushlok MF
  PUSHLOK12F_MF, // 12F, Pushlok MF
  PUSHLOK24F_MF, // 24F, Pushlok MF
  ECAM1F, // 1F, ECAM
} as const;

export type ConnectorName =
  (typeof ConnectorNames)[keyof typeof ConnectorNames];

export interface IConnectorType {
  id?: number;
  key: string;
  description: string;
  name: string;
  type: ConnectorName;
  fiberCount: number;
  smCode?: string;
  mmCode?: string;
  available?: {
    outdoor?: boolean;
    singleMode?: boolean;
    onlyEMEAUser?: boolean;
  };
}

// Originally we were going to use the part # as the key but some connectors do not have a part #, assigning incrementing key values
export const ConnLCSimplex: IConnectorType = {
  key: "1",
  description: LC_SIMPLEX,
  type: LC_SIMPLEX,
  fiberCount: 1,
  name: "LC Simplex",
};
export const ConnLCAPCSimplex: IConnectorType = {
  key: "2",
  description: LC_APC_SIMPLEX,
  type: LC_APC_SIMPLEX,
  fiberCount: 1,
  name: "LC APC Simplex",
};
export const ConnSCSimplex: IConnectorType = {
  key: "3",
  description: SC_SIMPLEX,
  type: SC_SIMPLEX,
  fiberCount: 1,
  name: "SC Simplex",
};
export const ConnSCAPCSimplex: IConnectorType = {
  key: "4",
  description: SC_APC_SIMPLEX,
  type: SC_APC_SIMPLEX,
  fiberCount: 1,
  name: "SC APC Simplex",
};
export const ConnSCUPCSimplex: IConnectorType = {
  key: "5",
  description: SC_UPC_SIMPLEX,
  type: SC_UPC_SIMPLEX,
  fiberCount: 1,
  name: "SC UPC Simplex",
};
export const ConnLSH: IConnectorType = {
  key: "6",
  description: LSH,
  type: LSH,
  fiberCount: 1,
  name: "LSH",
  available: {
    onlyEMEAUser: true,
  },
};
export const ConnLC: IConnectorType = {
  key: "7",
  description: LC,
  type: LC,
  fiberCount: 2,
  smCode: "78",
  mmCode: "79",
  name: "LC",
};
export const ConnLCAPC: IConnectorType = {
  key: "8",
  description: LC_APC_UNIBOOT,
  type: LC_APC_UNIBOOT,
  fiberCount: 2,
  smCode: "78",
  mmCode: "79",
  name: "LC APC Uniboot",
};
export const ConnLCAPCDuplex: IConnectorType = {
  key: "9",
  description: LC_APC,
  type: LC_APC,
  fiberCount: 2,
  name: "LC APC Duplex",
};
export const ConnLCDuplex: IConnectorType = {
  key: "10",
  description: LC_UPC,
  type: LC_UPC,
  fiberCount: 2,
  name: "LC UPC Duplex",
};
export const ConnSCAPCDuplex: IConnectorType = {
  key: "11",
  description: SC_APC,
  type: SC_APC,
  fiberCount: 2,
  name: "SC APC Duplex",
};
export const ConnSCDuplex: IConnectorType = {
  key: "12",
  description: SC_UPC,
  type: SC_UPC,
  fiberCount: 2,
  name: "SC UPC Duplex",
};
export const ConnMDC: IConnectorType = {
  key: "13",
  description: MDC,
  type: MDC,
  fiberCount: 2,
  name: "MDC",
};
export const ConnSN: IConnectorType = {
  key: "14",
  description: SN,
  type: SN,
  fiberCount: 2,
  name: "SN",
};
export const ConnMTP8_NP: IConnectorType = {
  key: "15",
  description: MTP8F_NP,
  type: MTP8F_NP,
  fiberCount: 8,
  smCode: "E8",
  name: "MTP 8",
};
export const ConnMTP8_P: IConnectorType = {
  key: "16",
  description: MTP8F_P,
  type: MTP8F_P,
  fiberCount: 8,
  smCode: "E7",
  name: "MTP 8",
};
export const ConnMTP12_NP: IConnectorType = {
  key: "17",
  description: MTP12F_NP,
  type: MTP12F_NP,
  fiberCount: 12,
  smCode: "90",
  mmCode: "75",
  name: "MPT 12",
};
export const ConnMTP12_P: IConnectorType = {
  key: "18",
  description: MTP12F_P,
  type: MTP12F_P,
  fiberCount: 12,
  smCode: "89",
  mmCode: "93",
  name: "MTP 12",
};
export const ConnMTP12_FP_NP: IConnectorType = {
  key: "19",
  description: MTP12F_FP_NP,
  type: MTP12F_FP_NP,
  fiberCount: 12,
  smCode: "F4",
  name: "MTP 12",
};
export const ConnMTP12_FP_P: IConnectorType = {
  key: "20",
  description: MTP12F_FP_P,
  type: MTP12F_FP_P,
  fiberCount: 12,
  smCode: "F5",
  name: "MTP 12",
};
export const ConnMTP24_NP: IConnectorType = {
  key: "21",
  description: MTP24F_NP,
  type: MTP24F_NP,
  fiberCount: 24,
  smCode: "A9",
  mmCode: "A6",
  name: "MTP 24",
};
export const ConnMTP24_P: IConnectorType = {
  key: "22",
  description: MTP24F_P,
  type: MTP24F_P,
  fiberCount: 24,
  name: "MTP 24",
};
export const ConnMTP24_FP_NP: IConnectorType = {
  key: "23",
  description: MTP24F_FP_NP,
  type: MTP24F_FP_NP,
  fiberCount: 24,
  smCode: "F6",
  name: "MTP 24",
};
export const ConnMTP24_FP_P: IConnectorType = {
  key: "24",
  description: MTP24F_FP_P,
  type: MTP24F_FP_P,
  fiberCount: 24,
  smCode: "F7",
  name: "MTP 24",
};
export const ConnPigtail_No_Leg: IConnectorType = {
  key: "25",
  description: PIGTAIL_NO_LEG,
  type: PIGTAIL_NO_LEG,
  fiberCount: 1,
  name: "Pigtail Leg",
};
export const ConnPigtail_With_Leg: IConnectorType = {
  key: "26",
  description: PIGTAIL_LEG,
  type: PIGTAIL_LEG,
  fiberCount: 1,
  name: "Pigtail Leg",
};
export const ConnMMC16_NP: IConnectorType = {
  key: "27",
  description: MMC16F_NP,
  type: MMC16F_NP,
  fiberCount: 16,
  name: "MMC 16",
};
export const ConnMMC16_P: IConnectorType = {
  key: "28",
  description: MMC16F_P,
  type: MMC16F_P,
  fiberCount: 16,
  name: "MMC 16",
};
export const ConnMMC24_NP: IConnectorType = {
  key: "29",
  description: MMC24F_NP,
  type: MMC24F_NP,
  fiberCount: 24,
  name: "MMC 24",
};
export const ConnMMC24_P: IConnectorType = {
  key: "30",
  description: MMC24F_P,
  type: MMC24F_P,
  fiberCount: 24,
  name: "MMC 24",
};
export const ConnOPTITAP1_FEMALE: IConnectorType = {
  key: "31",
  description: OPTITAP1F_FEMALE,
  type: OPTITAP1F_FEMALE,
  fiberCount: 1,
  name: "OPTITAP FEMALE 1F",
  available: {
    outdoor: true,
    singleMode: true,
    onlyEMEAUser: true,
  },
};
export const ConnOPTITAP1_MALE: IConnectorType = {
  key: "31",
  description: OPTITAP1F_MALE,
  type: OPTITAP1F_MALE,
  fiberCount: 1,
  name: "OPTITAP MALE 1F",
  available: {
    outdoor: true,
    singleMode: true,
    onlyEMEAUser: true,
  },
};
export const ConnOPTITIP4: IConnectorType = {
  key: "32",
  description: OPTITIP4F,
  type: OPTITIP4F,
  fiberCount: 4,
  name: "OPTITIP 4F",
  available: {
    outdoor: true,
    singleMode: true,
    onlyEMEAUser: true,
  },
};
export const ConnOPTITIP8: IConnectorType = {
  key: "33",
  description: OPTITIP8F,
  type: OPTITIP8F,
  fiberCount: 8,
  name: "OPTITIP 8F",
  available: {
    outdoor: true,
    singleMode: true,
    onlyEMEAUser: true,
  },
};
export const ConnOPTITIP12: IConnectorType = {
  key: "34",
  description: OPTITIP12F,
  type: OPTITIP12F,
  fiberCount: 12,
  name: "OPTITIP 12F",
  available: {
    outdoor: true,
    singleMode: true,
    onlyEMEAUser: true,
  },
};
export const ConnPushlok1_SF: IConnectorType = {
  key: "35",
  description: PUSHLOK1F_SF,
  type: PUSHLOK1F_SF,
  fiberCount: 1,
  name: "PUSHLOK 1F SF",
  available: {
    outdoor: true,
    singleMode: true,
    onlyEMEAUser: true,
  },
};
export const ConnPushlok4_MF: IConnectorType = {
  key: "36",
  description: PUSHLOK4F_MF,
  type: PUSHLOK4F_MF,
  fiberCount: 4,
  name: "PUSHLOK 4F MF",
  available: {
    outdoor: true,
    singleMode: true,
    onlyEMEAUser: true,
  },
};
export const ConnPushlok8_MF: IConnectorType = {
  key: "37",
  description: PUSHLOK8F_MF,
  type: PUSHLOK8F_MF,
  fiberCount: 8,
  name: "PUSHLOK 8F MF",
  available: {
    outdoor: true,
    singleMode: true,
    onlyEMEAUser: true,
  },
};
export const ConnPushlok12_MF: IConnectorType = {
  key: "38",
  description: PUSHLOK12F_MF,
  type: PUSHLOK12F_MF,
  fiberCount: 12,
  name: "PUSHLOK 12F MF",
  available: {
    outdoor: true,
    singleMode: true,
    onlyEMEAUser: true,
  },
};
export const ConnPushlok24_MF: IConnectorType = {
  key: "39",
  description: PUSHLOK24F_MF,
  type: PUSHLOK24F_MF,
  fiberCount: 24,
  name: "PUSHLOK 24F MF",
  available: {
    outdoor: true,
    singleMode: true,
    onlyEMEAUser: true,
  },
};
export const ConnECAM1: IConnectorType = {
  key: "40",
  description: ECAM1F,
  type: ECAM1F,
  fiberCount: 1,
  name: "ECAM 1F",
  available: {
    outdoor: true,
    singleMode: true,
    onlyEMEAUser: true,
  },
};

export const ConnectorTypes = {
  // 1F
  ConnPigtail_No_Leg,
  ConnPigtail_With_Leg,
  ConnLCSimplex,
  ConnLCAPCSimplex,
  ConnSCSimplex,
  ConnSCAPCSimplex,
  ConnSCUPCSimplex,
  ConnLSH,
  ConnPushlok1_SF,
  ConnOPTITAP1_FEMALE,
  ConnOPTITAP1_MALE,
  ConnECAM1,
  // 2F
  ConnLC,
  ConnLCAPC,
  ConnLCAPCDuplex,
  ConnLCDuplex,
  ConnSCAPCDuplex,
  ConnSCDuplex,
  ConnMDC,
  ConnSN,
  // 4F
  ConnOPTITIP4,
  ConnPushlok4_MF,
  // 8F
  ConnMTP8_NP,
  ConnMTP8_P,
  ConnOPTITIP8,
  ConnPushlok8_MF,
  // 12F
  ConnMTP12_NP,
  ConnMTP12_P,
  ConnMTP12_FP_NP,
  ConnMTP12_FP_P,
  ConnOPTITIP12,
  ConnPushlok12_MF,
  // 16F
  ConnMMC16_NP,
  ConnMMC16_P,
  // 24F
  ConnMMC24_NP,
  ConnMMC24_P,
  ConnMTP24_NP,
  ConnMTP24_FP_NP,
  ConnMTP24_FP_P,
  ConnPushlok24_MF,
  //ConnMTP24_P, Tim asked to hide this connector until we have more info
} as const;

export type ConnectorType =
  (typeof ConnectorTypes)[keyof typeof ConnectorTypes];

export const getConnectorName = (type: string): string => {
  return getConnectorType(type).name;
};
export const getConnectorType = (type: string): IConnectorType => {
  switch (type) {
    case ConnLC.type:
      return ConnLC;
    case ConnLCAPC.type:
      return ConnLCAPC;
    case ConnLCAPCDuplex.type:
      return ConnLCAPCDuplex;
    case ConnLCDuplex.type:
      return ConnLCDuplex;
    case ConnLCSimplex.type:
      return ConnLCSimplex;
    case ConnLCAPCSimplex.type:
      return ConnLCAPCSimplex;
    case ConnMDC.type:
      return ConnMDC;
    case ConnSCAPCDuplex.type:
      return ConnSCAPCDuplex;
    case ConnSCDuplex.type:
      return ConnSCDuplex;
    case ConnSCSimplex.type:
      return ConnSCSimplex;
    case ConnSCAPCSimplex.type:
      return ConnSCAPCSimplex;
    case ConnSCUPCSimplex.type:
      return ConnSCUPCSimplex;
    case ConnSN.type:
      return ConnSN;
    case ConnLSH.type:
      return ConnLSH;
    case ConnMTP8_NP.type:
      return ConnMTP8_NP;
    case ConnMTP8_P.type:
      return ConnMTP8_P;
    case ConnMTP12_NP.type:
      return ConnMTP12_NP;
    case ConnMTP12_P.type:
      return ConnMTP12_P;
    case ConnMTP12_FP_NP.type:
      return ConnMTP12_FP_NP;
    case ConnMTP12_FP_P.type:
      return ConnMTP12_FP_P;
    case ConnMTP24_NP.type:
      return ConnMTP24_NP;
    case ConnMTP24_P.type:
      return ConnMTP24_P;
    case ConnMTP24_FP_NP.type:
      return ConnMTP24_FP_NP;
    case ConnMTP24_FP_P.type:
      return ConnMTP24_FP_P;
    case ConnPigtail_No_Leg.type:
      return ConnPigtail_No_Leg;
    case ConnPigtail_With_Leg.type:
      return ConnPigtail_With_Leg;
    case ConnMMC16_NP.type:
      return ConnMMC16_NP;
    case ConnMMC16_P.type:
      return ConnMMC16_P;
    case ConnMMC24_NP.type:
      return ConnMMC24_NP;
    case ConnMMC24_P.type:
      return ConnMMC24_P;
    case ConnOPTITAP1_FEMALE.type:
      return ConnOPTITAP1_FEMALE;
    case ConnOPTITAP1_MALE.type:
      return ConnOPTITAP1_MALE;
    case ConnOPTITIP4.type:
      return ConnOPTITIP4;
    case ConnOPTITIP8.type:
      return ConnOPTITIP8;
    case ConnOPTITIP12.type:
      return ConnOPTITIP12;
    case ConnPushlok1_SF.type:
      return ConnPushlok1_SF;
    case ConnPushlok4_MF.type:
      return ConnPushlok4_MF;
    case ConnPushlok8_MF.type:
      return ConnPushlok8_MF;
    case ConnPushlok12_MF.type:
      return ConnPushlok12_MF;
    case ConnPushlok24_MF.type:
      return ConnPushlok24_MF;
    case ConnECAM1.type:
      return ConnECAM1;
    default:
      return {
        key: "-1",
        type: LC,
        description: "ConnectorNotFound",
        fiberCount: -1,
        name: "NotFound",
      };
  }
};

export const LC_KEY = "LCUniboot";
export const MTP_KEY = "MTP";
export const MMC_KEY = "MMC";
export const PINNED_KEY = "Pinned";
export const UNPINNED_KEY = "Unpinned";
export const MTP_PINNED_KEY = `${MTP_KEY}${PINNED_KEY}`;
export const MTP_UNPINNED_KEY = `${MTP_KEY}${UNPINNED_KEY}`;
export const MTP24_PINNED_KEY = `${MTP_KEY}24${PINNED_KEY}`;
export const MTP24_UNPINNED_KEY = `${MTP_KEY}24${UNPINNED_KEY}`;
export const MMC_PINNED_KEY = `${MMC_KEY}_${PINNED_KEY}`;
export const MMC_UNPINNED_KEY = `${MMC_KEY}_${UNPINNED_KEY}`;
export const SN_KEY = "SN";
export const LSH_KEY = "LSH";
export const LC_DUPLEX_KEY = "LCDuplex";
export const SC_DUPLEX_KEY = "SCDuplex";
export const MDC_KEY = "MDC";
export const LC_SIMPLEX_KEY = "LCSimplex";
export const SC_SIMPLEX_KEY = "SCSimplex";
export const PIGTAIL_KEY = "Pigtail";
export const OPTITAP_FEMALE_KEY = "OptitapFemale";
export const OPTITAP_MALE_KEY = "OptitapMale";
export const OPTITIP_KEY = "Optitip";
export const PUSHLOK_MF_KEY = "PushlokMF";
export const PUSHLOK_SF_KEY = "PushlokSF";
export const ECAM_KEY = "Ecam";
export const OutdoorConnectorKeys = [
  OPTITAP_FEMALE_KEY,
  OPTITAP_MALE_KEY,
  OPTITIP_KEY,
  PUSHLOK_SF_KEY,
  PUSHLOK_MF_KEY,
  ECAM_KEY
]

export const ConnectoryTypeKeys = {
  LC_KEY,
  MTP_PINNED_KEY,
  MTP_UNPINNED_KEY,
  MTP24_PINNED_KEY,
  MTP24_UNPINNED_KEY,
  SN_KEY,
  LSH_KEY,
  LC_DUPLEX_KEY,
  SC_DUPLEX_KEY,
  MDC_KEY,
  LC_SIMPLEX_KEY,
  SC_SIMPLEX_KEY,
  PIGTAIL_KEY,
  MMC_PINNED_KEY,
  MMC_UNPINNED_KEY,
  ...OutdoorConnectorKeys
};

export type ConnectorTypeKey =
  (typeof ConnectoryTypeKeys)[keyof typeof ConnectoryTypeKeys];
export const isOutdoorConnector = (connectorKey: string): boolean => {
  return OutdoorConnectorKeys.includes(connectorKey);
}
export const getConnectorTypeString = (type: string) => {
  switch (type) {
    case ConnMTP8_NP.type:
    case ConnMTP12_NP.type:
    case ConnMTP12_FP_NP.type:
      return MTP_UNPINNED_KEY;
    case ConnMTP8_P.type:
    case ConnMTP12_P.type:
    case ConnMTP12_FP_P.type:
      return MTP_PINNED_KEY;
    case ConnMTP24_NP.type:
    case ConnMTP24_FP_NP.type:
      return MTP24_UNPINNED_KEY;
    case ConnMTP24_P.type:
    case ConnMTP24_FP_P.type:
      return MTP24_PINNED_KEY;
    case ConnSN.type:
      return SN_KEY;
    case ConnLSH.type:
      return LSH_KEY;
    case ConnLCAPCDuplex.type:
    case ConnLCDuplex.type:
      return LC_DUPLEX_KEY;
    case ConnLCSimplex.type:
    case ConnLCAPCSimplex.type:
      return LC_SIMPLEX_KEY;
    case ConnSCAPCDuplex.type:
    case ConnSCDuplex.type:
      return SC_DUPLEX_KEY;
    case ConnSCSimplex.type:
    case ConnSCAPCSimplex.type:
    case ConnSCUPCSimplex.type:
      return SC_SIMPLEX_KEY;
    case ConnMDC.type:
      return MDC_KEY;
    case ConnPigtail_No_Leg.type:
    case ConnPigtail_With_Leg.type:
      return PIGTAIL_KEY;
    case ConnMMC16_NP.type:
    case ConnMMC24_NP.type:
      return MMC_UNPINNED_KEY;
    case ConnMMC16_P.type:
    case ConnMMC24_P.type:
      return MMC_PINNED_KEY;
    case ConnOPTITAP1_FEMALE.type:
      return OPTITAP_FEMALE_KEY;
    case ConnOPTITAP1_MALE.type:
      return OPTITAP_MALE_KEY;
    case ConnOPTITIP4.type:
    case ConnOPTITIP8.type:
    case ConnOPTITIP12.type:
      return OPTITIP_KEY;
    case ConnPushlok1_SF.type:
      return PUSHLOK_SF_KEY;
    case ConnPushlok4_MF.type:
    case ConnPushlok8_MF.type:
    case ConnPushlok12_MF.type:
    case ConnPushlok24_MF.type:
      return PUSHLOK_MF_KEY;
    case ConnECAM1.type:
      return ECAM_KEY;
    case ConnLC.type:
    case ConnLCAPC.type:
    default:
      return LC_KEY;
  }
};

export const isMTP = (type: string) => {
  return type.includes(MTP_KEY);
};

export const isMMC = (type: string) => {
  return type.includes(MMC_KEY);
};

export const isDuplex = (type: string) => {
  return type.toLowerCase().includes("duplex");
};

export const isPigtailConnector = (type: string) => {
  return type.includes(PIGTAIL_KEY);
};

export const LCSIMPLEX_FAMILY = "LC Simplex";
export const SCSIMPLEX_FAMILY = "SC Simplex";
const LC_FAMILY = "LC";
const MDC_FAMILY = "MDC";
const SN_FAMILY = "SN";
const LSH_FAMILY = "LSH";
export const LCDUPLEX_FAMILY = "LC Duplex";
export const SCDUPLEX_FAMILY = "SC Duplex";
const MTP8_FAMILY = "MTP8";
const MTP12_FAMILY = "MTP12";
const MTP24_FAMILY = "MTP24";
const MMC16_FAMILY = "MMC16";
const MMC24_FAMILY = "MMC24";
const OPTITAP1_FAMILY = "OPTITAP1";
const OPTITIP4_FAMILY = "OPTITIP4";
const OPTITIP8_FAMILY = "OPTITIP8";
const OPTITIP12_FAMILY = "OPTITIP12";
const PUSHLOK1_SF_FAMILY = "PUSHLOK1 SF";
const PUSHLOK4_MF_FAMILY = "PUSHLOK4 MF";
const PUSHLOK8_MF_FAMILY = "PUSHLOK8 MF";
const PUSHLOK12_MF_FAMILY = "PUSHLOK12 MF";
const PUSHLOK24_MF_FAMILY = "PUSHLOK24 MF";
export const ECAM1_FAMILY = "ECAM1";

export const getConnectorFamilyFromType = (connectorType: string) => {
  switch (connectorType) {
    case ConnMTP8_NP.type:
    case ConnMTP8_P.type:
      return MTP8_FAMILY;
    case ConnMTP12_NP.type:
    case ConnMTP12_FP_NP.type:
    case ConnMTP12_P.type:
    case ConnMTP12_FP_P.type:
      return MTP12_FAMILY;
    case ConnMTP24_NP.type:
    case ConnMTP24_FP_NP.type:
    case ConnMTP24_P.type:
    case ConnMTP24_FP_P.type:
      return MTP24_FAMILY;
    case ConnSN.type:
      return SN_FAMILY;
    case ConnLSH.type:
      return LSH_FAMILY;
    case ConnMDC.type:
      return MDC_FAMILY;
    case ConnLCAPCDuplex.type:
    case ConnLCDuplex.type:
      return LCDUPLEX_FAMILY;
    case ConnLCSimplex.type:
    case ConnLCAPCSimplex.type:
      return LCSIMPLEX_FAMILY;
    case ConnSCAPCDuplex.type:
    case ConnSCDuplex.type:
      return SCDUPLEX_FAMILY;
    case ConnSCSimplex.type:
    case ConnSCAPCSimplex.type:
    case ConnSCUPCSimplex.type:
      return SCSIMPLEX_FAMILY;
    case ConnMMC16_NP.type:
    case ConnMMC16_P.type:
      return MMC16_FAMILY;
    case ConnMMC24_NP.type:
    case ConnMMC24_P.type:
      return MMC24_FAMILY;
    case ConnOPTITAP1_FEMALE.type:
      return OPTITAP1_FAMILY;
    case ConnOPTITAP1_MALE.type:
      return OPTITAP1_FAMILY;
    case ConnOPTITIP4.type:
      return OPTITIP4_FAMILY;
    case ConnOPTITIP8.type:
      return OPTITIP8_FAMILY;
    case ConnOPTITIP12.type:
      return OPTITIP12_FAMILY;
    case ConnPushlok1_SF.type:
      return PUSHLOK1_SF_FAMILY;
    case ConnPushlok4_MF.type:
      return PUSHLOK4_MF_FAMILY;
    case ConnPushlok8_MF.type:
      return PUSHLOK8_MF_FAMILY;
    case ConnPushlok12_MF.type:
      return PUSHLOK12_MF_FAMILY;
    case ConnPushlok24_MF.type:
      return PUSHLOK24_MF_FAMILY;
    case ConnECAM1.type:
      return ECAM1_FAMILY;
    case ConnLC.type:
    case ConnLCAPC.type:
    default:
      return LC_FAMILY;
  }
};

export const getConnectorFamilyFromFiberCount = (fiberCount: number) => {
  return fiberCount > 2 ? `MTP${fiberCount}` : `${fiberCount}F`;
};

export interface ICableType {
  key: string;
  description: string | null;
}
