import { useContext, useMemo } from "react";
import { hexStringToNumber } from "../../../../ui/dialog/color/types";
import { Sides } from "../../../../workspace/assembly/breakout/types";
import {
    isMTP,
    LC,
    LC_SIMPLEX,
    LC_UPC,
    SC_SIMPLEX,
    SC_UPC,
    SN,
    MDC,
    LSH,
    SC_APC,
    SC_APC_SIMPLEX,
    SC_UPC_SIMPLEX,
    LC_APC,
    LC_APC_SIMPLEX,
    LC_APC_UNIBOOT,
    isPigtailConnector,
    PIGTAIL_NO_LEG,
    isMMC,
    ECAM1F,
    OPTITIP4F,
    OPTITIP8F,
    OPTITIP12F,
    OPTITAP1F_FEMALE,
    OPTITAP1F_MALE,
    PUSHLOK4F_MF,
    PUSHLOK8F_MF,
    PUSHLOK12F_MF,
    PUSHLOK24F_MF,
    PUSHLOK1F_SF,
    MMC24F_P,
    MMC24F_NP,
    MMC16F_P,
    MMC16F_NP,
} from "../../../../workspace/assembly/connector/types";
import { JacketColors } from "../../../../workspace/assembly/palette/types";
import { ColorContext } from "../../../color/types";
import { CablePositionContext } from "../../position/types";
import { connectorSpacing, getConnectorHeight } from "../../side/hooks";
import { SideContext } from "../../side/types";
import { IPosition } from "../../types";
import { BreakoutContext } from "../types";
import { IConnectorLegProps } from "./connector-leg/types";
import { getConnectorSprite, LargestSprite } from "./connector-sprite/types";
import { ConnectorFurcationProps } from "./types";
import { normalTrunkHeight } from "../../cable-base/hooks";
import { MarkerSettingsContext } from "../../../../workspace/store/types";

const borderThickness = 1;
export const furcationTrunkBaseWidth = 50;

export const legStaggerFactor = 0.5;
export const legGap = LargestSprite.height * legStaggerFactor;
export const groupOffsetFactor = 0.75;
export const groupGap = LargestSprite.height * groupOffsetFactor;

export const useConnectorFurcation = (props: ConnectorFurcationProps) => {
    const { furcation } = props;
    const { position: breakoutPosition } = useContext(BreakoutContext);
    const { m, breakoutsProps, hasGroupOffsets, maxUniqueLength } =
        useContext(SideContext);
    const {
        cableColor: { sideALegColor, sideBLegColor, outlineColor },
    } = useContext(ColorContext);
    const defaultLegColor = m > 0 ? sideBLegColor : sideALegColor;
    const { state: cablePositionState } = useContext(CablePositionContext);
    const { showTransitions } = useContext(MarkerSettingsContext);
    const side = m > 0 ? Sides.SideB : Sides.SideA;
    const sidePositions = cablePositionState.sidePositions[side];
    const breakoutProps = breakoutsProps.find(
        (b) => b.position === breakoutPosition
    );

    const cableLegProps: IConnectorLegProps[] = useMemo(() => {
        let legs: IConnectorLegProps[] = [];
        if (breakoutProps && furcation && props.height !== undefined) {
            const legJacketColor = JacketColors.find(
                (c) => c.name === furcation.jacketColor
            );
            const legColor = legJacketColor
                ? hexStringToNumber(legJacketColor.hex)
                : defaultLegColor;

            const {
                connectors: breakoutConnectors,
                trunkLength,
                uniqueLegLengths,
                groupsUniqueLegLengths,
                uniqueGroupLengths,
            } = breakoutProps;
            const breakoutHeight =
                breakoutConnectors.length > 1
                    ? breakoutConnectors
                          .map((c) => getConnectorHeight(c.connector.type))
                          .reduce((a, b) => a + b)
                    : getConnectorHeight(breakoutConnectors[0].connector.type);

            const connectorHeight =
                breakoutHeight / breakoutConnectors.length +
                connectorSpacing / 2;
            let startY =
                breakoutConnectors.length > 1
                    ? -(breakoutConnectors.length * 0.25 * connectorHeight)
                    : 0;

            let previousStaggerOffset = 0;
            let previousGroupOffsetIndex = 0;
            legs = breakoutConnectors.map(
                (
                    {
                        groupPosition,
                        groupLength,
                        connector,
                        isLastGroupConnector,
                    },
                    i
                ) => {
                    const sprite = getConnectorSprite(connector.type);
                    const heightDelta = LargestSprite.height - sprite.height;

                    const cp: IPosition = { x: 0, y: 0 };
                    const offsetY =
                        breakoutConnectors.length > 1
                            ? i * connectorHeight * 0.5
                            : 0;

                    const cp2: IPosition = { x: m * 5, y: startY + offsetY };

                    const dstOffsetX = m * heightDelta * 0.5;
                    const dst: IPosition = {
                        x: m * 15 + dstOffsetX,
                        y: startY + offsetY,
                    };
                    const isPigtailWithNoLeg =
                        breakoutConnectors[0].connector.type === PIGTAIL_NO_LEG;
                    const curveThickness =
                        isPigtailWithNoLeg && showTransitions
                            ? normalTrunkHeight
                            : getTrunkHeight(connector.type);
                    const trunkBorderThickness =
                        isPigtailWithNoLeg && showTransitions
                            ? 2
                            : borderThickness;
                    const bezierCurve = {
                        cp,
                        cp2,
                        dst,
                        line: { color: legColor, thickness: curveThickness },
                        outline: {
                            color: outlineColor,
                            thickness: curveThickness + trunkBorderThickness,
                        },
                    };

                    const uniqueLegLength =
                        uniqueLegLengths.find(
                            (g) =>
                                connector.length && g === connector.length.value
                        ) || uniqueLegLengths[0];
                    const nbGroupsOffsets = groupsUniqueLegLengths
                        .map((g) => g.groupOffset)
                        .filter((o) => o > 0).length;
                    let trunkOffsetIndex = 0;
                    let groupOffsetIndex = 0;
                    if (hasGroupOffsets && groupPosition > 1) {
                        // We will draw with group offset if possible
                        const uniqueGroupLength = groupLength.value;
                        const {
                            groupOffset,
                            hasGap,
                            uniqueLegLengths: groupUniqueLegLengths,
                        } = groupsUniqueLegLengths[groupPosition - 1];
                        if (groupOffset > 0 && hasGap) {
                            // Draw with group offset
                            trunkOffsetIndex =
                                groupUniqueLegLengths.indexOf(uniqueLegLength);
                            groupOffsetIndex =
                                uniqueGroupLengths.indexOf(uniqueGroupLength);
                        } else {
                            trunkOffsetIndex =
                                uniqueLegLengths.indexOf(uniqueLegLength); // Draw relative
                            if (previousGroupOffsetIndex > 0) {
                                // Draw relative to the previous group offset
                                trunkOffsetIndex =
                                    groupUniqueLegLengths.indexOf(
                                        uniqueLegLength
                                    );
                                groupOffsetIndex = previousGroupOffsetIndex;
                            }
                        }
                    } else {
                        // First group is always drawn relative. Obviously if we have no group offset, we want it relative as well
                        trunkOffsetIndex =
                            uniqueLegLengths.indexOf(uniqueLegLength);
                    }

                    let trunkDelta = 0;
                    let legDelta = 0;
                    let groupDelta = 0;
                    if (
                        uniqueLegLengths.includes(maxUniqueLength) &&
                        !breakoutsProps.every(
                            (u) =>
                                u.trunkLength === trunkLength &&
                                u.uniqueLegLengths.includes(maxUniqueLength) &&
                                u.uniqueLegLengths.length ===
                                    uniqueLegLengths.length
                        )
                    ) {
                        // Adjusting according to the longest An/Bn
                        const longerTrunks = breakoutsProps
                            .filter(
                                (u) =>
                                    u.position !== breakoutPosition &&
                                    u.trunkLength > trunkLength
                            )
                            .map((u) => u.trunkLength);
                        if (longerTrunks.length > 0) {
                            const longestTrunk = longerTrunks.reduce((a, b) =>
                                a > b ? a : b
                            );
                            if (
                                sidePositions[longestTrunk] &&
                                sidePositions[trunkLength]
                            ) {
                                const longestPosition =
                                    sidePositions[longestTrunk].positionOffset;
                                const localPosition =
                                    sidePositions[trunkLength].positionOffset;
                                trunkDelta = longestPosition - localPosition;
                            }
                        }

                        // Adjusting according to the breakout with the most unique lengths
                        const similarOrMoreUniqueLengths =
                            breakoutsProps.filter(
                                (u) =>
                                    u.position !== breakoutPosition &&
                                    u.uniqueLegLengths.length >=
                                        uniqueLegLengths.length
                            );
                        if (similarOrMoreUniqueLengths.length > 0) {
                            const longestWithMostUniqueLengths =
                                similarOrMoreUniqueLengths.reduce((a, b) => {
                                    if (
                                        a.uniqueLegLengths.length ===
                                        b.uniqueLegLengths.length
                                    ) {
                                        return a.trunkLength > b.trunkLength
                                            ? a
                                            : b;
                                    }
                                    return a.uniqueLegLengths.length >
                                        b.uniqueLegLengths.length
                                        ? a
                                        : b;
                                });

                            // Compensating for nb of unique lengths
                            let nbMostUniqueLengths = 0;
                            let nbLocalUniqueLengths = 0;
                            if (hasGroupOffsets) {
                                nbMostUniqueLengths =
                                    longestWithMostUniqueLengths.groupsUniqueLegLengths
                                        .map(
                                            (g) => g.uniqueLegLengths.length - 1
                                        )
                                        .reduce((a, b) => a + b, 0);
                                nbLocalUniqueLengths = groupsUniqueLegLengths
                                    .map((g) => g.uniqueLegLengths.length - 1)
                                    .reduce((a, b) => a + b, 0);
                            } else {
                                nbMostUniqueLengths =
                                    longestWithMostUniqueLengths
                                        .uniqueLegLengths.length;
                                nbLocalUniqueLengths = uniqueLegLengths.length;
                            }
                            legDelta +=
                                nbMostUniqueLengths - nbLocalUniqueLengths;

                            // Compensating for group unique lengths
                            const nbMostGroupOffsets =
                                longestWithMostUniqueLengths.groupsUniqueLegLengths
                                    .map((g) => g.groupOffset)
                                    .filter((o) => o > 0).length;
                            groupDelta += nbMostGroupOffsets - nbGroupsOffsets;

                            if (
                                !longestWithMostUniqueLengths.uniqueLegLengths.includes(
                                    maxUniqueLength
                                ) ||
                                (longestWithMostUniqueLengths.trunkLength !==
                                    trunkLength &&
                                    longestWithMostUniqueLengths
                                        .uniqueLegLengths.length >
                                        uniqueLegLengths.length &&
                                    nbGroupsOffsets > 0)
                            ) {
                                legDelta++;
                            }
                        }
                    }

                    let groupStaggerOffset = 0;
                    if (hasGroupOffsets && nbGroupsOffsets > 0) {
                        if (groupOffsetIndex > 0) {
                            if (
                                connector.spare ||
                                groupOffsetIndex === previousGroupOffsetIndex
                            ) {
                                groupStaggerOffset = previousStaggerOffset;
                            } else {
                                groupStaggerOffset =
                                    previousStaggerOffset + groupGap;
                            }
                        }
                    }

                    const legStaggerOffset = legGap * trunkOffsetIndex;
                    const staggerOffset = groupStaggerOffset + legStaggerOffset;
                    const legDeltaOffset = legDelta * legGap;
                    const groupDeltaOffset = groupDelta * groupGap;
                    const deltaOffset = legDeltaOffset + groupDeltaOffset;
                    const offsetX =
                        m *
                        (furcationTrunkBaseWidth +
                            trunkDelta +
                            staggerOffset +
                            deltaOffset);

                    const isMTPConnector = isMTP(connector.type);
                    const isPigtail = isPigtailConnector(connector.type);
                    const isMMCConnector = isMMC(connector.type);
                    const mmcOffsetX = isMMCConnector ? m * 1.6 : 0;
                    const mtpOffsetX = isMTPConnector ? m * 3.55 : 0;
                    const pigtailOffsetX = isPigtail ? m * 50 : 0;
                    const lineEnd = {
                        x:
                            dst.x +
                            offsetX +
                            mtpOffsetX +
                            pigtailOffsetX +
                            mmcOffsetX,
                        y: dst.y,
                    };

                    if (isLastGroupConnector) {
                        previousStaggerOffset = staggerOffset;
                        if (groupPosition > 1) {
                            // Preserving offsets for next groups
                            previousGroupOffsetIndex = groupOffsetIndex;
                        }
                    }

                    const spriteMultiplier = getSpriteMultiplier(
                        connector.type
                    );
                    return {
                        groupPosition,
                        bezierCurve,
                        connector,
                        trunkOffset: trunkOffsetIndex,
                        furcation,
                        lineEnd,
                        spriteMultiplier,
                    };
                }
            );
        }

        return legs;
    }, [
        breakoutProps,
        furcation,
        props.height,
        defaultLegColor,
        m,
        showTransitions,
        outlineColor,
        hasGroupOffsets,
        maxUniqueLength,
        breakoutsProps,
        breakoutPosition,
        sidePositions,
    ]);

    return { cableLegProps, x: props.x, y: props.y };
};

const getSpriteMultiplier = (connectorType: string) => {
    switch (connectorType) {
        case LC:
        case LC_APC_UNIBOOT:
        case SN:
            return 10;
        case LC_APC:
        case LC_UPC:
            return 9.1;
        case LC_SIMPLEX:
        case LC_APC_SIMPLEX:
            return 11.6;
        case SC_APC:
        case SC_UPC:
            return 7.6;
        case SC_SIMPLEX:
        case SC_APC_SIMPLEX:
        case SC_UPC_SIMPLEX:
            return 9.4;
        case LSH:
            return 8.9;
        case MDC:
            return 10.9;
        case MMC16F_NP:
        case MMC16F_P:
        case MMC24F_NP:
        case MMC24F_P:
            return 8.7;
        case ECAM1F:
            return 10.5;
        case OPTITIP4F:
        case OPTITIP8F:
        case OPTITIP12F:
            return 13.75;
        case OPTITAP1F_FEMALE:
            return 9.8;
        case OPTITAP1F_MALE:
            return 8.21;
        case PUSHLOK4F_MF:
        case PUSHLOK8F_MF:
        case PUSHLOK12F_MF:
        case PUSHLOK24F_MF:
            return 21;
        case PUSHLOK1F_SF:
            return 15.5;
        default:
            return 7.685;
    }
};

export const getTrunkHeight = (connectorType: string) => {
    switch (connectorType) {
        case LC_APC:
        case LC_UPC:
            return 3.25;
        case SC_APC:
        case SC_UPC:
            return 3.25;
        case LSH:
            return 0.5;
        case LC_SIMPLEX:
        case LC_APC_SIMPLEX:
        case SC_SIMPLEX:
        case SC_APC_SIMPLEX:
        case SC_UPC_SIMPLEX:
        case ECAM1F:
        case OPTITIP4F:
        case OPTITIP8F:
        case OPTITIP12F:
        case OPTITAP1F_FEMALE:
        case OPTITAP1F_MALE:
        case PUSHLOK4F_MF:
        case PUSHLOK8F_MF:
        case PUSHLOK12F_MF:
        case PUSHLOK24F_MF:
        case PUSHLOK1F_SF:
            return 1;
        default:
            return 1.5;
    }
};
