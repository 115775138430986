import React from "react";

export const OPTITAPMale: React.FC = () => {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 90 332.308"
            width="90"
            height="332.308"
        >
            <g>
                <g>
                    <path
                        className="st1"
                        fill="#231f20"
                        d="M68.192 87.231v191.769c0 5.885 -4.5 10.385 -10.385 10.385H11.769c-5.885 0 -10.385 -4.5 -10.385 -10.385V87.231c0 -2.077 1.385 -3.462 3.462 -3.462h59.885c2.077 0 3.462 1.385 3.462 3.462"
                    />
                    <path
                        className="st3"
                        fill="#494747"
                        d="M58.5 291.115H11.769c-6.577 0 -11.769 -5.538 -11.769 -12.115V87.231c0 -2.769 2.077 -5.192 4.846 -5.192h59.885c2.769 0 4.846 2.423 4.846 5.192v191.769c0 6.577 -5.192 12.115 -11.769 12.115zM4.846 85.5c-1.038 0 -1.731 0.692 -1.731 1.731v191.769c0 4.846 3.808 8.654 8.308 8.654h46.731c4.5 0 8.308 -3.808 8.308 -8.654V87.231c0 -1.038 -0.692 -1.731 -1.731 -1.731z"
                    />
                </g>
                <g>
                    <path
                        className="st1"
                        fill="#231f20"
                        points="4.3 .5 3.4 24.2 16.9 24.2 15.9 .5 4.3 .5"
                        d="M14.885 1.731L11.769 83.769L58.5 83.769L55.038 1.731L14.885 1.731Z"
                    />
                    <path
                        className="st3"
                        fill="#494747"
                        d="M60.231 85.5H10.038L13.5 0h43.269zm-46.731 -3.462h43.269L53.308 3.462H16.615l-3.115 78.923Z"
                    />
                </g>
                <g>
                    <path
                        className="st1"
                        fill="#231f20"
                        points="16.5 16.3 3.7 16.3 3.4 24.2 16.9 24.2 16.5 16.3"
                        d="M57.115 56.423L12.808 56.423L11.769 83.769L58.5 83.769L57.115 56.423Z"
                    />
                    <path
                        className="st3"
                        fill="#494747"
                        d="M60.231 85.5H10.038l1.385 -30.808h47.769l1.385 30.808zm-46.731 -3.462h43.269l-1.038 -23.885H14.538z"
                    />
                </g>
            </g>
            <g>
                <path
                    className="st1"
                    fill="#231f20"
                    d="M58.5 316.731v-20.423H11.769v20.423c0 7.615 5.885 13.846 13.5 13.846h20.077c7.269 0 13.5 -6.231 13.5 -13.846zm-27 3.462c-3.808 0 -6.577 -3.115 -6.577 -6.923v-3.462c0 -1.731 1.385 -3.462 3.462 -3.462h13.5c1.731 0 3.462 1.385 3.462 3.462v3.462c0 3.808 -3.115 6.923 -6.577 6.923z"
                />
                <path
                    className="st3"
                    fill="#494747"
                    d="M45 332.308H24.923c-8.308 0 -14.885 -6.923 -14.885 -15.577v-22.154h49.846v22.154c0 8.654 -6.577 15.577 -14.885 15.577m-31.5 -34.269v18.692c0 6.577 5.192 12.115 11.769 12.115h20.077c6.577 0 11.769 -5.538 11.769 -12.115v-18.692zm24.923 23.885H31.846c-4.5 0 -8.308 -3.808 -8.308 -8.654v-3.462c0 -2.769 2.077 -5.192 4.846 -5.192h13.5c2.769 0 4.846 2.423 4.846 5.192v3.462c0 4.846 -3.808 8.654 -8.308 8.654m-10.038 -13.5c-1.038 0 -1.731 0.692 -1.731 1.731v3.462c0 2.769 2.077 5.192 4.846 5.192H38.077c2.769 0 4.846 -2.423 4.846 -5.192v-3.462c0 -1.038 -0.692 -1.731 -1.731 -1.731H27.692z"
                />
            </g>
            <g>
                <path
                    className="st0"
                    fill="none"
                    x1="1.7"
                    y1="84.1"
                    x2="18.7"
                    y2="84.1"
                    d="M5.885 291.115L64.731 291.115"
                />
                <path
                    className="st2"
                    fill="#6b6b6b"
                    d="M64.731 294.577h-58.846c-1.731 0 -3.462 -1.385 -3.462 -3.462s1.385 -3.462 3.462 -3.462h58.846c1.731 0 3.462 1.385 3.462 3.462s-1.385 3.462 -3.462 3.462"
                />
            </g>
            <g>
                <path
                    className="st0"
                    fill="none"
                    x1="1.7"
                    y1="22.7"
                    x2="18.7"
                    y2="22.7"
                    d="M5.885 78.577L64.731 78.577"
                />
                <path
                    className="st2"
                    fill="#6b6b6b"
                    d="M64.731 82.038h-58.846c-1.731 0 -3.462 -1.385 -3.462 -3.462s1.385 -3.462 3.462 -3.462h58.846c1.731 0 3.462 1.385 3.462 3.462s-1.385 3.462 -3.462 3.462"
                />
            </g>
            <path
                className="st2"
                fill="#6b6b6b"
                d="M71.654 292.846h-10.038c-1.038 0 -1.731 -0.692 -1.731 -1.731s0.692 -1.731 1.731 -1.731h10.038c8.308 0 14.885 -6.923 14.885 -15.577V95.885c0 -8.654 -6.577 -15.577 -14.885 -15.577h-10.038c-1.038 0 -1.731 -0.692 -1.731 -1.731s0.692 -1.731 1.731 -1.731h10.038c10.038 0 18.346 8.308 18.346 18.692v178.269c0 10.385 -8.308 18.692 -18.346 18.692Z"
            />
        </svg>
    );
};

export const getOPTITAPMaleSVGString = () =>
    `  
          <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 90 332.308"
            width="90"
            height="332.308"
        >
            <g>
                <g>
                    <path
                        class="st1"
                        fill="#231f20"
                        d="M68.192 87.231v191.769c0 5.885 -4.5 10.385 -10.385 10.385H11.769c-5.885 0 -10.385 -4.5 -10.385 -10.385V87.231c0 -2.077 1.385 -3.462 3.462 -3.462h59.885c2.077 0 3.462 1.385 3.462 3.462"
                    />
                    <path
                        class="st3"
                        fill="#494747"
                        d="M58.5 291.115H11.769c-6.577 0 -11.769 -5.538 -11.769 -12.115V87.231c0 -2.769 2.077 -5.192 4.846 -5.192h59.885c2.769 0 4.846 2.423 4.846 5.192v191.769c0 6.577 -5.192 12.115 -11.769 12.115zM4.846 85.5c-1.038 0 -1.731 0.692 -1.731 1.731v191.769c0 4.846 3.808 8.654 8.308 8.654h46.731c4.5 0 8.308 -3.808 8.308 -8.654V87.231c0 -1.038 -0.692 -1.731 -1.731 -1.731z"
                    />
                </g>
                <g>
                    <path
                        class="st1"
                        fill="#231f20"
                        points="4.3 .5 3.4 24.2 16.9 24.2 15.9 .5 4.3 .5"
                        d="M14.885 1.731L11.769 83.769L58.5 83.769L55.038 1.731L14.885 1.731Z"
                    />
                    <path
                        class="st3"
                        fill="#494747"
                        d="M60.231 85.5H10.038L13.5 0h43.269zm-46.731 -3.462h43.269L53.308 3.462H16.615l-3.115 78.923Z"
                    />
                </g>
                <g>
                    <path
                        class="st1"
                        fill="#231f20"
                        points="16.5 16.3 3.7 16.3 3.4 24.2 16.9 24.2 16.5 16.3"
                        d="M57.115 56.423L12.808 56.423L11.769 83.769L58.5 83.769L57.115 56.423Z"
                    />
                    <path
                        class="st3"
                        fill="#494747"
                        d="M60.231 85.5H10.038l1.385 -30.808h47.769l1.385 30.808zm-46.731 -3.462h43.269l-1.038 -23.885H14.538z"
                    />
                </g>
            </g>
            <g>
                <path
                    class="st1"
                    fill="#231f20"
                    d="M58.5 316.731v-20.423H11.769v20.423c0 7.615 5.885 13.846 13.5 13.846h20.077c7.269 0 13.5 -6.231 13.5 -13.846zm-27 3.462c-3.808 0 -6.577 -3.115 -6.577 -6.923v-3.462c0 -1.731 1.385 -3.462 3.462 -3.462h13.5c1.731 0 3.462 1.385 3.462 3.462v3.462c0 3.808 -3.115 6.923 -6.577 6.923z"
                />
                <path
                    class="st3"
                    fill="#494747"
                    d="M45 332.308H24.923c-8.308 0 -14.885 -6.923 -14.885 -15.577v-22.154h49.846v22.154c0 8.654 -6.577 15.577 -14.885 15.577m-31.5 -34.269v18.692c0 6.577 5.192 12.115 11.769 12.115h20.077c6.577 0 11.769 -5.538 11.769 -12.115v-18.692zm24.923 23.885H31.846c-4.5 0 -8.308 -3.808 -8.308 -8.654v-3.462c0 -2.769 2.077 -5.192 4.846 -5.192h13.5c2.769 0 4.846 2.423 4.846 5.192v3.462c0 4.846 -3.808 8.654 -8.308 8.654m-10.038 -13.5c-1.038 0 -1.731 0.692 -1.731 1.731v3.462c0 2.769 2.077 5.192 4.846 5.192H38.077c2.769 0 4.846 -2.423 4.846 -5.192v-3.462c0 -1.038 -0.692 -1.731 -1.731 -1.731H27.692z"
                />
            </g>
            <g>
                <path
                    class="st0"
                    fill="none"
                    x1="1.7"
                    y1="84.1"
                    x2="18.7"
                    y2="84.1"
                    d="M5.885 291.115L64.731 291.115"
                />
                <path
                    class="st2"
                    fill="#6b6b6b"
                    d="M64.731 294.577h-58.846c-1.731 0 -3.462 -1.385 -3.462 -3.462s1.385 -3.462 3.462 -3.462h58.846c1.731 0 3.462 1.385 3.462 3.462s-1.385 3.462 -3.462 3.462"
                />
            </g>
            <g>
                <path
                    class="st0"
                    fill="none"
                    x1="1.7"
                    y1="22.7"
                    x2="18.7"
                    y2="22.7"
                    d="M5.885 78.577L64.731 78.577"
                />
                <path
                    class="st2"
                    fill="#6b6b6b"
                    d="M64.731 82.038h-58.846c-1.731 0 -3.462 -1.385 -3.462 -3.462s1.385 -3.462 3.462 -3.462h58.846c1.731 0 3.462 1.385 3.462 3.462s-1.385 3.462 -3.462 3.462"
                />
            </g>
            <path
                class="st2"
                fill="#6b6b6b"
                d="M71.654 292.846h-10.038c-1.038 0 -1.731 -0.692 -1.731 -1.731s0.692 -1.731 1.731 -1.731h10.038c8.308 0 14.885 -6.923 14.885 -15.577V95.885c0 -8.654 -6.577 -15.577 -14.885 -15.577h-10.038c-1.038 0 -1.731 -0.692 -1.731 -1.731s0.692 -1.731 1.731 -1.731h10.038c10.038 0 18.346 8.308 18.346 18.692v178.269c0 10.385 -8.308 18.692 -18.346 18.692Z"
            />
        </svg>
    `;
