import { CABContainer, EndDesignationText } from "../../pixi/cable/types";
import { IBreakout, IConnector, IConnectorGroup, Sides } from "./breakout/types";
import { ConnectorTypes, getConnectorType } from "./connector/types";
import { Length, Units } from "./length/types";
import { SideContainer } from '../../pixi/cable/side/types';
import { CableBaseContainers } from "../../pixi/cable/cable-base/types";
import { BreakoutContainers } from '../../pixi/cable/breakout/types';
import { BezierCurveGraphics } from '../../pixi/bezier-curve/types';
import { FurcationContainer } from '../../pixi/cable/breakout/connector-furcation/types';
import { ConnectorLegContainers } from '../../pixi/cable/breakout/connector-furcation/connector-leg/types';
import { ConnectorSpriteContainer } from '../../pixi/cable/breakout/connector-furcation/connector-sprite/types';
import { DocumentGraphicsContainer } from '../../pixi/document/types';
import { IAssemblyInfo } from "./info/types";
import { IAssemblyPalette } from "./palette/types";
import { Aqua, Black, Lime, WhiteBlackBootColors, WhiteBlueBootColors, WhiteWhiteBootColors, Yellow } from "../../ui/dialog/color/types";
import { IAssemblyPolarity } from "./polarity/types";
import { IAssemblyAnnotation } from "./annotation/types";
import { LengthMarkerContainer } from "../../pixi/markers/length/types";
import { PolaritySortTypes } from "./settings/types";
import { defaultLabelTolerances, defaultLabelToleranceValues, defaultTolerances, defaultToleranceValues, tolerances } from "../../pixi/markers/tolerance/types";

export const FiberCounts = [
    8,
    12,
    16,
    24,
    36,
    48,
    72,
    96,
    144,
    288,
    384,
    576,
    864
] as const;

export const EMEAFiberCounts = [
    8,
    12,
    16,
    24,
    36,
    38,
    48,
    72,
    96,
    144,
    192,
    288,
    384,
    432,
    576,
    864
]

export const Shapes = {
    Symmetrical: "Symmetrical",
    Asymmetrical: "Asymmetrical"
} as const;

export const CableEnvironments = {
    Indoor: "Indoor",
    Outdoor: "Outdoor",
    IndoorOutdoor: "Indoor/Outdoor"
} as const;

export type FiberCount = typeof FiberCounts[keyof typeof FiberCounts]
export type Shape = typeof Shapes[keyof typeof Shapes]
export type CableEnvironment = typeof CableEnvironments[keyof typeof CableEnvironments]

export const FlameRatings = {
    PlenumOFNP: "Plenum (OFNP)",
    LSZH: "LSZH",
    CPRCCA: "CPR Cca",
    CPRB2CA: "CPR B2ca",
    RiserOFNR: "Riser (OFNR)"
} as const

export interface IFlameRating {
    key: string,
    description: string,
    value: string,
    code?: string
}

export const PlenumOFNP: IFlameRating = { key: '0', description: "Plenum (OFNP)", value: FlameRatings.PlenumOFNP, code: "PN" };
export const LSZH: IFlameRating = { key: '1', description: "LSZH", value: FlameRatings.LSZH, code: "LZ" };
export const CPRCCA: IFlameRating = { key: '2', description: "CPR Cca", value: FlameRatings.CPRCCA };

export const PullingGrips = {
    EndA: "End A",
    EndB: "End B",
    BothEnds: "Both Ends",
    None: "None"
} as const

export type PullingGrip = typeof PullingGrips[keyof typeof PullingGrips];

export const BootColorCombinations = {
    WhiteWhite: "A (White), B (White)",
    WhiteBlack: "A (White), B (Black)",
    WhiteBlue: "A (White), B (Blue)",
} as const

export type BootColorCombination = typeof BootColorCombinations[keyof typeof BootColorCombinations];

export const CableTypes = {
    Altos: "ALTOS",
    BareRibbon: "BARE RIBBON",
    DFX: "DFX",
    EDGE: "EDGE",
    Fanout: "FANOUT",
    JacketedRibbon: "JACKETED RIBBON",
    MIC: "MIC",
    TMIC: "TACTICAL MIC",
    UMIC: "UMIC",
    Zipcord: "ZIPCORD",
    // for legacy use
    SingleMode: "Single mode",
    MultiMode: "Multi mode"
} as const

export type CableType = typeof CableTypes[keyof typeof CableTypes];

export const FiberTypes = {
    SMOS2: "SM OS2",
    MMOM3: "MM OM3",
    MMOM4: "MM OM4",
    MM0M5: "MM OM5"
} as const

export type FiberType = typeof FiberTypes[keyof typeof FiberTypes];

export const FurcationOuterDiameters = [
    "Default",
    "900 um",
    "1.2 mm",
    "1.6 mm",
    "2.0 mm",
    "2.9 mm",
    "4.8 mm"
];

export interface IAssembly {
    id?: number;
    fiberCount: number;
    overallLength?: Length;
    sideA?: IAssemblySide;
    sideB?: IAssemblySide;
    assemblyInfo?: IAssemblyInfo;
    palette?: IAssemblyPalette;
    annotation?: IAssemblyAnnotation;
    polarity?: IAssemblyPolarity;
    polarityAssignment?: string;
}

export interface IAssemblySide {
    id?: number;
    assemblyId?: number;
    fiberCount?: number; // when not set use breakout sum
    breakouts: IBreakout[];
}

const initialLength: Length = { value: 200, unit: Units.Millimeter };

const initialConnector: IConnector = {
    position: 1,
    type: ConnectorTypes.ConnLC.type
}
const initialConnectorGroup: IConnectorGroup = {
    connectors: [{...initialConnector}],
    position: 1,
    length: {...initialLength}
}

const initialSideABreakout: IBreakout = {
    position: 1,
    side: Sides.SideA,
    trunk: { length: { value: 200, unit: Units.Millimeter}},
    furcation: {
        groups: [
            { ...initialConnectorGroup, position: 1, connectors: [{...initialConnector, position: 1}],  },
        ]
    }
}

const initialSideBBreakout: IBreakout = {
    position: 2,
    side: Sides.SideB,
    trunk: { length: { value: 200, unit: Units.Millimeter}},
    furcation: {
        groups: [
            { ...initialConnectorGroup, 
                position: 1, 
                connectors: 
                    [
                        {...initialConnector, position: 1},
                    ],  
            },
        ]
    }
}
const initialAssemblySideA: IAssemblySide = {
    breakouts: [ 
        {...initialSideABreakout, position: 1},
    ]
}

const initialAssemblySideB: IAssemblySide = {
    breakouts: [
        {...initialSideBBreakout, position: 1},
    ]
}

export const initialAssembly: IAssembly = {
    fiberCount: FiberCounts[6],
    overallLength: { value: 0, unit: Units.Meter },
    sideA: initialAssemblySideA,
    sideB: initialAssemblySideB,
    palette: {
        jacketColor: Yellow.name,
        sideATrunkColor: Yellow.name,
        sideALegColor: Yellow.name,
        sideBTrunkColor: Yellow.name,
        sideBLegColor: Yellow.name
    },
    annotation: {
        notes: "",
        calloutVisibility: []
    },
    polarity: {
        connectorAssignments: []
    },
    assemblyInfo: {
        legTolerance: defaultTolerances.milimeter,
        legPrimeTolerance: defaultTolerances.milimeter,
        legLabelTolerance: defaultLabelTolerances.millimeter,
        polaritySortType: PolaritySortTypes.EndA
    }
}

export function getCableFiberCounts(cable: IAssembly) {
    const fiberCount = cable.fiberCount;
    const sideAFiberCount = cable.sideA ? getSideFiberCount(cable.sideA) : 0;
    const sideBFiberCount = cable.sideB ? getSideFiberCount(cable.sideB) : 0;

    return { fiberCount, sideAFiberCount, sideBFiberCount}
}

export function getSideFiberCount(cableSide: IAssemblySide) {
    let fiberCount = cableSide.fiberCount || 0
    if (!fiberCount) {
        const connectorFiberCount = cableSide.breakouts
            .map(b => b.furcation).flat()
            .map(f => f.groups).flat()
            .map(g => g.connectors).flat()
            .map(c => getConnectorType(c.type).fiberCount)
            .reduce((a, b) => a + b)

        fiberCount = connectorFiberCount;
    }

    return fiberCount;
}

export const getDefaultJacketColor = (fiberType?: FiberType, environment?: CableEnvironment) => {
    let jacketColor = Black;
    if (fiberType && environment && environment === "Indoor") {
        jacketColor = Yellow;
        if (fiberType.includes("MM OM")) {
            jacketColor = fiberType === FiberTypes.MM0M5 ? Lime : Aqua;
        }
    }
    return jacketColor;
}

export const getBootColorCombination = (combination: BootColorCombination) => {
    switch (combination) {
        case BootColorCombinations.WhiteWhite:
            return WhiteWhiteBootColors; 
        case BootColorCombinations.WhiteBlack:
            return WhiteBlackBootColors;
        case BootColorCombinations.WhiteBlue:
            return WhiteBlueBootColors;
    }
}

export const getAssemblyDefaultPalette = (fiberType?: FiberType, environment?: CableEnvironment) : IAssemblyPalette => {
    const defaultColor = getDefaultJacketColor(fiberType, environment).name;
    return {
        jacketColor: defaultColor,
        sideATrunkColor: defaultColor,
        sideALegColor: defaultColor,
        sideBTrunkColor: defaultColor,
        sideBLegColor: defaultColor
    }
}

export const getDefaultToleranceValues = (connectorType: string) => {
    switch(connectorType) {
        case CableTypes.TMIC:
        case CableTypes.Altos:
        case CableTypes.BareRibbon:
        case CableTypes.JacketedRibbon:
        case CableTypes.EDGE:
            return tolerances;
        default:
            return defaultTolerances;
    }
}

export const getDefaultToleranceValuesArray = (connectorType: string) => {
    switch(connectorType) {
        case CableTypes.TMIC:
        case CableTypes.Altos:
        case CableTypes.BareRibbon:
        case CableTypes.JacketedRibbon:
        case CableTypes.EDGE:
            return [tolerances.inch.min, tolerances.inch.max, tolerances.milimeter.min, tolerances.milimeter.max];
        default:
            return [defaultTolerances.inch.min, defaultTolerances.inch.max, defaultTolerances.milimeter.min, defaultTolerances.milimeter.max];
    }
}

export const isDefaultToleranceValue = (toleranceValue: number, connectorType?: string) => {
    let defaultTolerances = defaultToleranceValues;
    if (connectorType) {
        defaultTolerances = getDefaultToleranceValuesArray(connectorType);
    }

    return defaultTolerances.includes(Math.abs(toleranceValue));
}

export const isDefaultLabelToleranceValue = (toleranceValue: number) => {
    return defaultLabelToleranceValues.includes(Math.abs(toleranceValue));
}

export const assemblyHasCustomTolerances = (info: IAssemblyInfo) => {
    if (info.legTolerance && info.legPrimeTolerance && info.legLabelTolerance) {
        const tolerances = [info.legTolerance.min, info.legTolerance.max, info.legPrimeTolerance.min, info.legPrimeTolerance.max];
        const labelTolerances = [info.legLabelTolerance.min, info.legLabelTolerance.max];
        return tolerances.some(t => !isDefaultToleranceValue(t, info.type)) || labelTolerances.some(t => !isDefaultLabelToleranceValue(t));
    }
    return false;
}

export const ComponentNames = {
    CABContainer,
    SideContainer,
    ...CableBaseContainers,
    ...BreakoutContainers,
    BezierCurveGraphics,
    FurcationContainer,
    ...ConnectorLegContainers,
    ConnectorSpriteContainer,
    LengthMarkerContainer,
    EndDesignationText,
    DocumentGraphicsContainer
} as const;

export type ComponentName = typeof ComponentNames [keyof typeof ComponentNames];