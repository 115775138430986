import React from 'react';
import { Stage, Container } from '@inlet/react-pixi';
import { Background } from './background/Background';
import { Viewport } from './viewport/Viewport';
import { Cable } from './cable/Cable';
import { DocumentGraphics } from './document/DocumentGraphics';
import { ViewportContext } from './viewport/types';
import { usePixiComponent } from './hooks';
import { CablePositionContext } from './cable/position/types';
import { StatusContext } from './status/types';
import { ResourceManager } from './manager/ResourceManager';

import "./PixiComponent.scss";

const centerX = window.innerWidth / 2;
const centerY = window.innerHeight / 2;
export const centerPoint = { x: centerX, y: centerY }

export const PixiComponent = () => {
    const { windowSize, loaded, statusContext, viewportContext, viewportRef, cablePositionContext, documentGraphicsProps, cableProps } = usePixiComponent();
    return (
        <div id="pixi-container">
            <Stage id="pixi-stage" options={{ antialias: true, sharedLoader: true }} {...windowSize}>
                <DocumentGraphics {...documentGraphicsProps} />
                <Background />
                <StatusContext.Provider value={statusContext}>
                    <ResourceManager />
                    {
                        loaded &&
                            <ViewportContext.Provider value={viewportContext}>
                                <CablePositionContext.Provider value={cablePositionContext}>
                                    <Viewport viewportRef={viewportRef}>
                                        <Container {...centerPoint}>
                                            <Cable {...cableProps} />
                                        </Container>
                                    </Viewport>
                                </CablePositionContext.Provider>
                            </ViewportContext.Provider>
                    }
                </StatusContext.Provider>
            </Stage>
        </div>
    )
}